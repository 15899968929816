import { createSlice } from "@reduxjs/toolkit";
import { cryptoArray, walletProps } from "utils/constants";

const initialState = {
  walletAddresses: cryptoArray.reduce((c, i) => {
    c[i] = null;
    return c;
  }, {}),

  isLoadingAddresses: false,
  isLoadingAddressesNew: false,
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    getWalletAddresses: (state, { payload }) => {
      state.walletAddresses = { ...state.walletAddresses, ...payload };
    },
    isLoadingAddresses: (state, { payload }) => {
      state.isLoadingAddresses = payload;
    },
    isLoadingAddressesNew: (state, { payload }) => {
      state.isLoadingAddressesNew = payload;
    },
  },
});

export const { getWalletAddresses, isLoadingAddresses, isLoadingAddressesNew } =
  cryptoSlice.actions;

export default cryptoSlice.reducer;
