import { useEffect, useState } from "react";

export function useGetUrlParam(name) {
  const [refURL, setRefURL] = useState("");

  useEffect(() => {
    try {
      const marketingReferral = new URLSearchParams(window.location.search).get(
        name
      );
      setRefURL(marketingReferral);
    } catch (error) {
      // console.log(error);
    }

    return;
  }, [name]);

  return refURL;
}
