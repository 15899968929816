import React, { useState } from "react";
import { Dialog, Slide } from "@mui/material";
import { Button, Select, WalletIcon } from "components";
import { useUpdateCurrency } from "api";
import { walletFiatProps } from "utils/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectFiatCurrency({ open }) {
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  const [isUpdating, isUpdated, updateCurrency] = useUpdateCurrency();

  const handleSetCur = () => {
    updateCurrency({ defaultCurrency: defaultCurrency.id });
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      TransitionComponent={Transition}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        paper: "!w-[400px] !rounded-lg !overflow-visible",
      }}
    >
      <div className="rounded-2xl bg-card p-2 text-text-color shadow-md">
        <div className="bg-gray-50 px-4 py-4">
          <div className="flow-root">
            <h5 className="font-bold">Set Default Fiat Wallet</h5>
          </div>
        </div>
        <div className="bg-[#f4f8f5] p-2 shadow-md">
          <div className="relative rounded-lg bg-white px-4 py-4 shadow-md">
            <div className="mb-6 rounded-md bg-[#e7f7ea] p-4 text-sm">
              <div className="text-[#50875a]">
                The selected wallet will be your default fiat wallet. Go to settings to change.
              </div>
            </div>
            <div className="grid-col-1 grid gap-4">
              <div className="col-span-1">
                <Select
                  handleChange={setDefaultCurrency}
                  titleValue="name"
                  subTitleValue="subtitle"
                  dropDown={walletFiatProps.map((i) => ({
                    ...i,
                    subtitle: (
                      <div className="flex items-center">
                        <WalletIcon type={i.id} />
                        <span className="ml-1">{i.sym}</span>
                      </div>
                    ),
                  }))}
                  inputLabel="Default Currency"
                />
              </div>
              <div className="col-span-1">
                <Button
                  label="Save"
                  fullWidth
                  gradient
                  handleClick={handleSetCur}
                  disabled={defaultCurrency === null}
                  isSubmitting={isUpdating}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
