import React from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";

import { useCryptoWalletListProps, useTransferCrypto } from "api";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  WalletBalance,
} from "components";
import { CustomInput } from "../components/CustomInput";
import { toFixed } from "utils";
import NoDisplay from "../components/NoDisplay";
import { add } from "lodash";

export default function TransferCrypto({
  currentWalletProps: currentWallet,
  displayPage,
}) {
  // useState hooks

  const {
    user_details: { defaultCurrency },
  } = useSelector((state) => state.profile);

  // variables

  const [isSubmitting, isSubmitted, transferCrypto] = useTransferCrypto();

  const { walletProps } = useCryptoWalletListProps();

  const tetherTronWallet = walletProps?.find(
    (item) => item.id === "tether-tron"
  );
  const tetherWallet = walletProps?.find((item) => item.id === "tether");

  const totalTransferWallet = add(
    parseFloat(tetherWallet?.amount),
    parseFloat(tetherTronWallet?.amount)
  );

  // Methods

  const handleSubmitTransfer = () => {
    const tradeValues = {
      crypto: parseFloat(tetherTronWallet?.amount),
      cryptoTotal: parseFloat(tetherTronWallet?.amount),
      usdt: parseFloat(tetherWallet?.amount),
      usdtTotal: totalTransferWallet,
      usd: parseFloat(tetherWallet?.amount),
      total: totalTransferWallet,
      totalUsd: totalTransferWallet,
    };

    transferCrypto({
      tradeValues,
      sym: tetherTronWallet?.sym,
      symTo: tetherWallet?.sym,
      tradeType: "transfer",
      walletN: tetherTronWallet?.id,
      walletNTo: tetherWallet?.id,
      cryptowallet: "tetherTronWallet",
      fiatWallet: "tetherWallet",
    });
  };

  if (!displayPage) {
    return <NoDisplay type="transfer" />;
  }

  return (
    <SectionContent>
      {["naira", "cedis", "cefa"].includes(defaultCurrency) ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <Fade>
              <div className="rounded">
                <div className="mt-2">
                  <WalletBalance
                    type={currentWallet.type}
                    amount={currentWallet.amount}
                    amountUSD={currentWallet.amountUSD}
                    sym={currentWallet.sym}
                  />
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-span-1">
            <div className="relative py-2">
              <div className="grid grid-cols-1 gap-6">
                {[
                  {
                    sym: tetherTronWallet?.sym,
                    name: tetherTronWallet?.id,
                    id: "crypto",
                    label: `Total ${tetherTronWallet.sym} Asset`,
                    placeholder: "Amount",
                    valueAmt: `${tetherTronWallet?.amount}`,
                  },
                  {
                    sym: tetherWallet?.sym,
                    name: tetherWallet?.id,
                    id: "crypto",
                    label: `Total ${tetherWallet.sym} Asset`,
                    placeholder: "Amount",
                    valueAmt: `${tetherWallet?.amount}`,
                  },
                  {
                    sym: tetherWallet?.sym,
                    name: tetherWallet?.id,
                    id: "crypto",
                    label: `Total ${tetherWallet.sym} Asset After Transfer`,
                    placeholder: "Amount",
                    valueAmt: `${totalTransferWallet}`,
                  },
                ].map((item) => (
                  <div className="col-span-1" key={item.label}>
                    <CustomInput
                      currentWallet={item}
                      label={item.label}
                      value={item?.valueAmt}
                      disabled={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {parseFloat(tetherTronWallet?.amount) > 0 && totalTransferWallet > 0 && (
            <Slide up duration={300}>
              <div className="col-span-1">
                <EnterPIN
                  btnText={`Transfer USDT_TRON`}
                  processSubmit={handleSubmitTransfer}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Slide>
          )}
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
