import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import * as i from "../lib";
import { axiosCall, axiosCall2, getDevice, verifyOTP } from "../helpers";
import {
  getCurrentUser,
  setLogoutTimer,
} from "../../redux/reducers/authReducers";
import { useNavigate } from "react-router-dom";
import { notification, trimName } from "utils";
import { useGetUrlParam } from "./getUrlParam";
import { useSaveReferredUsers } from "./utils";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/auth/${endpoint}`;

export const useAuthStateChanged = async () => {
  const dispatch = useDispatch();
  const { profile } = useStore().getState();

  useEffect(() => {
    const unsubscribe = i.onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid, displayName, email, metadata, phoneNumber, photoURL } =
          user;
        dispatch(
          getCurrentUser({
            current_user: uid,
            current_user_details: {
              uid,
              displayName,
              email,
              metadata,
              phoneNumber,
              photoURL,
            },
            isLoading: false,
            isLoaded: true,
          })
        );
      } else {
        dispatch(setLogoutTimer(false));
        dispatch(
          getCurrentUser({
            current_user: null,
            current_user_details: null,
            isLoading: false,
            isLoaded: true,
          })
        );
      }
    });
    return unsubscribe;
  }, [dispatch, profile]);
};

const loginAuth = async (email, password, setIsLoading, setIsLoaded) => {
  const marketingReferral = new URLSearchParams(window.location.search).get(
    "marketing"
  );

  await i.setPersistence(auth, i.browserSessionPersistence);

  const { user } = await i.signInWithEmailAndPassword(auth, email, password);

  const data = {
    referredFrom: marketingReferral,
    user: user.uid,
    createdAt: i.serverTimestamp(),
    platform: "website",
  };

  if (marketingReferral && user) {
    try {
      const transactionsRef = i.doc(i.db, "userMarketingReferrals", user.uid);
      const snapshot = await i.getDoc(transactionsRef);

      if (snapshot.exists()) {
        return;
      } else {
        await i.setDoc(i.doc(i.db, "userMarketingReferrals", user.uid), data);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  const device = await getDevice();
  const config = {
    values: { device },
    uid: user.uid,
  };

  await axiosCall(url("login_user"), config, true);

  setIsLoading(false);
  setIsLoaded(true);
};

const twoFacAuth = async (
  email,
  password,
  twoFacDetails,
  enable2factorAuth,
  factorType,
  setRedirect
) => {
  if (factorType === "emailAuthentication") {
    await verifyOTP({ email, isVerify: false }, "verify_email_otp", false);
    notification("success", `OTP successfully sent to ${email}`);
  } else if (factorType === "phoneAuthentication") {
    await verifyOTP(
      { email, phoneNumber: twoFacDetails?.phoneNumber, isVerify: false },
      "verify_phone_otp",
      false
    );
    notification(
      "success",
      `OTP successfully sent to +${twoFacDetails?.phoneNumber}`
    );
  }
  setRedirect({
    email,
    password,
    enable2factorAuth,
    twoFacDetails,
    factorType,
  });
};

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [redirect, setRedirect] = useState({});
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (redirect?.enable2factorAuth) {
      navigate("/two-factor-check", {
        replace: true,
        state: redirect,
      });
    }
  }, [redirect]);

  const login = async (email, password) => {
    const configSignIn = { values: { email } };

    try {
      setIsLoading(true);

      await axiosCall(url("check_signin_details"), configSignIn);
      const checkFactor = await axiosCall(url("check_two_factor_login"), {
        values: { email, password },
      });

      const { enable2factorAuth, twoFacDetails, factorType } =
        checkFactor.data.data;

      if (enable2factorAuth) {
        await twoFacAuth(
          email,
          password,
          twoFacDetails,
          enable2factorAuth,
          factorType,
          setRedirect
        );
        setIsLoading(false);
        return;
      } else {
        await loginAuth(email, password, setIsLoading, setIsLoaded);
      }

      // await loginAuth(email, password, setIsLoading, setIsLoaded);

      setIsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      // console.log(error);
      const errorList = {
        "auth/too-many-requests":
          "Access to this account has been temporarily disabled due to many failed login attempts. Try again later.",
        "auth/wrong-password": "Invalid email/password.",
        "auth/user-not-found": "Invalid email/password.",
        "auth/user-disabled":
          "Access to this account has been temporarily disabled. Try again later.",
        "auth/network-request-failed":
          "No internet connection available. Check you network",
        "Biometric cannot be verified": "Biometric cannot be verified.",
        "Biometrics is not available.":
          "Biometric has been disabled for this device. Enable biometric and try again!.",
        "timeout exceeded":
          "No internet connection available. Check you network",
        "Network Error": "No internet connection available. Check you network",
        "Invalid email/password": "Invalid email/password",
        "Too many attempts":
          "Access to this account has been temporarily disabled due to many failed login attempts. Try again later.",
      };

      let message;
      if (errorList.hasOwnProperty(error?.code)) {
        message = errorList[error?.code];
      } else if (errorList.hasOwnProperty(error?.message)) {
        message = errorList[error?.message];
      } else if (errorList.hasOwnProperty(error)) {
        message = errorList[error];
      } else {
        message = "default_error";
      }

      setIsLoading(false);
      setIsLoaded(true);
      setError(true);
      if (message !== "default_error") {
        notification("danger", message, "error");
      }
    }
  };

  return [isLoading, isLoaded, error, login];
};

export const useCheckRegisterDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userDetails, setDetails] = useState(null);
  const [error, setError] = useState(false);

  const checkRegisterDetails = async (values) => {
    const configCheckSignup = { values, isAppCheck: "no_check" };

    try {
      setIsLoading(true);
      const checkSignupDetails = await axiosCall(
        url("check_signup_details"),
        configCheckSignup
      );

      const userDetailsFromServer = checkSignupDetails?.data?.data;

      setDetails(userDetailsFromServer);

      setIsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      let message;
      switch (error) {
        case "User exist":
          message = "User already exist!";
          break;
        case '"password" length must be at least 8 characters long':
          message = "Password length must be at least 8 characters long!";
          break;
        case "Phone Number is already in use by another account.":
          message = error;
          break;
        default:
          message = "An error occured. Try again later!";
      }

      // console.log(error);
      setIsLoading(false);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, userDetails, checkRegisterDetails];
};

export const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sendEmailCodeStatus, setSendEmailCodeStatus] = useState(false);
  const [sendPhoneCodeStatus, setSendPhoneCodeStatus] = useState(false);
  const [phoneOTPRef, setPhoneOTPRef] = useState(null);
  const [error, setError] = useState(false);

  const resendEmailOtpCode = async (values) => {
    const { email, displayName } = values;

    const configVerifyOtp = {
      values: {
        email: email,
        username: displayName,
        isVerify: false,
      },
    };

    try {
      await axiosCall(url("verify_email_otp"), configVerifyOtp);
      i.notification("success", "Email Otp successfully sent!");
    } catch (error) {
      i.notification("danger", "An error occured. Try again later!");
    }
  };

  const resendPhoneOtpCode = async (values) => {
    const { email, displayName, phoneNumber } = values;

    const configVerifyPhoneOtp = {
      values: {
        email: email,
        phoneNumber: phoneNumber,
        isVerify: false,
      },
    };

    try {
      const OTPRef = await axiosCall(
        url("verify_phone_otp"),
        configVerifyPhoneOtp
      );

      setPhoneOTPRef(OTPRef.data.data);

      i.notification("success", "SMS Otp successfully sent!");
    } catch (error) {
      i.notification("danger", "An error occured. Try again later!", "error");
    }
  };

  const register = async ({
    values,
    code,
    phoneCode,
    phoneOTPRef,
    referralParam,
  }) => {
    const { email, password } = values;

    const photoURL =
      "https://firebasestorage.googleapis.com/v0/b/kaapo-app.appspot.com/o/avatar_esetech.png?alt=media&token=21589ce3-5c9f-466b-934c-80f6db590af6";

    try {
      setIsLoading(true);
      const device = await getDevice();

      const valuesFormat = {
        ...values,
        displayName: trimName(values?.displayName),
        photoURL,
        device,
        password,
        // phoneVerifStatus,
        emailOTP: code,
        phoneOTP: phoneCode,
        requestId: phoneOTPRef,
      };

      await i.setPersistence(auth, i.browserSessionPersistence);

      const configRegisterUser = { values: valuesFormat };

      const registerCheck = await axiosCall(
        url("register_user"),
        configRegisterUser,
        true
      );

      const { token } = registerCheck?.data?.data;

      const { user } = await i.signInWithCustomToken(auth, token);

      const data = {
        referredFrom: referralParam,
        user: user.uid,
        createdAt: i.serverTimestamp(),
        platform: "website",
      };

      if (referralParam && user) {
        try {
          const marketingsRef = i.doc(i.db, "userMarketingReferrals", user.uid);
          const snapshot = await i.getDoc(marketingsRef);

          if (snapshot.exists()) {
            return;
          } else {
            await i.setDoc(
              i.doc(i.db, "userMarketingReferrals", user.uid),
              data
            );
          }
        } catch (error) {
          // console.log(error);
        }
      }

      setIsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      let message;
      if (error?.code) {
        message = error?.code;
      } else {
        switch (error) {
          case "Otp do not match":
            message = error;
            break;
          case "Exceeded maximum trials":
            message = error;
            break;
          default:
            message = "Otp has expired!";
            break;
        }
      }
      setIsLoading(false);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [
    isLoading,
    isLoaded,
    register,
    sendEmailCodeStatus,
    setSendEmailCodeStatus,
    sendPhoneCodeStatus,
    setSendPhoneCodeStatus,
    phoneOTPRef,
    resendEmailOtpCode,
    resendPhoneOtpCode,
  ];
};

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const logout = async () => {
    try {
      setIsLoading(true);
      return await i.signOut(auth);
    } catch (error) {
      // console.log(error)
    }
  };
  return [isLoading, isLoaded, error, logout];
};

export const useEnableTwoFactor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const enableFactor = async (enable, factorType) => {
    const { email } = auth.currentUser;

    try {
      setIsLoading(true);

      await axiosCall(
        url(enable ? "enable_two_factor" : "disable_two_factor"),
        { email, factorType: factorType },
        true
      );

      setIsLoading(false);
      setIsLoaded(true);
      notification(
        "success",
        `2factor auth successfully ${enable ? "enabled" : "disabled"}!`
      );
    } catch (error) {
      setIsLoading(false);
      setIsLoaded(true);
      notification("danger", "An error occured. Try again later.", "error");
    }
  };
  return [isLoading, isLoaded, enableFactor];
};

export const useVerifyTwoFactor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const verifyTwoFactor = async ({
    email,
    password,
    code,
    uid,
    phoneNumber,
    factorType,
  }) => {
    try {
      setIsLoading(true);
      await axiosCall(
        url("two_factor_auth"),
        { code, uid, phoneNumber, factorType, email },
        false
      );

      await loginAuth(email, password, setIsLoading, setIsLoaded);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setIsLoaded(true);

      notification("danger", error, "error");
    }
  };

  return [isLoading, isLoaded, verifyTwoFactor];
};

export const useEnableTwoFactorPhone = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const enableFactorPhone = async (enable) => {
    const { email } = auth.currentUser;

    try {
      setIsLoading(true);

      await axiosCall(
        url(enable ? "enable_two_factor_phone" : "disable_two_factor_phone"),
        { email },
        true
      );

      setIsLoading(false);
      setIsLoaded(true);
      notification(
        "success",
        `2factor phone auth successfully ${enable ? "enabled" : "disabled"}!`
      );
    } catch (error) {
      setIsLoading(false);
      setIsLoaded(true);
      notification("danger", "An error occured. Try again later.", "error");
    }
  };
  return [isLoading, isLoaded, enableFactorPhone];
};

export const useResetPass = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const resetPass = async (email) => {
    try {
      setIsLoading(true);
      const actionCodeSettings = {
        url: "https://dashboard.esetech.com.ng/login",
        handleCodeInApp: false,
      };

      await i.sendPasswordResetEmail(auth, email, actionCodeSettings);
      setIsLoading(false);
      setIsLoaded(true);
      notification("success", "Password reset email has been sent");
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setIsLoaded(true);
      setError(true);
      const errMsg =
        error.code === "auth/user-not-found"
          ? "Email address not found. Check your email address and try again."
          : "An error occured. Try again later.";

      notification("danger", errMsg, "error");
    }
  };

  return [isLoading, isLoaded, error, resetPass];
};

export const useVerifyResetPassword = () => {
  const [error, setError] = useState(false);
  const [getEmail, setGetEmail] = useState("");

  const verifyResetPassword = async (actionCode) => {
    try {
      const email = await i.verifyPasswordResetCode(i.auth, actionCode);
      setGetEmail(email);
    } catch (error) {
      setError(error.code);
    }
  };

  return [error, verifyResetPassword, getEmail];
};

export const useConfirmResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const confirmResetPassword = async (actionCode, newPassword, email) => {
    try {
      setIsLoading(true);

      await i.confirmPasswordReset(i.auth, actionCode, newPassword);

      setIsLoading(false);
      setIsLoaded(true);
    } catch (error) {
      // console.log(error);
      let message;
      if (error?.code) {
        switch (error?.code) {
          case "auth/invalid-action-code":
            message = "Reset Code has Expired";
            break;

          default:
            message = "An error occured. Try again later.";
            break;
        }
      } else {
        message = "An error occured. Try again later.";
      }
      setIsLoading(false);
      setIsLoaded(true);
      setError(true);
      notification("danger", message, "error");
    }
  };

  return [isLoading, isLoaded, error, confirmResetPassword];
};
