import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Navigation from "./navigation";
import { muiTheme } from "./config/themeConfig";
import { ReactNotifications } from "react-notifications-component";

import {
  useAuthStateChanged,
  useUserProfile,
  useNairaWallet,
  useCedisWallet,
  useCefaWallet,
  useCryptoWallet,
  useWithdrawBankAccount,
  useGetNotification,
  useGetAdvertPopup,
} from "api";
import ErrorBoundary from "shared/ErrorHandle";

import "./index.css";
import "react-notifications-component/dist/theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Messanger from "components/Messanger";
import { Footer } from "shared";
import { AdvertPopup } from "components";
import { useGetUrlParam } from "api/actions/getUrlParam";
import { getDevice } from "api/helpers";

function App() {
  const state = useSelector((state) => state);
  const { advertPopup } = useSelector((state) => state.utils);

  useAuthStateChanged();
  useUserProfile();
  useNairaWallet();
  useCedisWallet();
  useCefaWallet();
  useCryptoWallet();
  useWithdrawBankAccount();
  useGetNotification();
  useGetAdvertPopup();

  getDevice();

  return (
    <div className="app">
      <div className="min-h-screen">
        <ThemeProvider theme={muiTheme}>
          <Messanger />
          <ReactNotifications />
          <ErrorBoundary>
            <Navigation />
            {state?.auth?.currentUser?.current_user && (
              <AdvertPopup advertPopup={advertPopup} />
            )}
          </ErrorBoundary>
        </ThemeProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
