import React, { useEffect, useState } from "react";

import { Button, TextField } from "components";
import { AuthContainer } from "shared";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirmResetPassword, useVerifyResetPassword } from "api";

export default function ResetPass() {
  const [password, setPassword] = useState("");

  const [errorVerify, verifyResetPassword, getEmail] = useVerifyResetPassword();
  const [isLoading, isLoaded, error, confirmResetPassword] =
    useConfirmResetPassword();

  const { search } = useLocation();
  const navigate = useNavigate();

  const actionCode = new URLSearchParams(search).get("oobCode");
  const mode = new URLSearchParams(search).get("mode");
  const continueUrl = new URLSearchParams(search).get("continueUrl");

  useEffect(() => {
    if (actionCode) {
      verifyResetPassword(actionCode);
    }
  }, [actionCode]);

  useEffect(() => {
    if (errorVerify || !(actionCode && mode && continueUrl)) {
      navigate("/login");
    }
  }, [errorVerify]);

  return (
    <AuthContainer
      title="Reset Password"
      subTitle="Want to reset your password? Input your new password and click on reset button to continue."
      footer={{ link: "/login", text: "Remembered password?" }}
    >
      {isLoaded ? (
        <Button
          type="button"
          label={"Back to Login"}
          disabled={isLoading}
          isSubmitting={isLoading}
          handleClick={() => {
            return window.open(continueUrl, "_self");
          }}
          fullWidth
          gradient
        />
      ) : (
        <div className="grid grid-cols-1 gap-6">
          <div className="col-span-1">
            <TextField name="email" label="Email" value={getEmail} disabled />
          </div>
          <div className="col-span-1">
            <TextField
              name="password"
              type="password"
              label="Password"
              handleChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="New Password"
            />
          </div>
          <div className="col-span-1">
            <Button
              type="button"
              label={"Reset Password"}
              disabled={isLoading}
              isSubmitting={isLoading}
              handleClick={() => {
                if (Boolean(getEmail) && Boolean(password)) {
                  confirmResetPassword(actionCode, password, getEmail);
                }
              }}
              fullWidth
              gradient
            />
          </div>
        </div>
      )}
    </AuthContainer>
  );
}
