import React from "react";
import { SectionContent } from "components";

export default function NoDisplay({ type = "withdrawal" }) {
  return (
    <div className=" h-[350px]">
      <SectionContent>
        <div className="py-6 px-2 text-sm">Not available for {type}.</div>
      </SectionContent>
    </div>
  );
}
