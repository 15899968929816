/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { TextField, Autocomplete, Select } from ".";
import { useBankList, useVerifyAccountNum } from "api";

export default function VerifyBank({
  bankDetails,
  setBankDetails,
  accountType,
  accounts,
  label,
  isSubmitting,
}) {
  const [accountNum, setAccountNum] = useState(
    bankDetails?.bankAccountNo || ""
  );

  const [selectedBank, setSelectedBank] = useState(null);

  const [getBankList, errBankList, isLoadingBankList] = useBankList(label);

  const [
    verifyAcctName,
    setVerifyAcctName,
    isLoading,
    errorVerify,
    setErrorVerify,
    verifyAccountNum,
  ] = useVerifyAccountNum(
    {
      selectedBank: bankDetails,
      accountNum,
    },
    setBankDetails
  );

  let accountName = isLoading
    ? "Searching..."
    : verifyAcctName
    ? verifyAcctName?.accountName
    : "";

  const handleAccountNum = (e) => {
    let val = e.target.value;

    val = val.replace(/[^0-9]/g, "");
    if (val.length >= 10) {
      setAccountNum(val.slice(0, 10));
    } else {
      setAccountNum(val);
      setVerifyAcctName(null);
      setErrorVerify(null);
    }
  };

  useEffect(() => {
    if (accountNum.length >= 10 && selectedBank) {
      verifyAccountNum();
    }
  }, [selectedBank, accountNum]);

  useEffect(() => {
    if (selectedBank) {
      if (verifyAcctName) {
        setBankDetails((prevS) => ({
          ...prevS,
          ...verifyAcctName,
          isVerified: true,
        }));
      } else {
        setBankDetails(selectedBank);
      }
    } else {
      setVerifyAcctName(null);
      setBankDetails(null);
      setAccountNum("");
    }
  }, [verifyAcctName, selectedBank]);

  useEffect(() => {
    setBankDetails(null);
    setAccountNum("");
    setSelectedBank(null);
  }, [label]);

  useEffect(() => {
    setErrorVerify(null);
  }, [selectedBank, label]);

  return (
    <>
      {accountType === "other-account" ? (
        <>
          <div className="col-span-1">
            <Autocomplete
              inputLabel="Bank"
              options={getBankList}
              titleValue="name"
              handleChange={setSelectedBank}
              handleInputTextChange={() => {
                setAccountNum("");
                setVerifyAcctName(null);
              }}
              valueAlt={selectedBank}
              disabled={isSubmitting}
              isLoading={isLoadingBankList}
            />
          </div>

          <div className="col-span-1">
            <TextField
              name="account-number"
              type="text"
              label="Account Number"
              value={accountNum}
              handleChange={handleAccountNum}
              disableAutoComplete
              disabled={isSubmitting}
              placeholder="Account Number"
            />
          </div>

          <div className="col-span-1">
            <TextField
              name="account-name"
              label="Account Name"
              // value={bankDetails?.accountName ?? accountName}
              value={accountName}
              readOnly
              error={errorVerify === true}
              showError={errorVerify === true}
              helperText="No data found"
              disabled={isSubmitting}
              placeholder="Account Name"
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-span-1">
            <Select
              handleChange={(val) => {
                const t = { ...val };
                setBankDetails(t);
                setSelectedBank(t);
                setAccountNum(val?.bankAccountNo ?? "");
              }}
              titleValue="bankName"
              subTitleValue="accountName"
              dropDown={accounts ?? []}
              inputLabel="Bank"
            />
          </div>

          <div className="col-span-1">
            <TextField
              name="account-number"
              type="number"
              label="Account Number"
              value={bankDetails?.bankAccountNo ?? ""}
              placeholder="Account Number"
              readOnly
            />
          </div>

          <div className="col-span-1">
            <TextField
              name="account-name"
              label="Account Name"
              value={accountName}
              readOnly
              error={errorVerify === true}
              showError={errorVerify === true}
              helperText="No account details found"
              placeholder="Account Name"
            />
          </div>
        </>
      )}
    </>
  );
}
