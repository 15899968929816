import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PageContainer } from "shared";
import WalletDrawer from "./WalletDrawer";
import WalletContent from "./WalletContent";
import { useGetFiatTransAuth, useWalletStatus } from "api";
import { classNames, useBreakpoint } from "utils";
import WalletContentMobile from "./WalletContentMobile";

export default function Index() {
  const { currentId } = useLocation().state || {};

  const [walletStatus] = useWalletStatus();

  const breakpoint = useBreakpoint();

  useGetFiatTransAuth();

  useEffect(() => {
    if (currentId) {
      walletStatus({ currentWallet: currentId });
    }
  }, [currentId]);

  return (
    <PageContainer header="Fiat Wallets">
      <div
        className={classNames(
          "relative overflow-hidden rounded-xl bg-white shadow-md",
          breakpoint.md ? "h-[480px]" : "h-[240px]"
        )}
      >
        <div className="flex flex-row overflow-hidden">
          <WalletDrawer />
          {breakpoint.md && <WalletContent />}
        </div>
        {!breakpoint.md && <WalletContentMobile />}
      </div>
    </PageContainer>
  );
}
