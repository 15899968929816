import { createTheme } from "@mui/material/styles";
const colors = require("tailwindcss/colors");

export const muiTheme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 480,
  //     md: 768,
  //     lg: 976,
  //     xl: 1440,
  //   },
  // },
  palette: {
    primary: {
      light: "#e8f5e9",
      light_10: "#d5e8d6",
      main: "#388951",
      dark: "#2e7d32",
      contrastText: "#fff",
    },
    success: {
      main: "#39da8a",
    },
    default: {
      main: colors.gray["300"],
      contrastText: "#475f7b",
    },
    secondary: {
      // main: '#f44336',
      main: "#2e7d32",
      contrastText: "#fff",
    },
    neutral: {
      // main: "#959292",
      main: "#e0e0e0",
      // contrastText: "#333",
      contrastText: "#357d33",
    },
    text: {
      main: "#444",
      primary: "#475f7b",
      dark: "#357d33",
    },
    background: {
      main: "#fafaff",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
  // typography: {
  //   fontFamily: [
  //     "Fira Sans",
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //   ].join(","),
  //   color: "#fff",
  // },
});

// export const muiTheme = createTheme({
//   palette: {
//     primary: {
//       // main: colors.indigo['600'],
//       main: '#4174b2',
//     },
//     default: {
//       main: colors.slate['200'],
//       contrastText: '#4174b2',
//     },
//     neutral: {
//       main: '#959292',
//     },
//     text: {
//       main: '#fff',
//     },
//     background: {
//       main: '#fafaff',
//     },
//   },
//   typography: {
//     fontFamily: 'inherit',
//   },
// });
