import React, { useCallback, useEffect, useState } from "react";
import {
  classNames,
  formatCrypto,
  formatDate,
  formatInputNum,
  useBreakpoint,
} from "utils";
import { Badge, SimpleIconButton, SimpleTable } from "components";

import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import {
  useUserTransCompletedForWallet,
  useGetCryptoPendingDetails,
} from "api";
import { cryptoAssetSym, statProps, walletCryptoProps } from "utils/constants";
import TransactionPopup from "pages/Transactions/TransactionPopup";

const RightContentTableList = () => {
  const { completedSingleTrans, isTransactionListLoading } = useSelector(
    (state) => state.transaction
  );

  const columns = [
    { id: "date", label: "Date" },
    { id: "status", label: "Status" },
    { id: "product", label: "Product" },
    { id: "action", label: "Action" },
    { id: "amount", label: "Amount" },
  ];

  const rows = completedSingleTrans?.map((item) => ({
    date: <div className="w-24">{formatDate(item.dateCreated)}</div>,
    status: (
      <div
        className={classNames(
          statProps[item.status].bg,
          statProps[item.status].text,
          "w-fit rounded p-2 text-center text-xs font-bold"
        )}
      >
        {item.status}
      </div>
    ),
    product: capitalize(item.productType),
    action: item?.transaction?.tradeType,
    amount: `${formatCrypto(
      ["send", "convert"].includes(item?.transaction?.tradeType)
        ? item?.transaction?.total
        : item?.transaction?.crypto
    )} ${item?.transaction?.sym}`,
  }));

  return (
    <>
      <div className="mb-4 flex font-bold">
        <div className="mr-2 text-gray-500">Recent Transaction</div>
      </div>
      <div className="relative  rounded-lg bg-white">
        <SimpleTable
          rows={rows}
          columns={columns}
          isLoading={isTransactionListLoading}
          coloredHeader
        />
      </div>
    </>
  );
};

const RightContentCryptoList = ({ pendingCryptoList }) => {
  const [getParams, setGetParams] = useState(null);
  const [isLoading, isError, details] = useGetCryptoPendingDetails(getParams);

  const transDetails = useCallback(() => {
    if (!isLoading && details && Boolean(getParams)) {
      const pendingC = pendingCryptoList.find(
        (i) => i.id === getParams.transId
      );
      const dataObj = { ...pendingC, ...details };
      const {
        amount,
        hash,
        type,
        dollarAmount,
        assetType,
        dateCreated,
        address,
        confirmation,
      } = dataObj;

      return {
        status: (
          <div
            className={classNames(
              "bg-yellow-100",
              "text-yellow-500",
              "w-fit rounded p-2 text-center text-xs font-bold"
            )}
          >
            Pending
          </div>
        ),
        cryptoAmount: `${formatCrypto(amount)} ${assetType}`,
        dollarAmount: `${formatInputNum(dollarAmount, 2)} USD`,
        hash: <span className="break-all">{hash}</span>,
        address: <span className="break-all">{address}</span>,
        type,
        confirmation,
        dateCreated: formatDate(dateCreated),
      };
    } else {
      return null;
    }
  }, [getParams, details, isLoading])();

  return (
    <>
      <div className="mb-4 flex font-bold">
        <div className="mr-2 text-gray-500">Pending Crypto</div>
        <Badge num={pendingCryptoList.length} />
      </div>
      {pendingCryptoList.map((item) => (
        <div key={item.id} className="mb-2 rounded-lg bg-white p-4 shadow">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <div className="mb-1 text-xs text-gray-400">
                {formatDate(item.dateCreated, "Do MMM YYYY, hh:mma")}
              </div>
              <div className="mt-1">
                <div className="text-sm font-bold text-yellow-500">
                  {formatCrypto(item.amount)} {item.currency}
                </div>
              </div>
            </div>

            <SimpleIconButton
              icon="ViewAgenda"
              frontColor="text-gray-400"
              onClick={() => {
                setGetParams({
                  type: item.currency,
                  transId: item.id,
                });
              }}
            />
          </div>
        </div>
      ))}
      <TransactionPopup
        open={Boolean(getParams)}
        setOpen={setGetParams}
        transDetails={transDetails}
      />
    </>
  );
};

export default function RightContent() {
  const breakpoint = useBreakpoint();

  const {
    walletStatus: { currentWallet },
  } = useSelector((state) => state.utils);

  const { pendingCrypto } = useSelector((state) => state.transaction);

  useUserTransCompletedForWallet({
    col: "transCrypto",
    productType: currentWallet,
  });

  const cWProp = walletCryptoProps.find((i) => i.id === currentWallet);

  const pendingCryptoList = pendingCrypto?.filter(
    (item) => cryptoAssetSym[item.currency] === cWProp?.assetType
  );

  const Block = ({ children }) =>
    breakpoint.md ? (
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeightMin={285}
      >
        {children}
      </Scrollbars>
    ) : (
      <div>{children}</div>
    );

  return (
    <div className="col-span-7 xl:col-span-8">
      <div
        className={classNames(
          "relative bg-gray-100",
          breakpoint.md ? "h-[420px]" : "h-full"
        )}
      >
        <div className="h-full w-full">
          <Block>
            <div className="p-6">
              {Boolean(pendingCryptoList.length) ? (
                <RightContentCryptoList pendingCryptoList={pendingCryptoList} />
              ) : (
                <RightContentTableList />
              )}
            </div>
          </Block>
        </div>
      </div>
    </div>
  );
}
