import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";

import { useBuyCrypto, useGetUSDCryptoRate, useGetUSDFiatRate } from "api";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  WalletBalance,
} from "components";
import { CustomInput } from "../components/CustomInput";
import { toFixed } from "utils";
import NoDisplay from "../components/NoDisplay";

export default function BuyCrypto({
  currentWalletProps: currentWallet,
  displayPage,
  siteRate,
}) {
  // useState hooks

  const [amt, setAmt] = useState({
    crypto: "",
    usd: "",
    fiat: "",
  });

  const [deficit, setDeficit] = useState(false);
  const [deficitText, setDeficitText] = useState("");

  const { nairaWallet, cedisWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const {
    user_details: { defaultCurrency },
  } = useSelector((state) => state.profile);

  // variables

  const [isSubmitting, isSubmitted, buyCrypto] = useBuyCrypto();

  const rate = useGetUSDCryptoRate();
  const { NGN = 0, GHS = 0, XOF = 0 } = useGetUSDFiatRate() || {};
  const siteBuyRate = siteRate;

  const cryptoToUsdRate = rate?.[currentWallet.sym];
  const { crypto, usd, fiat } = amt;

  // useCallback hooks

  const defaultFiat = useCallback(() => {
    const buyRate = siteRate;
    if (defaultCurrency) {
      const d = {
        naira: {
          siteCryptoRate: toFixed(buyRate, 2),
          sym: "NGN",
          symUnit: "₦",
          name: "naira",
          id: "ngn",
          fiatWalletAmt: nairaWallet,
          usdFiatRate: NGN,
          maintainAmt: 50,
          fiatWallet: "nairaWallet",
        },
        cedis: {
          siteCryptoRate: toFixed(buyRate, 2),
          sym: "GHS",
          symUnit: "₵",
          name: "cedis",
          id: "ghs",
          fiatWalletAmt: cedisWallet,
          usdFiatRate: GHS,
          maintainAmt: toFixed((GHS * 50) / NGN, 2),
          fiatWallet: "cedisWallet",
        },
        cefa: {
          siteCryptoRate: toFixed(buyRate, 2),
          sym: "XOF",
          symUnit: "F",
          name: "cefa",
          id: "xof",
          fiatWalletAmt: cefaWallet,
          usdFiatRate: XOF,
          maintainAmt: toFixed((XOF * 50) / NGN, 2),
          fiatWallet: "cefaWallet",
        },
      };

      return d[defaultCurrency];
    } else {
      return {};
    }
  }, [
    defaultCurrency,
    siteBuyRate,
    nairaWallet,
    cedisWallet,
    cefaWallet,
    NGN,
    GHS,
    XOF,
  ])();

  const { siteCryptoRate, fiatWalletAmt } = defaultFiat;

  // useEffect hooks

  useEffect(() => {
    const underLimitAmt = 0.99;
    const amountF = parseFloat(fiatWalletAmt?.amount || 0).toFixed(2);

    const underLimit = parseFloat(amt?.usd) <= underLimitAmt;
    const overLimit = parseFloat(amt?.fiat) >= amountF;
    const maintainLimit =
      amountF - parseFloat(amt?.fiat) <= defaultFiat.maintainAmt;

    const isDeficit = overLimit || underLimit || maintainLimit;
    setDeficit(isDeficit);
    if (isDeficit) {
      const t = overLimit
        ? `You have exceeded your ${defaultFiat.name} wallet balance`
        : underLimit
        ? `You can only buy $1 worth or more`
        : maintainLimit
        ? `You should maintain a minimum of ${defaultFiat.symUnit}${defaultFiat.maintainAmt} in ${defaultFiat.name} wallet`
        : "";
      setDeficitText(t);
    } else {
      setDeficitText("");
    }
  }, [amt, currentWallet]);

  useEffect(() => {
    if (!isSubmitting) {
      handleAmt(amt.crypto, "crypto");
    }
  }, [cryptoToUsdRate, siteBuyRate]);

  // methods

  const handleAmt = (v, id) => {
    let crypto, usd, fiat;

    const e = v.replace(/[^0-9.]/g, "");
    const disabledMutlipleDot = /^[0-9]*\.?[0-9]*$/;
    const toDecimalLength = e.toString().split(".")?.[1]?.length || 0;
    const countDecimal = id === "crypto" ? 8 : 2;

    if (!disabledMutlipleDot.test(e) || toDecimalLength > countDecimal) {
      return;
    }

    if (id === "usd") {
      usd = e;
      fiat = toFixed(e * siteCryptoRate, 2);
      crypto = toFixed(parseFloat(e) / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "fiat") {
      const ngnPerUsd = parseFloat(e) / siteCryptoRate;

      fiat = e;
      usd = toFixed(ngnPerUsd, 2);
      crypto = toFixed(ngnPerUsd / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "crypto") {
      const cryptoPerUsd = parseFloat(cryptoToUsdRate) * parseFloat(e);

      crypto = e;
      usd = toFixed(cryptoPerUsd, 2);
      fiat = toFixed(cryptoPerUsd * siteCryptoRate, 2);
    }

    setAmt((prevS) => ({ ...prevS, crypto, usd, fiat }));
  };

  const handleSubmitBuy = () => {
    const tradeValues = {
      fiat: parseFloat(fiat),
      fiatSym: defaultFiat?.sym,
      usd: parseFloat(usd),
      crypto: parseFloat(crypto),
      cryptoToUsdRate,
      minTradeAmt: parseFloat(defaultFiat?.maintainAmt),
      fiatRate: parseFloat(siteCryptoRate),
    };

    buyCrypto({
      tradeValues,
      sym: currentWallet?.sym,
      tradeType: "buy",
      walletN: currentWallet?.type,
      cryptowallet: currentWallet?.wallet,
      fiatWallet: defaultFiat?.fiatWallet,
    });
  };

  if (!displayPage) {
    return <NoDisplay type="buying" />;
  }

  return (
    <SectionContent>
      {cryptoToUsdRate &&
      ["naira", "cedis", "cefa"].includes(defaultCurrency) &&
      Boolean(siteBuyRate) ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <Fade>
              <div className="">
                <div className="mb-2 rounded bg-[#e7f7ea]/40 p-3">
                  <div className="font-bold text-[#50875a]">
                    <>
                      Buying Rate: 1 USD ~ {siteCryptoRate} {defaultFiat.sym}
                    </>
                  </div>
                </div>

                <div className="mt-2">
                  <WalletBalance
                    type={defaultFiat.name}
                    amount={fiatWalletAmt?.amount || 0}
                    amountUSD={
                      (fiatWalletAmt?.amount || 0) /
                      parseFloat(defaultFiat?.usdFiatRate)
                    }
                    sym={defaultFiat.sym}
                  />
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-span-1">
            <div className="relative py-2">
              <div className="grid grid-cols-1 gap-6">
                {[
                  {
                    sym: "USD",
                    name: "dollar",
                    id: "usd",
                    label: `Buy (USD)`,
                  },
                  {
                    sym: defaultFiat.sym,
                    name: defaultFiat.name,
                    id: "fiat",
                    label: `Buy (${defaultFiat.sym})`,
                  },
                  {
                    sym: currentWallet?.sym,
                    name: currentWallet?.type,
                    id: "crypto",
                    label: `Receive (${currentWallet.sym})`,
                  },
                ].map((item) => (
                  <div className="col-span-1" key={item.sym}>
                    <CustomInput
                      currentWallet={item}
                      label={item.label}
                      value={amt?.[item.id]}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />
                    {deficit && item.name === defaultFiat?.name && (
                      <Slide down duration={300}>
                        <div className="mt-2 text-xs font-semibold text-red-500">
                          {deficitText}
                        </div>
                      </Slide>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!deficit && crypto !== "" && parseFloat(crypto) > 0 && (
            <Slide up duration={300}>
              <div className="col-span-1">
                <EnterPIN
                  btnText={`Buy ${currentWallet?.type}`}
                  processSubmit={handleSubmitBuy}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Slide>
          )}
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
