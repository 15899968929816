import React, { useState } from "react";
import { SectionContent } from "components";
import NoDisplay from "../components/NoDisplay";

export default function FundFiat({ currentWalletName, displayPage }) {
  if (!displayPage) {
    return <NoDisplay type="funding" />;
  }

  return (
    <SectionContent>
      <div className="py-6 px-2">Not available</div>
    </SectionContent>
  );
}
