import React from "react";

import { PopupDrawer } from "components";
import Airtime from "../sub-page/Airtime";
import DataBundle from "../sub-page/DataBundle";
import { startCase } from "lodash";

export default function PopupContent({ openPopup, setOpenPopup, displayPage }) {
  const pages = {
    purchaseAirtime: Airtime,
    subscribeDataBundle: DataBundle,
  };

  const Pages = pages[openPopup];

  const open = Boolean(openPopup);

  const setOpenDrawer = (action) => {
    setOpenPopup({ currentWalletAction: action });
  };

  return (
    <PopupDrawer open={open} setOpenDrawer={setOpenDrawer}>
      <div className="bg-gray-50 p-4 sm:flex sm:px-4">
        <h5 className="font-bold">{startCase(openPopup)}</h5>
      </div>

      <div className="mt-0 bg-[#f4f8f5] p-2">
        {openPopup && <Pages displayPage={displayPage} />}
      </div>
    </PopupDrawer>
  );
}
