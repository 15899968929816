export {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
  fetchSignInMethodsForEmail,
  updateProfile,
  signInWithCustomToken,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  setPersistence,
  browserSessionPersistence,
  EmailAuthProvider,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth";
export { getToken, getLimitedUseToken } from "firebase/app-check";

export { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
export {
  collection,
  getDocs,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  setDoc,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  runTransaction,
  getCountFromServer,
  serverTimestamp,
} from "firebase/firestore";

export { useEffect } from "react";
export { db, storage, auth, firebaseAppCheck } from "../config/firebaseConfig";
export { default as axios } from "axios";

export const baseURL = "https://api.esetech.com.ng/v1";
// export const baseURL = "http://192.168.1.22:8080/v1"; //starlink
// export const baseURL = "http://192.168.0.170:8080/v1"; // My-Fi
// export const baseURL = "http://192.168.96.132:8080/v1"; // My-Fi

export { notification } from "utils/";
