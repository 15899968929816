import React, { useState } from "react";
import { SimpleIcon } from "./Icon";
import { Menu, MenuItem } from "@mui/material";

export default function DownloadButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="relative mt-6">
      <div className="flex w-full justify-center">
        <button
          className="relative h-[50px] w-[180px] rounded-full border
       border-primary shadow-download_button transition-all duration-500 ease-in-out  hover:-translate-y-1  hover:duration-500"
          onClick={handleClick}
        >
          <div className="primary relative flex flex-row items-center justify-center px-4">
            <div className="relative mr-6 text-sm font-bold uppercase leading-none tracking-wider text-primary">
              Download App
            </div>
            <div className="bg-primary-0 absolute right-2 rounded-full">
              <SimpleIcon icon="DownloadForOffline" />
            </div>
          </div>
        </button>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="download-app-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          list: "!p-0 !flex !flex-row !overflow-auto",
        }}
        PaperProps={{
          className: "!bg-white border border-slate-200 !shadow-lg !rounded-lg",
          elevation: 0,
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {[
          {
            icon: "Apple",
            name: "Apple Store",
            linkTo:
              "https://apps.apple.com/us/app/com.esetech.mobile/id6443486350",
          },
          {
            icon: "Android",
            name: "Google Play",
            linkTo:
              "https://play.google.com/store/apps/details?id=com.esetech.mobile",
          },
        ].map((item, index) => (
          <MenuItem
            key={index}
            className="flex h-full w-full flex-col !p-0 !text-sm"
            onClick={() => {
              return window.open(item.linkTo, "_blank");
            }}
          >
            <div className="flex flex-row items-start justify-start p-4">
              <div className="mr-2">
                <SimpleIcon icon={item.icon} otherClass="iconShadow-alt" />
              </div>
              <div>
                <div className="text-sm">Download from</div>
                <div className="-mt-1 text-base font-bold">{item.name}</div>
              </div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
