import React from "react";

export function Badge({ num }) {
  return (
    <div className="h-4 w-4 rounded-full bg-red-500">
      <div className="flex h-full w-full items-center justify-center text-xs text-white">
        {num}
      </div>
    </div>
  );
}
