import React from "react";
import { useSelector } from "react-redux";
import { useWithdrawBankAccount } from "api";

import WithdrawComp from "./sub-pages/WithdrawComp";
import NoDisplay from "../../components/NoDisplay";

export default function Index({ currentAction, displayPage }) {
  const {
    account: { withdrawalAccount: bank_Account },
    auth: {
      currentUser: { current_user },
    },
  } = useSelector((state) => state);

  const tabList = [
    {
      label: "Own account",
      id: "own-account",
      account: bank_Account?.ownDetails || [],
    },
    {
      label: "Beneficiary account",
      id: "beneficiary-account",
      account: bank_Account?.beneficiaryDetails || [],
    },
    { label: "Other account", account: null, id: "other-account" },
  ];

  const currentP = tabList.find((i) => i.id === currentAction);

  if (!displayPage) {
    return <NoDisplay type="withdrawal" />;
  }

  return (
    <WithdrawComp
      accounts={currentP?.account}
      label={currentP?.label}
      type={currentAction}
      userId={current_user}
    />
  );
}
