import * as React from "react";
import { Link } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { Slide } from "@mui/material";

import { SimpleIcon } from "components";
import { classNames } from "utils";

export default function MenuSubList({ pathname, icon, subLink }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classNames(
          pathname.includes("wallets")
            ? "rounded-lg bg-primary !text-white"
            : "",
          // nav-link
          "relative flex items-center rounded-lg py-1 px-4 text-sm font-bold text-color-main duration-500 hover:bg-primary hover:text-slate-50"
        )}
      >
        <div className="flex items-center justify-center">
          <SimpleIcon icon={icon} otherClass="!text-[18px] !text-inherit" />

          <span className="ml-1 text-sm font-bold leading-[5px]">Wallets</span>
        </div>
      </button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Slide
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              elevation={0}
              classes={{
                root: "!w-[130px]",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <ul className="flex flex-col items-start justify-center overflow-hidden rounded-lg bg-white shadow-dropdown">
                  {subLink?.map((i) => (
                    <li
                      className={classNames(
                        pathname.includes(i.linkTo)
                          ? "bg-primary/50 text-slate-50"
                          : "",
                        "h-full w-full text-center text-sm text-color-main duration-500 hover:bg-primary/50 hover:text-slate-50"
                      )}
                      key={i.name}
                    >
                      <Link to={i.linkTo} className="block h-full w-full p-2">
                        {i.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </ClickAwayListener>
            </Paper>
          </Slide>
        )}
      </Popper>
    </>
  );
}
