import React from "react";
import { ChevronRight } from "react-feather";
import { capitalize } from "lodash";
import { Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useLogout } from "api";
import NavbarTop from "./NavbarTop";
import NavbarBottom from "./NavbarBottom";
import SelectFiatCurrency from "components/SelectFiatCurrency";

export default function PageContainer({
  children,
  header,
  disableNavbarBottom,
}) {
  const [, , , logout] = useLogout();
  const { user_details } = useSelector((state) => state.profile || {});

  const { defaultCurrency } = user_details || {};

  const location = useLocation();
  const path = location.pathname.split("/").slice(1);

  return (
    <div className="page-container relative">
      <div className="navbar relative z-50">
        {!disableNavbarBottom && (
          <>
            <NavbarTop logout={logout} avatarUrl={user_details?.avatarUrl} />
            <NavbarBottom logout={logout} avatarUrl={user_details?.avatarUrl} />
          </>
        )}
      </div>

      <main className="main-content">
        {/* max-w-screen-2xl  */}
        <div className="mx-auto mt-20 max-w-screen-2xl px-0 md:mt-32 md:px-4">
          {!disableNavbarBottom && (
            <header className="mb-4 p-3 md:mb-0 md:p-4 lg:p-6">
              <div className="flex justify-between ">
                <div className="flex items-center">
                  {header && (
                    <div>
                      <h1 className="header-text text-xl font-bold">
                        {header}
                      </h1>
                    </div>
                  )}
                </div>
                <ul className="header-text hidden items-center justify-center text-sm font-bold text-color-main md:flex">
                  <li className="flex items-center">
                    <Link to="/" className="underline underline-offset-2">
                      App
                    </Link>
                    <ChevronRight strokeWidth="2px" size={16} />
                  </li>
                  {path.map((i) => {
                    const d = i !== path[path.length - 1];
                    const Block = d ? Link : Box;
                    return (
                      <li key={i} className="flex items-center">
                        <Block
                          to={`/${i}`}
                          className={d ? "underline underline-offset-2" : ""}
                        >
                          {capitalize(i)}
                        </Block>
                        {i !== path[path.length - 1] && (
                          <ChevronRight strokeWidth="2px" size={16} />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </header>
          )}
          {/* card */}
          <div className="mb-6 rounded-xl bg-card p-3 shadow-sm sm:p-4 lg:p-2">
            {children}
          </div>
        </div>
      </main>
      <SelectFiatCurrency open={user_details && !defaultCurrency} />
    </div>
  );
}
