import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";

import {
  useConvertCrypto,
  useGetSiteConvertCharge,
  useGetSiteSellRate,
  useGetUSDCryptoRate,
} from "api";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  TextField,
  WalletBalance,
} from "components";
import {
  CustomInput,
  CustomInputWithDropdown,
} from "../components/CustomInput";
import { add, lowerCase } from "lodash";
import { classNames, toFixed } from "utils";
import NoDisplay from "../components/NoDisplay";

export default function ConvertCrypto({
  currentWalletName,
  currentWalletProps: currentWallet,
  setCurrentWalletProps: setCurrentWallet,
  dropdownList,
  displayPage,
}) {
  const [amt, setAmt] = useState({
    crypto: "",
    usd: "",
    usdt: "",
    feeC: "",
    feeD: "",
    total: "",
    totalC: "",
    totalD: "",
    totalText: "",
    feesText: "",
  });

  const [deficit, setDeficit] = useState(false);
  const [deficitText, setDeficitText] = useState("");

  const { cryptoWallet } = useSelector((state) => state.account || {});

  const rate = useGetUSDCryptoRate();
  const siteSellRate = useGetSiteSellRate();
  const [isSubmitting, isSubmitted, convertCrypto] = useConvertCrypto();

  const isConvertReverse = useCallback(() => {
    if (currentWalletName === "tether") {
      return true;
    }
  }, [currentWalletName])();

  // const convertCharges = 0.05;
  const convertCharges = useGetSiteConvertCharge(
    lowerCase(currentWallet?.name)
  );

  const siteCryptoRate = siteSellRate?.[currentWallet.sym];
  const cryptoToUsdRate = rate?.[currentWallet.sym];
  const currentCryptoWallet = cryptoWallet?.[currentWallet.wallet];

  const handleAmt = (v, id) => {
    let crypto, usd, usdt, feeC, feeD, totalC, totalD, feesText, totalText;

    const e = v.replace(/[^0-9.]/g, "");
    const disabledMutlipleDot = /^[0-9]*\.?[0-9]*$/;
    const toDecimalLength = e.toString().split(".")?.[1]?.length || 0;
    const countDecimal = ["crypto", "usdt"].includes(id) ? 8 : 2;

    if (!disabledMutlipleDot.test(e) || toDecimalLength > countDecimal) {
      return;
    }

    if (id === "usd") {
      usd = e;
      usdt = toFixed(rate?.["USDT"] * e, 8);
      crypto = toFixed(parseFloat(e) / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "usdt") {
      const cryptoPerUsd = parseFloat(e) / parseFloat(rate?.["USDT"]);

      usdt = e;
      usd = toFixed(cryptoPerUsd, 2);
      crypto = toFixed(parseFloat(e) / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "crypto") {
      const cryptoPerUsd = parseFloat(cryptoToUsdRate) * parseFloat(e);
      crypto = e;
      usd = toFixed(cryptoPerUsd, 2);
      usdt = toFixed(rate?.["USDT"] * cryptoPerUsd, 8);
    }

    if (isConvertReverse) {
      const convertChargesPerc = convertCharges * usd;

      feeC = toFixed(convertChargesPerc / rate?.["USDT"], 8);
      feeD = toFixed(convertChargesPerc, 2);

      totalC = toFixed(add(parseFloat(usdt), parseFloat(feeC)), 8);
      totalD = toFixed(add(parseFloat(usd), parseFloat(feeD)), 2);

      totalText = Boolean(parseFloat(e))
        ? `$${toFixed(
            add(parseFloat(feeD), parseFloat(usd)),
            2
          )} (${totalC} USDT)`
        : "";
      feesText = Boolean(parseFloat(e)) ? `$${feeD} (${feeC} USDT)` : "";
    } else {
      const convertChargesPerc = convertCharges * usd;

      feeC = toFixed(convertChargesPerc / cryptoToUsdRate, 8);
      feeD = toFixed(convertChargesPerc, 2);

      totalC = toFixed(add(parseFloat(crypto), parseFloat(feeC)), 8);
      totalD = toFixed(add(parseFloat(usd), parseFloat(feeD)), 2);

      totalText = Boolean(parseFloat(e))
        ? `$${toFixed(add(parseFloat(feeD), parseFloat(usd)), 2)} (${totalC} ${
            currentWallet.sym
          })`
        : "";
      feesText = Boolean(parseFloat(e))
        ? `$${feeD} (${feeC} ${currentWallet.sym})`
        : "";
    }

    setAmt((prevS) => ({
      ...prevS,
      crypto,
      usd,
      usdt,
      feeC,
      feeD,
      totalC,
      totalD,
      totalText,
      feesText,
    }));
  };

  useEffect(() => {
    const typeP = isConvertReverse ? "tether" : currentWallet?.type;
    const currentCryptoWalletAmt = isConvertReverse
      ? cryptoWallet?.["tetherWallet"] || 0
      : currentCryptoWallet;

    const underLimitAmt = 0.99;

    const underLimit = parseFloat(amt?.usd) <= underLimitAmt;
    const overLimit =
      parseFloat(amt?.totalC) >=
      parseFloat(currentCryptoWalletAmt || 0).toFixed(8);

    const isDeficit = overLimit || underLimit;

    setDeficit(isDeficit);
    if (isDeficit) {
      const t = overLimit
        ? `You have exceeded your ${typeP} wallet balance`
        : underLimit
        ? `You can only sell $1 worth or more`
        : "";
      setDeficitText(t);
    } else {
      setDeficitText("");
    }
  }, [amt, currentWallet]);

  useEffect(() => {
    handleAmt(amt.crypto, "crypto");
  }, [cryptoToUsdRate, siteCryptoRate]);

  // useEffect(() => {
  //   setAmt({
  //     crypto: "",
  //     usd: "",
  //     usdt: "",
  //     feeC: "",
  //     feeD: "",
  //     totalC: "",
  //     totalD: "",
  //     totalText: "",
  //     feesText: "",
  //   });
  // }, [currentWallet]);

  const { crypto, usd, usdt, feeC, feeD, feesText, totalC, totalD, totalText } =
    amt;

  const handleSubmitConvert = () => {
    const tradeValues = {
      crypto: parseFloat(crypto),
      cryptoFees: parseFloat(isConvertReverse ? 0 : feeC),
      cryptoTotal: parseFloat(isConvertReverse ? crypto : totalC),
      usdt: parseFloat(usdt),
      usdtFees: parseFloat(isConvertReverse ? feeC : 0),
      usdtTotal: parseFloat(isConvertReverse ? totalC : usdt),
      fees: parseFloat(feeC),
      feesUsd: parseFloat(feeD),
      usd: parseFloat(usd),
      total: parseFloat(totalC),
      totalUsd: parseFloat(totalD),
      cryptoToUsdRate: parseFloat(cryptoToUsdRate),
      usdtToUsdRate: parseFloat(rate?.["USDT"]),
    };
    convertCrypto({
      tradeValues,
      sym: isConvertReverse ? "USDT" : currentWallet?.sym,
      symTo: isConvertReverse ? currentWallet?.sym : "USDT",
      tradeType: "convert",
      walletN: isConvertReverse ? "tether" : currentWallet?.type,
      walletNTo: isConvertReverse ? currentWallet?.type : "tether",
      cryptowallet: isConvertReverse ? "tetherWallet" : currentWallet?.wallet,
      cryptowalletTo: isConvertReverse ? currentWallet?.wallet : "tetherWallet",

      amountToCredit: parseFloat(
        isConvertReverse ? tradeValues?.cryptoTotal : tradeValues?.usdtTotal
      ),
      amountToDebit: parseFloat(
        isConvertReverse ? tradeValues?.usdtTotal : tradeValues?.cryptoTotal
      ),
    });
  };

  if (!displayPage) {
    return <NoDisplay type="converting" />;
  }

  return (
    <SectionContent>
      {cryptoToUsdRate && siteCryptoRate ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <Fade>
              <div className="">
                <WalletBalance
                  {...(isConvertReverse
                    ? {
                        type: "tether",
                        amount: cryptoWallet?.["tetherWallet"] || 0,
                        amountUSD:
                          (cryptoWallet?.["tetherWallet"] || 0) *
                          rate?.["USDT"],
                        sym: "USDT",
                      }
                    : {
                        type: currentWallet.type,
                        amount: currentWallet.amount,
                        amountUSD: currentWallet.amountUSD,
                        sym: currentWallet.sym,
                      })}
                />
              </div>
            </Fade>
          </div>
          <div className="col-span-1">
            <div className="relative py-2">
              <div className="grid grid-cols-1 gap-6">
                {[
                  {
                    sym: "USD",
                    name: "dollar",
                    id: "usd",
                    label: `Convert (USD)`,
                  },
                  ...(!isConvertReverse
                    ? [
                        {
                          sym: currentWallet?.sym,
                          name: currentWallet?.type,
                          id: "crypto",
                          label: `Convert (${currentWallet?.sym})`,
                        },
                      ]
                    : [
                        {
                          sym: "USDT",
                          name: "tether",
                          id: "usdt",
                          label: `Convert USDT`,
                        },
                      ]),
                ].map((item, index) => (
                  <div
                    className={classNames(
                      isConvertReverse ? "order-first" : "",
                      "col-span-1"
                    )}
                    key={item.sym}
                  >
                    <CustomInput
                      currentWallet={item}
                      // label={isConvertReverse ? "Convert" : "Receive"}
                      label={item.label}
                      value={amt?.[item.id]}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />
                    {deficit && index === 1 && (
                      <Slide down duration={300}>
                        <div className="mt-4 text-xs font-semibold text-red-500">
                          {deficitText}
                        </div>
                      </Slide>
                    )}
                  </div>
                ))}

                <div className="col-span-1">
                  <TextField
                    label="Charges"
                    value={feesText}
                    readOnly
                    disabled={isSubmitting}
                    // inputFontSize="!text-sm"
                  />
                </div>

                <div className="col-span-1">
                  <TextField
                    label="Total Convert"
                    value={totalText}
                    readOnly
                    disabled={isSubmitting}
                    // inputFontSize="!text-sm"
                  />
                </div>

                {!isConvertReverse ? (
                  <>
                    {[
                      {
                        sym: "USDT",
                        name: "tether",
                        id: "usdt",
                        label: `Total Receive USDT`,
                      },
                    ].map((item, index) => (
                      <div
                        className={classNames(
                          isConvertReverse ? "order-first" : "",
                          "col-span-1"
                        )}
                        key={item.sym}
                      >
                        <CustomInput
                          currentWallet={item}
                          // label={isConvertReverse ? "Convert" : "Receive"}
                          label={item.label}
                          value={amt?.[item.id]}
                          handleChange={(e, name) => handleAmt(e, name)}
                          disabled={isSubmitting}
                        />
                        {deficit && index === 1 && (
                          <Slide down duration={300}>
                            <div className="mt-4 text-xs font-semibold text-red-500">
                              {deficitText}
                            </div>
                          </Slide>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-span-1">
                    <CustomInputWithDropdown
                      {...{ currentWallet, setCurrentWallet }}
                      dropdownList={dropdownList}
                      dropdownId="convert-tether-crypto"
                      label={`Total Receive ${currentWallet.sym}`}
                      value={amt}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {!deficit &&
            usdt !== "" &&
            parseFloat(usdt) > 0 &&
            crypto !== "" &&
            parseFloat(crypto) > 0 &&
            parseFloat(totalC) > 0 && (
              <Fade>
                <div className="col-span-1">
                  <EnterPIN
                    btnText={`Convert ${
                      isConvertReverse ? "Tether" : currentWallet?.type
                    }`}
                    processSubmit={handleSubmitConvert}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </Fade>
            )}
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
