import React, { useCallback, useEffect, useState } from "react";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  TextField,
  WalletBalance,
} from "components";
import {
  useBonusTransfer,
  useGetFiatCharges,
  useGetSiteFiatRate,
  useGetUSDFiatRate,
} from "api";
import { Fade, Slide } from "react-reveal";
import { useSelector } from "react-redux";
import NoDisplay from "../components/NoDisplay";

export default function BonusFiat({ currentWalletName, displayPage }) {
  // useState hook
  const [amount, setAmount] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  // useSelector hook
  const {
    currentUser: { current_user: userId },
  } = useSelector((state) => state.auth || {});
  const { nairaWallet, cedisWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  // externl functions
  const usdFiatRate = useGetUSDFiatRate() || {};
  const { NGN, GHS, XOF } = usdFiatRate;

  const [isSubmitting, isSubmitted, bonusTransfer] = useBonusTransfer();

  const fiatRate = useGetSiteFiatRate() || {};
  const { cedisRate, cefaRate } = fiatRate;

  const chargesObj = useGetFiatCharges(currentWalletName);
  const {
    minAmount: minAmt,
    maxAmount: maxAmt,
    maintainMinimum: maintainAmt,
  } = chargesObj || {};

  // hooks
  const currentWalletObj = useCallback(() => {
    let bonus, sym, symUnit, type, rate, maxAmount, minAmount, maintainMax;
    switch (currentWalletName) {
      case "naira":
        bonus = nairaWallet?.nairaBonus || 0;
        symUnit = "₦";
        sym = "NGN";
        type = "naira";
        rate = NGN;
        minAmount = minAmt;
        maxAmount = maxAmt;
        maintainMax = maintainAmt;
        break;
      case "cedis":
        bonus = cedisWallet?.cedisBonus || 0;
        symUnit = "₵";
        sym = "GHS";
        type = "cedis";
        rate = GHS;
        minAmount = Math.ceil(minAmt * cedisRate);
        maxAmount = Math.ceil(maxAmt * cedisRate);
        maintainMax = Math.ceil(maintainAmt * cedisRate);
        break;
      case "cefa":
        bonus = cefaWallet?.cefaBonus || 0;
        symUnit = "F";
        sym = "XOF";
        type = "cefa";
        rate = XOF;
        minAmount = Math.ceil(minAmt * cefaRate);
        maxAmount = Math.ceil(maxAmt * cefaRate);
        maintainMax = Math.ceil(maintainAmt * cefaRate);
        break;
      default:
        break;
    }
    return {
      bonus,
      sym,
      symUnit,
      type,
      rate,
      maxAmount,
      minAmount,
      maintainMax,
      chargesObj,
    };
  }, [
    nairaWallet,
    cedisWallet,
    cefaWallet,
    currentWalletName,
    usdFiatRate,
    cedisRate,
    cefaRate,
    chargesObj,
  ])();

  useEffect(() => {
    const { bonus, minAmount, maxAmount, symUnit } = currentWalletObj;

    const total = parseInt(amount);
    const limitLow = parseInt(amount) < minAmount;
    const limitHigh = parseInt(amount) > maxAmount;
    const overlimit = total >= parseInt(bonus || 0);

    setTotalAmt(total);
    setErrorAmt(overlimit || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Withdrawal must be ${symUnit}${minAmount} and above`
        : limitHigh
        ? `Withdrawal must be ${symUnit}${maxAmount} and below`
        : ""
    );
  }, [amount]);

  // methods
  const handleAmtChange = (val) => {
    let e = val.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }
  };

  const processBonusTransfer = () => {
    const { type, maintainMax, minAmount, maxAmount } = currentWalletObj;
    const values = {
      productType: `withdraw-bonus-${type}`,
      bonusDescription: `transfer-to-${type}-wallet`,
      amountToDebit: parseInt(totalAmt),
      amountToCredit: parseInt(totalAmt),
      walletToCredit: `${type}Wallet`,
      walletToDebit: `${type}BonusWallet`,
      amount: parseInt(amount),
      totalAmount: parseInt(totalAmt),
      type,
      accountType: type,
      transferDetails: {
        amount: {
          amount: parseInt(amount),
          totalAmount: parseInt(totalAmt),
          maintainMax: parseFloat(maintainMax),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
      },
    };
    bonusTransfer(values);
  };

  if (!displayPage) {
    return <NoDisplay type="transfer" />;
  }

  return (
    <>
      {fiatRate && chargesObj ? (
        <SectionContent>
          <div className="pt-2">
            <h6 className="text-center text-gray-500">
              Transfer bonus to main account.
            </h6>
          </div>
          <div className="grid grid-cols-1 gap-4 p-2">
            <div className="col-span-1">
              <WalletBalance
                type={currentWalletObj?.type}
                amount={currentWalletObj?.bonus || 0}
                amountUSD={
                  (currentWalletObj?.bonus || 0) / currentWalletObj?.rate
                }
                sym={currentWalletObj?.sym}
                symUnit={currentWalletObj?.symUnit}
              />
            </div>

            <div className="col-span-1">
              <div className="relative">
                <div className="grid grid-cols-1 gap-6">
                  <div className="col-span-1">
                    <TextField
                      name="amount"
                      label={`Amount (${currentWalletObj?.sym})`}
                      type="text"
                      value={amount}
                      handleChange={handleAmtChange}
                      formatInput
                      disableAutoComplete
                      placeholder="0.00"
                      disabled={isSubmitting}
                    />
                    {errorAmt && (
                      <Slide down duration={300}>
                        <div className="mt-2 text-xs font-normal text-red-500">
                          {errorText}
                        </div>
                      </Slide>
                    )}
                  </div>

                  {parseFloat(amount) && !errorAmt ? (
                    <div className="col-span-1">
                      <Fade>
                        <EnterPIN
                          processSubmit={processBonusTransfer}
                          btnText="Transfer Bonus"
                          isSubmitting={isSubmitting}
                        />
                      </Fade>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </>
  );
}
