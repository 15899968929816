import React, { useEffect } from "react";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { Button } from "components";
import { useNavigate, useLocation, Navigate } from "react-router";
import { PageContainer } from "shared";
import { useSelector } from "react-redux";
import { useWalletStatus } from "api";

export default function SuccessTransaction() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [setWalletStatus] = useWalletStatus();

  useEffect(() => {
    setWalletStatus({
      mobilePopup: false,
      currentWalletAction: null,
      currentWallet: null,
    });
  }, []);

  if (!state?.noRedirect) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <PageContainer>
      <div className="m-auto h-full w-full md:w-2/3 lg:w-1/3">
        <div className="relative flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-6 text-center shadow-card-box">
          <div className="absolute -top-6 left-1/2 -translate-x-1/2 rounded-full border-8 border-white">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
              <CheckTwoToneIcon
                sx={{ fontSize: 36 }}
                className="iconShadow-alt"
              />
            </div>
          </div>
          <div className="mt-8">
            <h4 className="text-xl font-bold text-primary">Success!</h4>
            <p className="my-4">Transaction was successfully processed!</p>
          </div>
        </div>

        <div className="m-auto mt-4 w-full text-center">
          <Button
            label="Back To Dashboard"
            fullWidth
            gradient
            handleClick={() => {
              navigate("/", { replace: true });
            }}
          />
        </div>
      </div>
    </PageContainer>
  );
}
