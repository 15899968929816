import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { Form } from "components";
import { AuthContainer } from "shared";
import { registerValidationSchema } from "utils/formSchema";
import { useCheckRegisterDetails } from "api";
import "react-phone-input-2/lib/material.css";
import { useGetUrlParam } from "api/actions/getUrlParam";

export default function Register() {
  const [showReferralInput, setShowReferralInput] = useState(false);

  const [isLoading, isLoaded, userDetails, checkRegisterDetails] =
    useCheckRegisterDetails();

  const navigate = useNavigate();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get("email");
  const referralCode = new URLSearchParams(search).get("referralCode");

  const marketingReferral = new URLSearchParams(search).get("marketing");

  const refParam = useGetUrlParam("utm_source");

  const fieldList = [
    {
      name: "displayName",
      // type: "text",
      label: "Full Name",
      inputType: "textfield",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      inputType: "textfield",
    },
    {
      name: "phoneNumber",
      // type: "text",
      label: "Phone Number",
      inputType: "phonefield",
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      inputType: "textfield",
    },
    {
      name: "referralCode",
      type: "text",
      label: "Referral Code",
      inputType: "referralfield",
    },
    {
      name: "verifyChannel",
      defaultValue: false,
      options: [
        {
          label: "sms",
          value: false,
        },
        {
          label: "whatsapp",
          value: false,
        },
      ],
      type: "radio",
      label: "verification channel",
      disabled: false,
      inputType: "verifyChannel",
    },
  ];

  const handleSubmit = (values) => {
    checkRegisterDetails(values);
  };
  useEffect(() => {
    if (isLoaded && userDetails) {
      navigate("/verify-email", {
        replace: true,
        state: {
          userDetails: userDetails,
          marketingReferral: marketingReferral,
          bitMediaReferral: refParam,
        },
      });
    }
  }, [isLoaded, marketingReferral, refParam, navigate, userDetails]);

  const initialFieldValues = {
    displayName: "",
    email: email || "",
    phoneNumber: "",
    password: "",
    referralCode: referralCode || "",
    // messageChannel: "",
  };

  return (
    <AuthContainer
      title="Create Account"
      subTitle="Let’s get you started! This will only take a few minutes."
      footer={{ link: "/login", text: "Already have an account? Login!" }}
    >
      <Form
        fieldList={fieldList}
        initialFieldValues={initialFieldValues}
        buttonText="Register"
        buttonLoading={isLoading}
        handleSubmit={handleSubmit}
        validationSchema={registerValidationSchema}
        fullWidth
        extraInfo={
          <>
            <div className="text-sm">
              By clicking on the Register button, you agree to our
              <a href="/" className="text-primary">
                Privacy Policy & Terms.
              </a>
            </div>
          </>
        }
        verifyType="phoneVerify"
        displayReferralCode={referralCode || showReferralInput}
        setShowReferralInput={setShowReferralInput}
      />
    </AuthContainer>
  );
}
