import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { Button } from "components";
import { useUpdateProfileImage } from "api";

export default function ProfileImage() {
  const { user_details } = useSelector((state) => state.profile);
  const [images, setImages] = useState([]);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const [isUpdatingUpload, isUpdatingReset, isUpdated, updateImage] =
    useUpdateProfileImage();

  const handleUploadImage = (type) => {
    updateImage({ profileImage: images, type });
  };

  useEffect(() => {
    if (isUpdated) {
      setImages([]);
    }
  }, [isUpdated]);

  return (
    <div className="flex flex-row items-center text-gray-500">
      <ImageUploading
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
      >
        {({ imageList, onImageUpload, onImageRemove }) => (
          <div className="flex flex-wrap rounded-lg bg-dark/5 p-2">
            <div className="relative flex">
              <button onClick={onImageUpload}>
                <div className="h-24 w-24">
                  <img
                    src={
                      imageList?.[0]?.["data_url"] || user_details?.avatarUrl
                    }
                    alt="profile-image"
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              </button>
              {imageList.length ? (
                <div className="absolute -right-3 -top-3">
                  <button
                    className="flex h-6 w-6 items-center justify-center rounded-full bg-slate-50 text-gray-500 shadow-lg"
                    onClick={() => onImageRemove(0)}
                  >
                    <Close sx={{ fontSize: 16 }} />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </ImageUploading>
      {/* w-full  */}
      <div className="action-buttons ml-3 flex w-auto flex-col items-center gap-y-2 md:flex-row md:gap-x-2">
        <Button
          label="Upload Photo"
          gradient
          fullWidth
          handleClick={() => handleUploadImage("upload")}
          isSubmitting={isUpdatingUpload}
          disabled={!images.length}
          otherClass="!w-full !px-6"
        />
        <Button
          label="Reset"
          fullWidth
          variant="outlined"
          color="error"
          handleClick={() => handleUploadImage("reset")}
          disabled={images.length > 0}
          isSubmitting={isUpdatingReset}
          otherClass="!w-full !md:w-[150px]"
        />
        {/* <span className="mt-3 text-xs text-gray-400">
          Allowed JPG or PNG. Max size of 500KB
        </span> */}
      </div>
    </div>
  );
}
