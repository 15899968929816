import React, { useState } from "react";
import { SimpleIcon } from "components";
import { classNames } from "utils";

export default function SettingsDrawer({
  tabList,
  selectedIndex,
  setSelectedIndex,
  setMobilePopup,
}) {
  const d = {
    0: "top-[0]",
    1: "top-[80px]",
    2: "top-[160px]",
    3: "top-[240px]",
    4: "top-[320px]",
    5: "top-[400px]",
  };

  return (
    <div className="relative h-full min-w-[250px] border-r border-slate-200 bg-white">
      <div className="z-1 absolute left-0 h-full w-full">
        <div
          className={classNames(
            d[selectedIndex],
            "absolute left-0 flex h-[80px] w-full justify-end duration-500"
          )}
        >
          <div className="h-full w-1 rounded-sm bg-gradient-to-b from-dark/60 to-dark shadow-md shadow-dark/30" />
        </div>
      </div>

      {tabList.map((item, index) => {
        const cur = selectedIndex === index;
        const curTextCol = cur ? "text-dark" : "text-gray-600";
        return (
          <div key={item.label} className="nav-list h-[80px]">
            <div className="z-2 relative flex h-full w-full items-center px-3">
              <button
                className={classNames(
                  "relative flex w-full flex-row items-start justify-start rounded border-0 p-2 outline-none duration-500"
                )}
                onClick={() => {
                  setSelectedIndex(index);
                  setMobilePopup(true);
                }}
              >
                <div
                  className={classNames(
                    "flex h-8 w-8 items-center justify-center rounded-full bg-slate-50 shadow-md duration-500"
                  )}
                >
                  <SimpleIcon
                    fontSize="small"
                    icon={cur ? item.IconAlt : item.Icon}
                    frontColor={curTextCol}
                  />
                </div>

                <div className="ml-2 flex flex-col items-start justify-between">
                  <div className={classNames(curTextCol, "font-bold")}>
                    {item.label}
                  </div>
                  <div className="flex flex-col text-left">
                    <span className={classNames("mt-1 text-sm", curTextCol)}>
                      {item.subtitle}
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <div className="z-0 border-b border-slate-200" />
          </div>
        );
      })}
    </div>
  );
}
