import React from "react";
import { SelectChip, TextField, VerifyBank } from "components";
import { Slide } from "react-reveal";
import { useBreakpoint } from "utils";

export default function OthersAccount({
  errorAmt,
  errorText,
  handleAmtChange,
  setBankDetails,
  bankDetails,
  amount,
  isSubmitting,
  label,
}) {
  const breakpoint = useBreakpoint();
  return (
    <>
      <VerifyBank
        {...{ bankDetails, setBankDetails, label, isSubmitting }}
        accountType="other-account"
      />

      <div className="col-span-1">
        <SelectChip
          title="Select Amount"
          list={["5000", "10000", "20000", "50000", "100000"]}
          handleClick={(amt) => handleAmtChange(amt)}
          currData={amount}
          disabled={isSubmitting}
        />
      </div>

      <div className="col-span-1">
        <TextField
          name="amount"
          type={breakpoint.md ? "text" : "number"}
          label="Amount"
          value={amount}
          handleChange={handleAmtChange}
          startAdornment="₦"
          formatInput
          disableAutoComplete
          placeholder="0.00"
          disabled={isSubmitting}
        />
        {errorAmt && (
          <Slide down duration={300}>
            <div className="mt-2 text-xs font-normal text-red-500">
              {errorText}
            </div>
          </Slide>
        )}
      </div>
    </>
  );
}
