import React, { useState } from "react";
import { PageContainer } from "shared";
import { Container, SimpleIcon, SuccessTransaction } from "components";
import PopupContent from "./components/PopupContent";
import { classNames } from "utils";
import {
  useDisableTransactionCompleted,
  useGetUtilityTransAuth,
  useWalletStatus,
} from "api";
import { useSelector } from "react-redux";

const utilityList = [
  {
    name: "Airtime",
    btnText: "Click to purchase airtime",
    id: "purchaseAirtime",
    icon: "PermPhoneMsgTwoTone",
    displayId: "enableAirtime",
  },
  {
    name: "Data Bundle",
    btnText: "Click to subscribe for data bundle",
    id: "subscribeDataBundle",
    icon: "WifiTetheringOffTwoTone",
    displayId: "enableDataBundle",
  },
];

export default function Index() {
  const [openIsSubmitted, setIsSubmitted] = useState(false);

  const [currentPage, setCurrentPage] = useState(null);

  const [setWalletStatus] = useWalletStatus();

  const {
    walletStatus: { currentWalletAction },
    utilityAuth,
  } = useSelector((state) => state.utils || {});

  useGetUtilityTransAuth();
  useDisableTransactionCompleted(setIsSubmitted);

  return (
    <PageContainer header="Utilities">
      <div className="flex items-center justify-center gap-10">
        <Container otherclass="p-1 !h-full w-[650px]">
          <div className="flex flex-col md:flex-row">
            {utilityList.map((item, ind) => (
              <div
                key={item.name}
                className={classNames(
                  "z-2 nav-list relative flex h-[80px] w-full items-center justify-center px-2",
                  ind === 0
                    ? "border-b border-slate-200 md:border-b-0 md:border-r"
                    : ""
                )}
              >
                <button
                  className="relative flex h-full w-full items-center justify-between rounded border-0 p-2 outline-none duration-500"
                  onClick={() => {
                    setCurrentPage(item);
                    setWalletStatus({ currentWalletAction: item?.id });
                  }}
                >
                  {/* ml-2  */}
                  <div className="flex flex-col items-start">
                    <div className={classNames("font-bold")}>{item.name}</div>
                    <div className="flex flex-col text-left">
                      <span className={classNames("mt-1 text-sm")}>
                        {item.btnText}
                      </span>
                    </div>
                  </div>
                  {/* slate-50 */}
                  <div className="flex items-end">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary shadow-md duration-500">
                      <SimpleIcon
                        fontSize="small"
                        icon={item.icon}
                        frontColor={"text-white"}
                      />
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <PopupContent
        {...{
          openPopup: currentWalletAction,
          setOpenPopup: setWalletStatus,
          displayPage: utilityAuth?.[currentPage?.displayId],
        }}
      />

      {openIsSubmitted && (
        <SuccessTransaction open={openIsSubmitted} setOpen={setIsSubmitted} />
      )}
    </PageContainer>
  );
}
