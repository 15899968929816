import React, { useEffect, useState } from "react";
import { formatDateToMill } from "utils";
import { ResponsiveDialog } from "./Dialog";

export default function AdvertPopup({ advertPopup }) {
  const [open, setOpen] = useState(false);
  const [pageDelay, setPageDelay] = useState(true);

  const { imagePath, target, time } = advertPopup || {};

  useEffect(() => {
    setPageDelay(true);
    setTimeout(() => {
      setPageDelay(false);
    }, 5000);
  }, []);

  useEffect(() => {
    const durationFrom = formatDateToMill(time?.dateFrom);
    const durationTo = formatDateToMill(time?.dateTo);
    const durationPresent = formatDateToMill();

    const isDuration =
      durationPresent >= durationFrom && durationPresent <= durationTo;

    if (target?.userDashboard && isDuration) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [advertPopup, target?.userDashboard, time?.dateFrom, time?.dateTo]);

  return (
    <>
      {!pageDelay && (
        <ResponsiveDialog open={open} setOpen={setOpen}>
          <div className="mt-0 bg-[#f4f8f5] p-2">
            <img src={imagePath} alt="popup" />
          </div>
        </ResponsiveDialog>
      )}
    </>
  );
}
