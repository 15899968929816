import { useEffect } from "react";
import { Loader } from "shared";
import { useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { Navigate } from "react-router";
import { useLogout } from "api";

export const UserIsNotAuthenticated = ({ children }) => {
  const {
    auth: {
      currentUser: {
        isLoading: isLoadingAuth,
        isLoaded: isLoadedAuth,
        current_user,
      },
    },
  } = useSelector((state) => state);

  if (!current_user && isLoadingAuth) {
    return <Loader fullPage />;
  }

  if (!current_user && isLoadedAuth) {
    return <>{children}</>;
  }

  if (current_user && isLoadedAuth) {
    return <Navigate to="/" />;
  }
  return <Loader fullPage />;
};

export const UserIsAuthenticated = ({ children }) => {
  const {
    auth: {
      currentUser: {
        isLoading: isLoadingAuth,
        isLoaded: isLoadedAuth,
        current_user,
      },
    },
    profile: {
      isLoading: isLoadingProfile,
      isLoaded: isLoadedProfile,
      user_details,
    },
  } = useSelector((state) => state);
  const [, , , logout] = useLogout();

  useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: () => logout(),
    debounce: 1000,
    crossTab: true,
  });

  if (isLoadingAuth && isLoadingProfile) {
    return <Loader fullPage />;
  }

  if (!current_user && isLoadedAuth) {
    return <Navigate to="/login" />;
  }

  if (current_user && isLoadedAuth && user_details) {
    return <>{children}</>;
  }
  return <Loader fullPage />;
};
