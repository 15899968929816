import React, { useCallback, useRef } from "react";
import { omit } from "lodash";
import { useGetUSDCryptoRate, useGetUSDPriceChange } from "api";
import { ReactComponent as CustomBTC } from "../../../assets/crypto/BTC-alt.svg";
import { ReactComponent as CustomETH } from "../../../assets/crypto/ETH-alt.svg";
import { ReactComponent as CustomUSDT } from "../../../assets/crypto/USDT.svg";
import { ReactComponent as CustomBNB } from "../../../assets/crypto/BNB-alt.svg";
import { ReactComponent as CustomLTC } from "../../../assets/crypto/LTC-alt.svg";
import { classNames, formatInputNum, useBreakpoint } from "utils";
import { Fade } from "react-reveal";
import { BackdropSection } from "components";
import Slider from "react-slick";

export default function TopContent() {
  const rate = useGetUSDCryptoRate();
  const change = useGetUSDPriceChange();
  const breakpoint = useBreakpoint();

  const slider = useRef(null);

  const rateObj = useCallback(() => {
    const icons = {
      BTC: CustomBTC,
      ETH: CustomETH,
      USDT: CustomUSDT,
      BNB: CustomBNB,
      LTC: CustomLTC,
    };
    return Object.entries(omit(rate, ["USDT_TRON"])).map((i) => {
      return {
        name: i[0],
        price: i[1],
        icon: icons[i[0]],
        change: change?.[i[0]],
        CustomSvgIcon: ({ Icon, ...otherProps }) => <Icon {...otherProps} />,
      };
    });
  }, [rate, change])();

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: breakpoint.lg ? 4 : breakpoint.sm ? 2 : 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: true,
  };

  return (
    <Fade>
      <div className="mb-2 min-h-[50px] rounded-lg border border-slate-200 bg-white p-1 shadow-md">
        {rate && change ? (
          <Fade>
            <div className=" bg-gray-100">
              <Slider ref={slider} {...settings}>
                {rateObj.map((item, index) => (
                  <div className="col-span-1" key={index}>
                    <div
                      className={classNames(
                        index !== 0 ? "border-l border-slate-200" : ""
                      )}
                    >
                      <div className="flex items-center justify-start py-2 px-6">
                        <div className="mr-6 flex items-center justify-center">
                          <div className="flex h-6 w-6 items-center justify-center rounded-full border border-slate-200">
                            <item.CustomSvgIcon
                              Icon={item.icon}
                              className={classNames("h-4 w-4 fill-slate-600")}
                            />
                          </div>
                          <div className="ml-1 text-xs">{item.name}</div>
                        </div>
                        <div className="flex flex-1 justify-between">
                          <div>
                            <div className="text-sm font-bold text-slate-500">
                              {formatInputNum(item.price, 2)} USD
                            </div>
                            <div className="text-xs text-slate-400">Price</div>
                          </div>
                          <div className="flex flex-col items-end">
                            <div
                              className={classNames(
                                item.change >= 0
                                  ? "text-green-500"
                                  : "text-red-500",
                                "text-sm font-semibold"
                              )}
                            >
                              {item.change
                                ? (Number(item?.change) || parseInt(0)).toFixed(
                                    2
                                  )
                                : "-.--"}
                              %
                            </div>
                            <div className="text-xs text-slate-400">
                              Change (24hr)
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Fade>
        ) : (
          <div className="relative w-full">
            <BackdropSection open={true} />
          </div>
        )}
      </div>
    </Fade>
  );
}
