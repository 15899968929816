import React from "react";
import { classNames } from "utils";

export default function Container({ children, otherclass, disableFullHeight }) {
  return (
    <div
      className={classNames(
        // bg-card
        disableFullHeight ? "" : "h-full",
        "rounded-2xl bg-white p-2 shadow-md",
        otherclass
      )}
    >
      {children}
    </div>
  );
}

export function SectionContent({ children, disableFullHeight }) {
  return (
    <div
      className={classNames(
        // disableFullHeight ? '' : 'h-full',
        // [#f8f9ff]
        "rounded-xl border-0 border-slate-300 bg-white p-2 shadow-sm"
      )}
    >
      <div className="rounded-2xl border-0 border-slate-100">{children}</div>
    </div>
  );
}
