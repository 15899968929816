import React, { useState, useEffect } from "react";
import { uid } from "uid";
import {
  VerifyBank,
  Button,
  SectionContent,
  PopupDrawer,
  Dialog,
} from "components";
import { useUpdateWithdrawalAcc } from "api";
import { pick } from "lodash";

export function AddAccount({ openDrawer, setOpenDrawer }) {
  const [bankDetails, setBankDetails] = useState(null);

  const [isUpdating, isUpdated, updateAcct] = useUpdateWithdrawalAcc();

  const addAccount = () => {
    const details = pick(bankDetails, [
      "bankAccountNo",
      "accountName",
      "bankName",
      "bankCode",
    ]);
    const values = {
      accounts: { ...details, id: uid(16) },
      type: "add",
      accountsName: "ownDetails",
    };
    updateAcct(values);
  };

  useEffect(() => {
    if (isUpdated) {
      setOpenDrawer(false);
      setBankDetails(null);
    }
  }, [isUpdated, isUpdating]);

  return (
    <PopupDrawer
      open={openDrawer}
      setOpenDrawer={setOpenDrawer}
      keepOpen={isUpdating}
    >
      <div className="bg-gray-50 p-4 sm:flex sm:px-4">
        <h5 className="font-bold">Add Bank Account</h5>
      </div>
      <div className="mt-0 bg-[#f4f8f5] p-2">
        <div className="drawer-content">
          <SectionContent>
            <div className="p-2">
              <div className="grid grid-cols-1 gap-6">
                <VerifyBank
                  {...{ bankDetails, setBankDetails }}
                  label="Other account"
                  accountType="other-account"
                />

                <div className="col-span-1">
                  <Button
                    label="Add Account"
                    gradient
                    handleClick={addAccount}
                    disabled={!bankDetails || isUpdating}
                    isSubmitting={isUpdating}
                  />
                </div>
              </div>
            </div>
          </SectionContent>
        </div>
      </div>
    </PopupDrawer>
  );
}

export function RemoveAccount({
  openPopup,
  setOpenPopup,
  accountDelete,
  accountsName,
}) {
  const [isUpdating, isUpdated, updateAcct] = useUpdateWithdrawalAcc();

  const deleteAccount = () => {
    const values = {
      accounts: accountDelete,
      type: "remove",
      accountsName,
    };
    updateAcct(values);
  };

  useEffect(() => {
    if (isUpdated) {
      setOpenPopup(false);
    }
  }, [isUpdated]);

  return (
    <Dialog open={openPopup} setOpen={setOpenPopup} title="">
      <div className="py-3 text-center">
        <p className="mt-4 text-base text-gray-400">
          Are you sure you want to delete this account?
        </p>
        <div className="action-buttons flex justify-center space-x-2 p-4">
          <Button
            label="Delete"
            color="error"
            handleClick={deleteAccount}
            isSubmitting={isUpdating}
          />
          <Button
            label="Cancel"
            handleClick={() => setOpenPopup(false)}
            variant="outlined"
            color="neutral"
            disabled={isUpdating}
          />
        </div>
      </div>
    </Dialog>
  );
}
