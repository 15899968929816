import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import * as i from "../lib";
import {
  getNairaWallet,
  getCedisWallet,
  getCefaWallet,
  getCryptoWallet,
  getWithdrawalAccount,
  getUtilitiesBeneficiary,
  getDepositAccount,
} from "redux/reducers/walletReducers";
import { axiosCall } from "../helpers";
import { getTransactionStatus } from "redux/reducers/transactionReducers";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/wallet/${endpoint}`;

export const useSnapShot = (actionDispatcher, payloadKey, collection) => {
  const dispatch = useDispatch();

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    try {
      unsub = i.onSnapshot(i.doc(i.db, collection, userUid), (doc) => {
        dispatch(
          actionDispatcher({
            [payloadKey]: doc.data(),
          })
        );
      });
    } catch (error) {
      dispatch(
        actionDispatcher({
          [payloadKey]: null,
        })
      );
    }
    return unsub;
  }, [dispatch, userUid]);
};

export const useNairaWallet = () => {
  return useSnapShot(getNairaWallet, "nairaWallet", "nairaWallet");
};

export const useCedisWallet = () => {
  return useSnapShot(getCedisWallet, "cedisWallet", "cedisWallet");
};

export const useCefaWallet = () => {
  return useSnapShot(getCefaWallet, "cefaWallet", "cefaWallet");
};

export const useCryptoWallet = () => {
  return useSnapShot(getCryptoWallet, "cryptoWallet", "cryptoWallet");
};

export const useWithdrawBankAccount = () => {
  return useSnapShot(
    getWithdrawalAccount,
    "withdrawalAccount",
    "userTransactionDetails"
  );
};

export const useUtilitiesDetails = () => {
  return useSnapShot(
    getUtilitiesBeneficiary,
    "utilityBeneficiary",
    "userUtilityDetails"
  );
};

export const useDepositBankAccount = () => {
  return useSnapShot(
    getDepositAccount,
    "depositAccount",
    "userDepositBankDetails"
  );
};

export const useCreateDepositAcct = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const createDepositAcc = async (values) => {
    const valueFormat = {
      ...values,
      transactionReference: auth.currentUser.uid,
    };

    try {
      dispatch(getTransactionStatus(true));

      setSubmitting(true);
      const createAcct = await axiosCall(
        url("create_deposit_account"),
        valueFormat,
        true
      );

      setSubmitting(false);
      setSubmitted(true);

      dispatch(
        getDepositAccount({ isLoaded: true, depositAcct: createAcct.data.data })
      );

      dispatch(getTransactionStatus(false));

      i.notification("success", "Deposit Account successfully created.");
    } catch (error) {
      // console.log(error);
      setSubmitting(false);

      dispatch(getTransactionStatus(false));

      i.notification("danger", "An error occured! Try again later.", "error");
    }
  };

  return [isSubmitting, isSubmitted, createDepositAcc];
};

export const useGetDepositAcct = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getDepAcct = async () => {
      try {
        dispatch(getDepositAccount({ isLoaded: false }));

        const getAcct = await axiosCall(url("get_deposit_account"), {}, true);

        dispatch(
          getDepositAccount({ isLoaded: true, depositAcct: getAcct.data.data })
        );
      } catch (error) {
        // console.log(error);
        dispatch(getDepositAccount({ isLoaded: false, depositAcct: null }));

        i.notification("danger", "An error occured! Try again later.", "error");
      }
    };
    getDepAcct();
  }, []);
};
