import { useSelector } from "react-redux";

import { PageContainer } from "shared";
import { getFirstName } from "utils";

import WalletFiat from "./WalletFiat";
import WalletCrypto from "./WalletCrypto";
import ProductCard from "./ProductCard";
import AnalysisCard from "./AnalysisCard";
import TableSection from "./TableSection";
import { useUserTransAnalysisCount } from "api";

export default function Dashboard() {
  const {
    profile: { user_details },
  } = useSelector((state) => state);

  useUserTransAnalysisCount();

  return (
    <PageContainer
      header={`Hello ${getFirstName(user_details?.displayName)} 👋`}
    >
      <div className="p-1">
        <div className="grid grid-cols-8 gap-y-[10px] md:gap-[60px]">
          <WalletFiat />
          <WalletCrypto />
        </div>
        <div className="mt-[60px] grid grid-cols-1 gap-y-[60px] lg:grid-cols-3 lg:gap-[60px]">
          <div className="col-span-2">
            <div className="grid grid-cols-1  gap-[60px]">
              <div className="col-span-1">
                <ProductCard />
              </div>
              <div className="col-span-1 hidden md:block">
                <TableSection />
              </div>
              <div className="col-span-1 block md:hidden">
                <AnalysisCard />
              </div>
            </div>
          </div>
          <div className="col-span-1 hidden md:block">
            <AnalysisCard />
          </div>
          <div className="col-span-1 block md:hidden">
            <TableSection />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
