import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";

import { useSellCrypto, useGetUSDCryptoRate } from "api";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  WalletBalance,
} from "components";
import { CustomInput } from "../components/CustomInput";
import { toFixed } from "utils";
import NoDisplay from "../components/NoDisplay";

export default function SellCrypto({
  currentWalletProps: currentWallet,
  displayPage,
  siteRate,
}) {
  // useState hooks

  const [amt, setAmt] = useState({
    crypto: "",
    usd: "",
    fiat: "",
  });

  const [deficit, setDeficit] = useState(false);
  const [deficitText, setDeficitText] = useState("");

  // useSelector hooks

  const { cryptoWallet } = useSelector((state) => state.account || {});

  const {
    user_details: { defaultCurrency },
  } = useSelector((state) => state.profile);

  // variables

  const [isSubmitting, isSubmitted, sellCrypto] = useSellCrypto();

  const rate = useGetUSDCryptoRate();
  const cryptoToUsdRate = rate?.[currentWallet.sym];
  const currentCryptoWallet = cryptoWallet?.[currentWallet.wallet];
  const siteSellRate = toFixed(siteRate, 2);

  const { crypto, usd, fiat } = amt;

  // useSelector hooks

  const defaultFiat = useCallback(() => {
    if (defaultCurrency) {
      const d = {
        naira: {
          sym: "NGN",
          name: "naira",
          id: "ngn",
          fiatWallet: "nairaWallet",
        },
        cedis: {
          sym: "GHS",
          name: "cedis",
          id: "ghs",
          fiatWallet: "cedisWallet",
        },
        cefa: {
          sym: "XOF",
          name: "cefa",
          id: "xof",
          fiatWallet: "cefaWallet",
        },
      };

      return d[defaultCurrency];
    } else {
      return {};
    }
  }, [defaultCurrency, siteSellRate])();

  // useEffect hooks

  useEffect(() => {
    const underLimitAmt = 0.99;
    const underLimit = parseFloat(amt?.usd) <= underLimitAmt;
    const overLimit =
      parseFloat(amt?.crypto) >=
      parseFloat(currentCryptoWallet || 0).toFixed(8);

    const isDeficit = overLimit || underLimit;
    setDeficit(isDeficit);
    if (isDeficit) {
      const t = overLimit
        ? `You have exceeded your ${currentWallet?.type} wallet balance`
        : underLimit
        ? `You can only sell $1 worth or more`
        : "";
      setDeficitText(t);
    } else {
      setDeficitText("");
    }
  }, [amt, currentWallet]);

  useEffect(() => {
    if (!isSubmitting) {
      handleAmt(amt.crypto, "crypto");
    }
  }, [cryptoToUsdRate, siteSellRate]);

  // Methods

  const handleAmt = (v, id) => {
    let crypto, usd, fiat;

    const e = v.replace(/[^0-9.]/g, "");
    const disabledMutlipleDot = /^[0-9]*\.?[0-9]*$/;
    const toDecimalLength = e.toString().split(".")?.[1]?.length || 0;
    const countDecimal = id === "crypto" ? 8 : 2;

    if (!disabledMutlipleDot.test(e) || toDecimalLength > countDecimal) {
      return;
    }

    if (id === "usd") {
      usd = e;
      fiat = toFixed(e * siteSellRate, 2);
      crypto = toFixed(parseFloat(e) / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "fiat") {
      const ngnPerUsd = toFixed(e / siteSellRate, 2);

      fiat = e;
      usd = toFixed(ngnPerUsd, 2);
      crypto = toFixed(ngnPerUsd / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "crypto") {
      const cryptoPerUsd = parseFloat(cryptoToUsdRate) * parseFloat(e);

      crypto = e;
      usd = toFixed(cryptoPerUsd, 2);
      fiat = toFixed(cryptoPerUsd * siteSellRate, 2);
    }

    setAmt((prevS) => ({ ...prevS, crypto, usd, fiat }));
  };

  let transactionValue;

  const handleSubmitSell = () => {
    const tradeValues = {
      fiat: parseFloat(fiat),
      fiatSym: defaultFiat?.sym,
      usd: parseFloat(usd),
      crypto: parseFloat(crypto),
      cryptoToUsdRate,
      fiatRate: parseFloat(siteSellRate),
    };

    transactionValue = tradeValues;

    sellCrypto({
      tradeValues,
      sym: currentWallet?.sym,
      tradeType: "sell",
      walletN: currentWallet?.type,
      cryptowallet: currentWallet?.wallet,
      fiatWallet: defaultFiat?.fiatWallet,
    });
  };

  function pushToAnalytics() {
    const transaction = transactionValue.fiat;
    window.dataLayer.push({ transaction: null });
    window.dataLayer.push({
      event: "proccessed-crypto",
      transaction,
      eventCallback: function () {
        return;
      },
    });
  }

  const handleTrade = () => {
    handleSubmitSell();
    pushToAnalytics();
  };

  if (!displayPage) {
    return <NoDisplay type="selling" />;
  }

  return (
    <SectionContent>
      {cryptoToUsdRate &&
      ["naira", "cedis", "cefa"].includes(defaultCurrency) &&
      Boolean(siteSellRate) ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <Fade>
              <div className="rounded">
                {[
                  <>
                    Selling Rate: 1 USD ~ {siteSellRate} {defaultFiat.sym}
                  </>,
                ].map((i, index) => (
                  <div
                    key={index}
                    className="mb-2 rounded-lg bg-[#e7f7ea]/40 p-3"
                  >
                    <div className="font-bold text-[#50875a]">{i}</div>
                  </div>
                ))}

                <div className="mt-2">
                  <WalletBalance
                    type={currentWallet.type}
                    amount={currentWallet.amount}
                    amountUSD={currentWallet.amountUSD}
                    sym={currentWallet.sym}
                  />
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-span-1">
            <div className="relative py-2">
              <div className="grid grid-cols-1 gap-6">
                {[
                  {
                    sym: "USD",
                    name: "dollar",
                    id: "usd",
                    label: `Sell (USD)`,
                  },
                  {
                    sym: currentWallet?.sym,
                    name: currentWallet?.type,
                    id: "crypto",
                    label: `Sell (${currentWallet.sym})`,
                  },
                  // { sym: "NGN", name: "naira", id: "ngn", label: "Receive" },
                  {
                    sym: defaultFiat.sym,
                    name: defaultFiat.name,
                    // id: defaultFiat.id,
                    id: "fiat",
                    label: `Receive (${defaultFiat.sym})`,
                  },
                ].map((item) => (
                  <div className="col-span-1" key={item.sym}>
                    <CustomInput
                      currentWallet={item}
                      label={item.label}
                      value={amt?.[item.id]}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />
                    {deficit && item.name === currentWallet?.type && (
                      <Slide down duration={300}>
                        <div className="mt-2 px-2 text-xs font-normal text-red-500">
                          {deficitText}
                        </div>
                      </Slide>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!deficit && fiat !== "" && parseInt(fiat) > 0 && (
            <Slide up duration={300}>
              <div className="col-span-1">
                <EnterPIN
                  btnText={`Sell ${currentWallet?.type}`}
                  processSubmit={handleTrade}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Slide>
          )}
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
