import React, { useState, useEffect, useRef } from "react";
import { Slide } from "react-reveal";
import { Slide as MuiSlide } from "@mui/material";
import { classNames, useBreakpoint } from "utils";
import Scrollbars from "react-custom-scrollbars-2";
import { SimpleIcon, TextField, WalletIcon } from "components";

export function useDropDownVisible(initialIsVisible, dropdownId) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    try {
      if (
        event &&
        event.target.className?.includes(`dropdown-button-${dropdownId}`)
      ) {
        setIsDropdownVisible(!isDropdownVisible);
      } else if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDropdownVisible]);

  return { ref, isDropdownVisible, setIsDropdownVisible };
}

export function CustomInputWithDropdown({
  currentWallet,
  setCurrentWallet,
  dropdownList,
  dropdownId,
  disableDropdown,
  label,
  value,
  handleChange,
  formatInput,
  onInteract,
  disabled,
}) {
  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    dropdownId
  );

  const handleCurrentWallet = (item) => {
    setCurrentWallet(item);
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative">
      {/* focus-within:shadow-input hover:shadow-input */}
      <div className="z-[20] rounded-lg ">
        <TextField
          {...(onInteract && {
            onFocus: () => onInteract(currentWallet.id, true),
            onBlur: () => onInteract(currentWallet.id, false),
          })}
          label={label}
          disableAutoComplete
          formatInput={formatInput}
          value={value[currentWallet.id] || ""}
          disabled={disabled}
          handleChange={(e) => {
            const v = e.target.value;
            handleChange(v, currentWallet.id);
          }}
          placeholder={currentWallet.id === "crypto" ? "0.00000000" : "0.00"}
          endAdornment={
            <button
              className={classNames(
                disableDropdown || disabled
                  ? "pointer-events-none"
                  : `dropdown-button-${dropdownId}`,
                `w-full rounded-full bg-slate-50 p-2`
              )}
            >
              <div className="pointer-events-none flex flex-row items-center justify-between">
                <div className="mr-2 flex items-center">
                  <WalletIcon type={currentWallet?.type} />

                  <div className="ml-1 text-xs font-bold">
                    {currentWallet.sym}
                  </div>
                </div>
                {!disableDropdown && (
                  <SimpleIcon
                    icon={!isDropdownVisible ? "ExpandMore" : "ExpandLess"}
                  />
                )}
              </div>
            </button>
          }
        />
      </div>
      {isDropdownVisible && dropdownId && !disableDropdown && (
        <div
          ref={ref}
          // absolute
          className={`dropdown-${dropdownId} top-14 left-0 z-[10] w-full`}
        >
          <div className="h-full w-full overflow-auto rounded-lg bg-white px-1 shadow-md">
            <Slide down duration={300}>
              <Scrollbars autoHeight>
                <div className="my-1 w-full overflow-auto rounded-md border border-slate-200 bg-white shadow-md">
                  {dropdownList.map((item, index) => (
                    <button
                      key={index}
                      className={classNames(
                        index === 0 ? "" : "border-t border-slate-100",
                        currentWallet.type === item.type ? "bg-slate-100" : "",
                        "dropdown-list flex w-full flex-row items-center p-2 hover:rounded hover:bg-card"
                      )}
                      onClick={() => {
                        handleCurrentWallet(item);
                      }}
                    >
                      <div className="pointer-events-none flex flex-row items-center">
                        <WalletIcon type={item?.type} />
                        <div className="ml-3 flex flex-col items-start text-xs text-gray-400">
                          <span className="font-bold text-gray-500">
                            {item.sym}
                          </span>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </Scrollbars>
            </Slide>
          </div>
        </div>
      )}
    </div>
  );
}

export function CustomInput({
  currentWallet,
  label,
  value,
  handleChange,
  formatInput,
  disabled,
}) {
  const breakpoint = useBreakpoint();

  return (
    <div className="relative">
      {/* shadow-sm */}
      {/* focus-within:shadow-input hover:shadow-input */}
      <div className="rounded-lg ">
        <TextField
          label={label}
          type={breakpoint.md ? "text" : "number"}
          disableAutoComplete
          formatInput={formatInput}
          value={value || ""}
          disabled={disabled}
          handleChange={(e) => {
            const v = e.target.value;
            handleChange(v, currentWallet.id);
          }}
          placeholder={currentWallet.id === "crypto" ? "0.00000000" : "0.00"}
          endAdornment={
            <div className="pointer-events-none w-full rounded-full bg-slate-100 p-2">
              <div className="flex flex-row items-center justify-between">
                <div className="mr-2 flex items-center">
                  <WalletIcon type={currentWallet?.name} />

                  <div className="ml-1 text-sm font-bold">
                    {currentWallet.sym}
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export function CustomDropdown({
  currentWallet,
  setCurrentWallet,
  dropdownList,
  dropdownId,
  isSubmitting,
}) {
  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    dropdownId
  );

  const handleCurrentWallet = (item) => {
    setCurrentWallet(item);
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative">
      <button
        className={classNames(
          isSubmitting ? "pointer-events-none" : "",
          `dropdown-button-${dropdownId} w-full rounded bg-white p-3 shadow-card-box`
        )}
      >
        <div className="pointer-events-none flex flex-row items-center justify-between">
          <div className="mr-2 flex items-center">
            <WalletIcon type={currentWallet?.type} />

            <div className="ml-1 text-xs font-bold">{currentWallet.sym}</div>
          </div>
          <SimpleIcon icon={!isDropdownVisible ? "ExpandMore" : "ExpandLess"} />
        </div>
      </button>

      {isDropdownVisible && dropdownId && (
        <div
          ref={ref}
          className={`dropdown-${dropdownId} absolute top-14 left-0 z-[10] w-full`}
        >
          <div className="h-full w-full overflow-auto rounded-lg bg-white px-1 shadow-md">
            <Slide down duration={300}>
              <Scrollbars autoHeight>
                <div className="my-1 w-full overflow-auto rounded-md border border-slate-200 bg-white shadow-md">
                  {dropdownList.map((item, index) => (
                    <button
                      key={index}
                      className={classNames(
                        index === 0 ? "" : "border-t border-slate-100",
                        currentWallet.type === item.type ? "bg-slate-100" : "",
                        "dropdown-list flex w-full flex-row items-center p-2 hover:rounded hover:bg-card"
                      )}
                      onClick={() => {
                        handleCurrentWallet(item);
                      }}
                    >
                      <div className="pointer-events-none flex flex-row items-center">
                        <WalletIcon type={item?.type} />
                        <div className="ml-3 flex flex-col items-start text-xs text-gray-400">
                          <span className="font-bold text-gray-500">
                            {item.sym}
                          </span>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </Scrollbars>
            </Slide>
          </div>
        </div>
      )}
    </div>
  );
}

export function SubLinkDropdown({ subLink, dropdownId, handleClick }) {
  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    dropdownId
  );

  return (
    <>
      {isDropdownVisible && dropdownId && (
        <div
          className={`dropdown-${dropdownId} relative ml-6 flex flex-col items-start gap-2`}
          ref={ref}
        >
          {/* down duration={300}  */}
          <MuiSlide
            direction="up"
            in={isDropdownVisible}
            mountOnEnter
            unmountOnExit
          >
            <div>
              {subLink.map((itemSub) => (
                <button
                  key={itemSub.id}
                  className="relative left-0 flex w-full items-center p-3 text-sm outline-none transition-all duration-500 hover:left-2 hover:rounded-lg hover:bg-primary/5 hover:shadow-sm"
                  onClick={() => handleClick(itemSub.id, itemSub)}
                >
                  <SimpleIcon
                    icon="MoreHoriz"
                    frontColor="text-gray-400"
                    fontSize="small"
                  />
                  <span className="ml-3 text-gray-500">{itemSub.name}</span>
                </button>
              ))}
            </div>
          </MuiSlide>
        </div>
      )}
    </>
  );
}
