import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { axiosCall } from "../helpers";
import {
  createRefID,
  formatInputNum,
  getDeviceType,
  notification,
} from "utils";

import * as i from "../lib";
import { useNavigate } from "react-router-dom";
import {
  getTransactionIsCompleted,
  getTransactionStatus,
} from "redux/reducers/transactionReducers";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/utility/${endpoint}`;

export const useRetrieveDataPlan = (networkId) => {
  const [getDataPlan, setDataPlan] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setDataPlan(null);
    setLoading(true);
    const retrievePlan = async () => {
      try {
        if (networkId) {
          const dataList = await axiosCall(url("get_data_list"), {});
          const list = dataList?.data?.data?.[networkId];
          const listObj = list.map((item) => ({
            ...item,
            namePrice: `${item.name} - ₦${formatInputNum(item.price, 2)}`,
          }));
          setDataPlan(listObj);
          setLoading(false);
        }
      } catch (error) {
        notification("danger", "An error occured fetching data!", "error");
        setLoading(false);
      }
    };
    retrievePlan();
  }, [networkId]);
  return [getDataPlan, setDataPlan, isLoading];
};

export const useGetUtilityList = (type) => {
  const [utilityList, setGetUtilityList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setGetUtilityList(null);
    setLoading(true);
    const getUtilityList = async () => {
      try {
        if (type) {
          setLoading(true);
          const dataList = await axiosCall(url("get_utility_list"), { type });
          const list = dataList?.data?.data;

          setGetUtilityList(list);
          setLoading(false);
        }
      } catch (error) {
        notification("danger", "An error occured fetching data!", "error");
        setLoading(false);
      }
    };

    getUtilityList();
  }, [type]);
  return [utilityList, isLoading];
};

export const usePurchaseUtility = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const purchaseUtility = async (valuesObj) => {
    try {
      setSubmitting(true);
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      const values = {
        ...valuesObj,
        product: "utility",
        amountToCredit: "none",
        walletToCredit: "none",
        walletToDebit: "nairaWallet",
        customerId: auth.currentUser.uid,
        deviceType: getDeviceType(),
      };

      const purchaseTrans = await axiosCall(
        url("purchase_utility"),
        values,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      notification(
        "danger",
        error?.message || "An error occured! Try again later.",
        "error"
      );
    }
  };
  return [isSubmitting, isSubmitted, purchaseUtility];
};
