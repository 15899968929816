import {createSlice} from '@reduxjs/toolkit';

const initialState = {};

export const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    getPhoneList: (state, {payload}) => {
      state.phoneList = payload;
    },
  },
});

export const {getPhoneList} = utilitySlice.actions;

export default utilitySlice.reducer;
