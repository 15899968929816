import React, { useState } from "react";
import { BackdropSection, Button, SectionContent, TextField } from "components";
import { useCreateDepositAcct, useGetDepositAcct } from "api";
import { Slide } from "react-reveal";
import { useSelector } from "react-redux";
import AccountDetails from "../components/AccountDetails";
import NoDisplay from "../components/NoDisplay";

export default function FundNaira({ currentWalletName, displayPage }) {
  const [input, setInput] = useState({
    bvn: "",
  });
  const { user_details: { displayName } } = useSelector((state) => state.profile);

  const {
    depositAccount: { depositAcct, isLoaded },
  } = useSelector((state) => state.account);

  useGetDepositAcct();

  const handleInput = async (e) => {
    let val = e.target.value;
    const name = e.target.name;

    val = val.replace(/[^0-9]/g, "");

    setInput((prevS) => {
      let v = val;
      if (val.length >= 11 && name === "bvn") {
        v = val.slice(0, 11);
      }
      return { ...prevS, [name]: v };
    });
  };

  const [isSubmitting, isSubmitted, createDepositAcc] = useCreateDepositAcct();

  const disabled = !(
    input?.bvn?.length === 11 &&
    displayName?.trim()?.split(" ").length >= 2 &&
    displayName
      ?.trim()
      ?.split(" ")
      .every((i) => i.length > 2)
  );

  if (!displayPage) {
    return <NoDisplay type="funding" />;
  }

  return (
    <SectionContent>
      {isLoaded ? (
        <>
          {depositAcct ? (
            <div>
              <AccountDetails depositAcct={depositAcct} />
            </div>
          ) : (
            <>
              <div className="p-2 text-center">
                <h6 className="text-base text-gray-500">
                  Create a bank account to fund your wallet.
                </h6>
              </div>
              <div className="grid grid-cols-1 gap-6 p-2">
                <div className="col-span-1">
                  <TextField
                    name="bvn"
                    type="text"
                    label="BVN"
                    value={input?.bvn}
                    handleChange={handleInput}
                    placeholder="Input BVN"
                    disableAutoComplete
                    disabled={isSubmitting}
                  />
                </div>

                <div className="col-span-1">
                  <Slide up duration={300}>
                    <Button
                      fullWidth
                      label="Create Account"
                      gradient
                      handleClick={() =>
                        createDepositAcc({
                          ...input,
                          accountName: displayName,
                        })
                      }
                      disabled={disabled}
                      isSubmitting={isSubmitting}
                    />
                  </Slide>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
