import React from "react";
import { Dialog, SimpleIcon } from ".";
import { useBreakpoint } from "utils";

export default function SuccessTransaction({ open, setOpen }) {
  const breakpoint = useBreakpoint();

  return (
    <Dialog
      {...{ open, setOpen }}
      classes={{
        paper: "!max-w-[300px]",
      }}
    >
      <div className="relative flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-4 text-center shadow-card-box">
        {/* absolute top-2 left-1/2 -translate-x-1/2 */}
        <div className="rounded-full border-0 border-white">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
            <SimpleIcon
              icon="CheckTwoTone"
              frontColor="text-white"
              fontSize={36}
              otherClass="iconShadow-alt"
            />
          </div>
        </div>
        <div className="mt-2">
          <h4 className="text-xl font-bold text-primary">Success!</h4>
          <p className="my-2">Transaction was successfully processed!</p>
        </div>
      </div>

      {/* <div className="flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-4 text-center shadow-card-box">
        <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
          <SimpleIcon
            icon="CheckTwoTone"
            frontColor="text-white"
            fontSize={24}
            otherClass=""
          />
        </div>
        <div className="mt-4">
          <h4 className="mb-1 text-lg font-semibold text-primary">Success</h4>
          <div className="text-sm">Transaction was successfully processed!</div>
        </div>
      </div> */}
    </Dialog>
  );
}
