import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { IconBox } from "./Icon";
import { classNames } from "utils";
import { BackdropSection } from "./Dialog";

const CustomPagination = ({ setPage, count, page }) => {
  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage) - 1);
  };

  return (
    <div className="flex items-center justify-center">
      <Pagination
        variant="outlined"
        shape="rounded"
        color="primary"
        count={count}
        page={page + 1}
        onChange={handleChangePage}
      />
    </div>
  );
};

export function TableData({
  columns,
  rows,
  hideFooter,
  onRowClick,
  coloredHeader,
  defaultMsg,
}) {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = React.useState(0);

  const pageCount = Math.ceil(rows?.length / pageSize);

  return (
    <div style={{ display: "", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          classes={{
            root: "!text-gray-500 !bg-white !rounded-lg !border-0",
            main: "!rounded-lg !shadow-md",
            cell: "!outline-none",
            row: "cursor-pointer",
            columnHeader: "!outline-none !text-center uppercase",
            columnHeaders: coloredHeader
              ? "bg-primary text-white"
              : "bg-white border-none text-gray-400 !bg-[#fafafa]",
            sortIcon: coloredHeader ? "!text-white" : "",
            menuIconButton: coloredHeader ? "!text-white" : "",
            footerContainer: "!text-gray-500",
          }}
          columns={columns}
          rows={rows}
          pageSize={pageSize}
          page={page}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          hideFooterSelectedRowCount
          hideFooter={pageCount > 1 ? hideFooter : true}
          autoHeight
          getRowHeight={() => 68}
          initialState={{ pinnedColumns: { right: ["action"] } }}
          onRowClick={(params) => {
            onRowClick(params.id);
          }}
          components={{
            ...(pageCount > 1
              ? {
                  Pagination: () => (
                    <CustomPagination
                      {...{ setPage, page }}
                      count={pageCount}
                    />
                  ),
                }
              : {
                  NoRowsOverlay: () => (
                    <div className="mt-0 flex h-full flex-col items-center justify-center text-sm text-gray-400">
                      <IconBox icon={"DeveloperBoardOff"} gradient />
                      <div className="mt-4 font-semibold">
                        {defaultMsg || "No data to display"}
                      </div>
                    </div>
                  ),
                }),
          }}
        />
      </div>
    </div>
  );
}

export function SimpleTable({
  columns,
  rows,
  caption,
  isLoading,
  coloredHeader,
  emptyListText = "No data to display",
}) {
  return (
    <div className="min-h-[100px] w-full overflow-auto rounded-lg bg-white shadow-md">
      {!isLoading ? (
        <>
          {/* minWidth: 650  */}
          {/* sx={{ maxHeight: 440 }}  */}
          <TableContainer
            className={classNames(
              // "min-h-[440px]",
              rows?.length ? "m-w-[650px]" : ""
            )}
          >
            {caption && (
              <div className="caption">
                <div className="p-5 text-sm text-gray-400">{caption}</div>
                <Divider />
              </div>
            )}

            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {rows?.length && columns?.length
                    ? columns.map((column, index) => (
                        <TableCell
                          key={index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          classes={{
                            root: classNames(
                              coloredHeader
                                ? "!text-white !bg-primary"
                                : "!text-gray-400 !bg-dark/5"
                            ),
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length ? (
                  rows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              classes={{
                                root: "!text-gray-500 !whitespace-nowrap",
                              }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <div className="mt-0 flex h-full flex-col items-center justify-center p-4 text-sm text-gray-400">
                        <IconBox
                          icon={"DeveloperBoardOff"}
                          gradient
                          shadowAlt
                        />
                        <div className="mt-4 text-base font-bold">
                          {emptyListText}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <BackdropSection open={true} />
      )}
    </div>
  );
}
