import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import { useGetUSDFiatRate, useGetUtilityList, usePurchaseUtility } from "api";
import {
  Autocomplete,
  BackdropSection,
  EnterPIN,
  SectionContent,
  TextField,
  WalletBalance,
} from "components";
import NoDisplay from "pages/WalletFiat/components/NoDisplay";
import { toLower } from "lodash";

export default function DataBundle({ displayPage }) {
  const [network, setNetwork] = useState(null);
  const [networkPlan, setNetworkPlan] = useState(null);
  const [getDataList, setGetDataList] = useState(null);
  const [phoneN, setPhoneN] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { nairaWallet } = useSelector((state) => state.account || {});

  const [isSubmitting, isSubmitted, purchaseUtility] = usePurchaseUtility();

  const [utilityList, isLoadingList] = useGetUtilityList("data-bundle");

  useEffect(() => {
    const { service_type } = network || {};

    if (service_type) {
      const list =
        utilityList &&
        utilityList?.find((item) => item.service_type === service_type);

      setGetDataList(
        list?.products
          ?.map((item) => ({
            ...item,
            fixedAmount: item?.price,
          }))
          ?.sort((a, b) => a.fixedAmount - b.fixedAmount)
      );
    }
  }, [network, utilityList]);

  useEffect(() => {
    setAmount("");
    setNetworkPlan(null);
  }, [network]);

  const rate = useGetUSDFiatRate();
  const nairaToUsdRate = rate?.["NGN"];

  useEffect(() => {
    const total = parseInt(amount);
    const limitLow = parseInt(amount) < 100;
    const limitHigh = parseInt(amount) > 50000;
    const overlimit = total >= parseInt(nairaWallet?.amount || 0);
    const maintainMaximum = parseInt(nairaWallet?.amount || 0) - total < 50;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMaximum || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Purchase must be ₦100 and above.`
        : limitHigh
        ? `You can only purchase airtime amount of ₦50000 nd below`
        : maintainMaximum
        ? `Maintain a minimum of ₦50 in wallet`
        : ""
    );
  }, [amount]);

  const handlePhoneInput = (val) => {
    let e = val.target.value.replace(/[^0-9]/g, "");
    if (e.length > 10) {
      setPhoneN(e.slice(0, 10));
    } else {
      setPhoneN(e);
    }
  };

  const handleAmtChange = (val) => {
    let e = val.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }
  };

  const handlePurchase = () => {
    const { name, service_type: serviceType } = network;
    const { name: networkPlanName, datacode: networkPlanId } = networkPlan;

    const values = {
      amountToDebit: parseInt(amount),
      provider: name,
      providerName: toLower(name),
      beneficiary: `0${phoneN}`,
      productType: "data-bundle",
      productPlan: networkPlanName,
      amount: parseInt(amount),
      transactionDetails: {
        productId: networkPlanId,
        phone: `0${phoneN}`,
        amount: parseInt(amount),
        serviceType,
      },
    };

    purchaseUtility(values);
  };

  if (!displayPage) {
    return <NoDisplay type="purchase" />;
  }

  const disableEnterPin =
    parseInt(amount) &&
    !errorAmt &&
    phoneN &&
    phoneN.length === 10 &&
    network &&
    networkPlan;

  return (
    <>
      {nairaToUsdRate ? (
        <SectionContent>
          <div className="grid grid-cols-1 gap-4 p-2">
            <div className="col-span-1">
              <Fade>
                <WalletBalance
                  type={"naira"}
                  amount={nairaWallet?.amount || 0}
                  amountUSD={(nairaWallet?.amount || 0) / nairaToUsdRate}
                  sym="NGN"
                />
              </Fade>
            </div>
            <div className="col-span-1">
              <div className="relative">
                <div className="grid grid-cols-1 gap-6">
                  <div className="col-span-1">
                    <Autocomplete
                      inputLabel="Provider"
                      options={utilityList}
                      titleValue="name"
                      handleChange={setNetwork}
                      valueAlt={network}
                      disabled={isSubmitting}
                      isLoading={isLoadingList}
                    />
                  </div>

                  <div className="col-span-1">
                    <Autocomplete
                      inputLabel="Data Plan"
                      options={getDataList || []}
                      titleValue="name"
                      handleChange={(e) => {
                        setAmount(parseInt(e?.price));
                      }}
                      valueAlt={networkPlan}
                      setValueAlt={(e) => setNetworkPlan(e)}
                      disabled={isSubmitting || !getDataList}
                    />
                  </div>

                  <div className="col-span-1">
                    <TextField
                      name="amount"
                      type="text"
                      label="Amount"
                      value={amount || ""}
                      handleChange={handleAmtChange}
                      startAdornment="₦"
                      formatInput
                      disableAutoComplete
                      placeholder="0.00"
                      readOnly
                      disabled={isSubmitting}
                    />
                    {errorAmt && (
                      <Slide down duration={300}>
                        <div className="mt-2 text-xs font-normal text-red-500">
                          {errorText}
                        </div>
                      </Slide>
                    )}
                  </div>

                  <div className="col-span-1">
                    <TextField
                      name="phoneNumber"
                      type="text"
                      label="Recipient Phone Number"
                      value={phoneN || ""}
                      handleChange={handlePhoneInput}
                      disableAutoComplete
                      placeholder="8100200300"
                      startAdornment={<div className="">+234</div>}
                      disabled={isSubmitting}
                    />
                  </div>

                  {disableEnterPin ? (
                    <Fade>
                      <div className="col-span-1">
                        <EnterPIN
                          btnText={`Subscribe Data Bundle`}
                          processSubmit={handlePurchase}
                          isSubmitting={isSubmitting}
                        />
                      </div>
                    </Fade>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </>
  );
}
