import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Logout from "@mui/icons-material/Logout";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useNavigate } from "react-router";

export default function AvatarMenu({ avatarUrl, logout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            classes={{
              root: "!rounded-full !h-8 !w-8 !bg-white flex justify-center items-center !p-0 ring-gray-200 duration-100 ease-in hover:text-color-main hover:ring-4",
            }}
          >
            <Avatar
              src={avatarUrl}
              className="!h-5 !w-5"
              alt="profile-image"
              // variant="rounded"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          list: "!p-0",
        }}
        PaperProps={{
          className:
            "!bg-white border border-slate-200 !shadow-lg !rounded-lg w-40",

          elevation: 0,

          sx: {
            overflow: "visible",
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {[
          {
            Icon: AccountBalanceWalletOutlinedIcon,
            name: "Fiat Wallets",
            onClick: () => navigate("/wallets-fiat"),
          },
          {
            Icon: AccountBalanceWalletIcon,
            name: "Crypto Wallets",
            onClick: () => navigate("/wallets-crypto"),
          },
          {
            Icon: Settings,
            name: "Settings",
            onClick: () => navigate("/settings"),
          },
          { Icon: Logout, name: "Logout", onClick: () => logout() },
        ].map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: "!text-sm !p-0 flex flex-col",
            }}
            onClick={item.onClick}
          >
            <div className="w-full py-2 px-4">
              <item.Icon fontSize="small" />{" "}
              <span className="ml-1">{item.name}</span>
            </div>
            {index === 2 && <Divider className="w-full" />}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
