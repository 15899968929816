import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingSingleTrans: null,
  pendingCrypto: [],
  pendingWithdrawal: null,
  pendingUtilities: null,
  completedSingleTrans: null,
  otherCompletedSingleTrans: null,
  transactionStatus: false,
  transactionIsCompleted: false,
  isTransactionListLoading: false,
  isOtherTransactionListLoading: false,
  isTransCountLoaded: false,
  transCount: {},
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    getPendingSingleTrans: (state, { payload }) => {
      state.pendingSingleTrans = payload;
    },
    getPendingWithdrawal: (state, { payload }) => {
      state.pendingWithdrawal = payload;
    },
    getPendingUtilities: (state, { payload }) => {
      state.pendingUtilities = payload;
    },
    getPendingCrypto: (state, { payload }) => {
      state.pendingCrypto = payload;
    },
    getCompletedSingleTrans: (state, { payload }) => {
      state.completedSingleTrans = payload;
    },
    getOtherCompletedSingleTrans: (state, { payload }) => {
      state.otherCompletedSingleTrans = payload;
    },
    getTransactionStatus: (state, { payload }) => {
      state.transactionStatus = payload;
    },
    getTransactionIsCompleted: (state, { payload }) => {
      state.transactionIsCompleted = payload;
    },
    getTransactionList: (state, { payload }) => {
      state.isTransactionListLoading = payload;
    },
    getOtherTransactionList: (state, { payload }) => {
      state.isOtherTransactionListLoading = payload;
    },
    getUserTransCount: (state, { payload }) => {
      state.transCount = payload;
    },
    getTransCountLoaded: (state, { payload }) => {
      state.isTransCountLoaded = payload;
    },
  },
});

export const {
  getPendingSingleTrans,
  getPendingWithdrawal,
  getPendingUtilities,
  getPendingCrypto,
  getCompletedSingleTrans,
  getOtherCompletedSingleTrans,
  getTransactionStatus,
  getTransactionIsCompleted,
  getTransactionList,
  getOtherTransactionList,
  getUserTransCount,
  getTransCountLoaded,
} = transactionSlice.actions;

export default transactionSlice.reducer;
