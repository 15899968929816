import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BackdropSection, WalletIcon, SuccessTransaction } from "components";
import { walletCryptoProps } from "utils/constants";
import PopupContent from "./components/PopupContent";
import { useDisableTransactionCompleted } from "api";
import LeftContent from "./components/LeftContent";
import RightContent from "./components/RightContent";

export default function WalletContent() {
  const [openIsSubmitted, setIsSubmitted] = useState(false);
  const [pageDelay, setPageDelay] = useState(false);

  const {
    walletStatus: { currentWallet },
  } = useSelector((state) => state.utils);

  const cWProp = walletCryptoProps.find((i) => i.id === currentWallet);

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [currentWallet]);

  useDisableTransactionCompleted(setIsSubmitted);

  return (
    <>
      {cWProp && (
        <div className="relative hidden flex-1 md:block">
          <div className="h-full">
            <div className="flex h-[60px] items-center border-b border-slate-100 p-4">
              <div className="flex-1">
                <div className="flex items-center">
                  <WalletIcon type={currentWallet} fontSize="small" />
                  <h5 className="ml-2 font-bold">{cWProp?.name}</h5>
                </div>
              </div>
            </div>

            <div className="h-full">
              {/*  */}
              <div className="grid h-full grid-cols-11">
                <LeftContent />
                <RightContent />
              </div>
            </div>
          </div>
          <PopupContent />

          <BackdropSection open={!pageDelay} />
        </div>
      )}

      {openIsSubmitted && (
        <SuccessTransaction open={openIsSubmitted} setOpen={setIsSubmitted} />
      )}
    </>
  );
}
