import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { capitalize, startCase } from "lodash";

import { SimpleIconButton } from "components";
import WithdrawNaira from "../sub-page/WithdrawNaira";
import WithdrawFiat from "../sub-page/WithdrawFiat";
import FundNaira from "../sub-page/FundNaira";
import FundFiat from "../sub-page/FundFiat";
import TransferFiat from "../sub-page/TransferFiat";
import FiatBonus from "../sub-page/BonusFiat";
import ConvertFiat from "../sub-page/ConvertFiat";
import { useWalletStatus } from "api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupContent() {
  const [displayPage, setDisplayPage] = useState(null);

  const { transactionStatus } = useSelector((state) => state.transaction);

  const {
    walletStatus: { currentWallet, currentWalletAction: currentAction },
  } = useSelector((state) => state.utils);

  const { fiatAuth } = useSelector((state) => state.utils);

  const [walletStatus] = useWalletStatus();

  const open = Boolean(currentAction);

  useEffect(() => {
    setDisplayPage(fiatAuth?.[currentWallet]?.[currentAction]);
  }, [currentWallet, currentAction]);

  const handleClose = () => {
    if (!transactionStatus) {
      walletStatus({ currentWalletAction: null });
    }
  };

  const pages = {
    "own-account": WithdrawNaira,
    "beneficiary-account": WithdrawNaira,
    "other-account": WithdrawNaira,
    withdrawCedis: WithdrawFiat,
    withdrawCefa: WithdrawFiat,
    transfer: TransferFiat,
    fundNaira: FundNaira,
    fundCedis: FundFiat,
    fundCefa: FundFiat,
    bonus: FiatBonus,
    convert: ConvertFiat,
  };

  const Pages = pages[currentAction];

  const withdrawP = ["own-account", "beneficiary-account", "other-account"];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      // keepMounted
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        paper: "!w-[400px] !rounded-lg !overflow-visible",
      }}
    >
      <div className="absolute -right-2 -top-2 z-[502] flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
        <SimpleIconButton
          icon="Close"
          frontColor="text-primary"
          onClick={handleClose}
          fontSize="small"
          className="!p-1"
        />
      </div>

      <div className="overflow-hidden rounded-2xl bg-card p-2 text-text-color shadow-md">
        <div className="bg-gray-50 px-4 py-4 sm:flex sm:px-4">
          <div className="flow-root">
            <h5 className="font-bold">
              {withdrawP.includes(currentAction)
                ? `Withdraw Naira | ${capitalize(currentAction)}`
                : currentAction === "bonus"
                ? `Transfer ${capitalize(currentWallet)} Bonus`
                : `${
                    ["transfer", "convert"].includes(currentAction)
                      ? `${startCase(currentAction)} ${capitalize(
                          currentWallet
                        )}`
                      : startCase(currentAction)
                  }`}
            </h5>
          </div>
        </div>

        {/* {user_details?.transactionPIN ? ( */}
        <div className="mt-0 bg-[#f4f8f5] p-2">
          {currentAction && (
            <Pages
              displayPage={displayPage}
              currentWalletName={currentWallet}
              currentAction={currentAction}
            />
          )}
        </div>
        {/* ) : (
          <div className="col-span-1 md:col-span-3 lg:col-span-2">
            <div className="rounded-lg bg-white p-6 shadow-card-box">
              <div className="flex flex-col items-center justify-center">
                <IconBox icon={"PriorityHigh"} gradient shadowAlt />
                <p className="mt-4 text-sm">Create PIN to continue.</p>
                <div className="action mt-2">
                  <div className="action-buttons">
                    <Button
                      label="Create PIN"
                      gradient
                      link="/settings"
                      state={{ page: 1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </Dialog>
  );
}
