import React, { useState, useRef } from "react";
import { classNames, useBreakpoint } from "utils";
import {
  BackdropSection,
  Container,
  IconButton,
  SimpleIconButton,
  WalletAmount,
  WalletAmountUsdRate,
  WalletIcon,
} from "components";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { useFiatWalletListProps } from "api";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const FiatWallet = ({ walletId, bonusId }) => {
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [hideAmt, setHideAmt] = useState(false);

  const navigate = useNavigate();

  const { walletProps, isLoadedWalletProps } = useFiatWalletListProps();

  const props = walletProps.filter((i) => [walletId, bonusId].includes(i.id));

  const textColor = props?.[0]?.color;
  const bgColor = props?.[0]?.iconColor;
  const sym = props?.[0]?.symUnit;
  const symC = props?.[0]?.sym;
  const type = props?.[0]?.id;

  return (
    <div className={classNames("relative col-span-1 mb-2 p-1")}>
      <Fade>
        {isLoadedWalletProps && walletId && bonusId ? (
          <Container disableFullHeight otherclass="p-1">
            <div className="top-0 scale-100 overflow-hidden rounded-2xl duration-500 hover:-top-1 hover:scale-[1.01]">
              {/* md:hover:shadow-pullup */}
              <button
                className="bg-hexagons-dark h-full w-full rounded-2xl bg-white p-4"
                onClick={() =>
                  navigate("/wallets-fiat", { state: { currentId: walletId } })
                }
              >
                <div className="flex flex-row items-start justify-between">
                  <div className="flex w-full flex-row items-center justify-between">
                    <WalletIcon
                      type={type}
                      fontSize="small"
                      color={bgColor}
                      sym={sym}
                    />

                    <div
                      className={classNames(
                        // textColor,
                        "ml-2 text-base font-bold text-emerald-400"
                      )}
                    >
                      {props?.[0]?.name} Wallet
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-left">
                  <div className="flex justify-between">
                    {props.map((item, index) => (
                      <div
                        key={index}
                        className={classNames(
                          index === 1 ? "text-right" : "",
                          "flex flex-col"
                        )}
                      >
                        <div className="mb-1 text-xs tracking-wider">
                          {item.title}
                        </div>
                        <div className="flex flex-col justify-between">
                          <div className={classNames("font-bold")}>
                            {!hideAmt ? (
                              <WalletAmount
                                type={item.id}
                                amount={item.amount}
                                sym={symC}
                              />
                            ) : (
                              "**********"
                            )}
                          </div>
                          <div
                            className={classNames(
                              // textColor,
                              "-mt-1 font-bold text-emerald-400"
                            )}
                          >
                            {!hideAmt ? (
                              <WalletAmountUsdRate
                                type={item.id}
                                amount={item.amountUSD}
                              />
                            ) : (
                              "******"
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </button>
              <div className="absolute bottom-6 right-[45%]">
                <SimpleIconButton
                  icon={hideAmt ? "Visibility" : "VisibilityOff"}
                  frontColor="text-gray-500"
                  fontSize="small"
                  onClick={() => setHideAmt(!hideAmt)}
                />
              </div>
            </div>
          </Container>
        ) : (
          <div className="relative h-36 w-full overflow-auto rounded-xl bg-white shadow-md">
            <BackdropSection open={true} />
            {/* <div class="mx-auto h-full w-full rounded-md p-4">
              <div class="flex animate-pulse space-x-4">
                <div class="h-8 w-8 rounded-full bg-slate-200"></div>
                <div class="flex-1 space-y-6 py-1">
                  <div class="h-4 rounded-lg bg-slate-200" />
                </div>
              </div>
              <div class="mt-4 animate-pulse space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="col-span-2 h-6 rounded-lg bg-slate-200"></div>
                  <div class="col-span-1 h-6 rounded-lg bg-slate-200"></div>
                </div>
                <div class="h-6 rounded bg-slate-200"></div>
              </div>
            </div> */}
          </div>
        )}
      </Fade>
    </div>
  );
};

export default function WalletFiat() {
  const { user_details } = useSelector((state) => state.profile || {});

  const { defaultCurrency } = user_details || {};

  return (
    <div className="fiat-wallet relative col-span-8 -m-1 md:col-span-3">
      <div className="relative">
        <FiatWallet
          walletId={defaultCurrency}
          bonusId={defaultCurrency ? `${defaultCurrency}Bonus` : null}
        />
      </div>
    </div>
  );
}
