import React, { useState, useEffect } from "react";
import { Form } from "components";
import { pinResetSchema, changePhoneSchema } from "utils/formSchema";
import { useUpdatePin, useVerifyPin } from "api";
import { createPinFormList, changePinFormList } from "utils/constants";
import { useSelector } from "react-redux";
import { useUpdatePinAlt } from "api/actions/profile";

const initialFieldValues = { pin: "", confirmPin: "", password: "" };

const changePinInitialVal = {
  code: "",
};

export default function CreatePin({ setDrawer }) {
  const [updatedValue, setUpdatedValue] = useState();

  const {
    user_details: { email, displayName },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, verifyEmailPin] = useVerifyPin();

  // const [isUpdatingPin, isUpdatedPin, updatePIN] = useUpdatePinAlt();
  const [isUpdatingPin, isUpdatedPin, updatePIN] = useUpdatePin();

  const fieldList = createPinFormList;

  useEffect(() => {
    if (isUpdatedPin) setDrawer(false);
  }, [isUpdatedPin]);

  const handleVerify = (values) => {
    setUpdatedValue(values);
    verifyEmailPin({ ...values, displayName });
  };

  const handleSubmit = (values, resetForm) => {
    updatePIN({ ...values, ...updatedValue, sentEmail: true }, resetForm);
  };

  // const handleSubmit = (values) => {
  //   updatePIN(values);
  // };

  return (
    <div className="p-4">
      <>
        <Form
          fieldList={fieldList}
          initialFieldValues={initialFieldValues}
          buttonText="Verify"
          buttonLoading={isUpdating}
          handleSubmit={handleVerify}
          validationSchema={pinResetSchema}
          disableVisibilty={isUpdated}
          isDisabled={isUpdating}
          isDisabledFormFirstForm={isUpdated}
        />
        {isUpdated && (
          <>
            <div className="mt-6" />
            <Form
              fieldList={changePinFormList(email)}
              initialFieldValues={changePinInitialVal}
              buttonText="Save PIN"
              buttonLoading={isUpdatingPin}
              handleSubmit={handleSubmit}
              validationSchema={changePhoneSchema}
              isDisabled={isUpdatingPin}
              resetFormOnCompleted={isUpdatedPin}
            />
          </>
        )}
      </>
    </div>
  );
}

//  return (
//    <div className="p-4">
//      <Form
//        fieldList={fieldList}
//        initialFieldValues={initialFieldValues}
//        buttonText="Save PIN"
//        buttonLoading={isUpdatingPin}
//        handleSubmit={handleSubmit}
//        validationSchema={pinResetSchema}
//        disableVisibilty={isUpdated}
//        isDisabled={isUpdating}
//        isDisabledFormFirstForm={isUpdated}
//      />
//    </div>
//  );
