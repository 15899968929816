import React, { Fragment, useState, useEffect } from "react";
import {
  Badge,
  WalletAmount,
  WalletAmountUsdRate,
  WalletIcon,
} from "components";
import { classNames, useBreakpoint } from "utils";
import { useCryptoWalletListProps, useWalletStatus } from "api";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { cryptoAssetSym } from "utils/constants";

export default function CryptoDrawer() {
  const breakpoint = useBreakpoint();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { pendingCrypto } = useSelector((state) => state.transaction);

  const {
    walletStatus: { currentWallet },
  } = useSelector((state) => state.utils || {});

  const [setWalletStatus] = useWalletStatus();

  const d = {
    0: "top-[0]",
    1: "top-[80px]",
    2: "top-[160px]",
    3: "top-[240px]",
    4: "top-[320px]",
    5: "top-[400px]",
  };

  const { walletProps } = useCryptoWalletListProps();

  const walletPropsFiltered = walletProps?.filter((item) => !item?.noDisplay);

  useEffect(() => {
    const r = walletPropsFiltered.findIndex(
      (i) => i.id === currentWallet ?? walletPropsFiltered[0].id
    );

    setSelectedIndex(r);
  }, [currentWallet, walletProps]);

  useEffect(() => {
    setWalletStatus({
      currentWalletAction: null,
    });
  }, [currentWallet]);

  useEffect(() => {
    setWalletStatus({
      currentWallet: walletPropsFiltered[0]?.id,
    });
  }, []);

  return (
    <Fade>
      <div
        className={classNames(
          "relative border-r border-slate-200 bg-gray-100",
          !breakpoint.md ? "w-full rounded-lg" : "min-w-[280px]"
        )}
      >
        {d[selectedIndex] ? (
          <div className="z-1 absolute left-0 h-full w-full">
            <div
              className={classNames(
                d[selectedIndex],
                "absolute left-0 h-[80px] w-full p-2 duration-500"
              )}
            >
              <div className="h-full w-full rounded bg-gradient-to-b from-dark/60 to-dark shadow-md shadow-dark/30" />
            </div>
          </div>
        ) : null}

        {walletPropsFiltered.map((item, index) => {
          const cur = currentWallet === item.id;
          const pendingNum = pendingCrypto?.filter(
            (i) => cryptoAssetSym[i.currency] === item.assetType
          )?.length;

          return (
            <div key={item.id} className="nav-list h-[80px]">
              <div className="z-2 relative h-full p-3">
                <button
                  className={classNames(
                    "relative flex h-full w-full flex-row items-center justify-start rounded border-0 p-2 outline-none duration-500"
                  )}
                  onClick={() => {
                    setWalletStatus({
                      currentWallet: item.id,
                      mobilePopup: true,
                    });
                  }}
                >
                  <WalletIcon type={item.id} fontSize="small" />

                  <div className="ml-2 flex flex-col items-start justify-between">
                    <div
                      className={classNames(
                        cur ? "text-white" : "",
                        "font-semibold"
                      )}
                    >
                      {item.name}
                    </div>
                    <div
                      className={classNames(
                        cur ? "text-white" : "text-gray-400",
                        "flex"
                      )}
                    >
                      <div className="text-left text-sm">
                        <WalletAmount
                          type={item.id}
                          amount={item.amount}
                          sym={item.sym}
                        />
                      </div>
                      {!breakpoint.md && (
                        <div className="flex text-sm">
                          <div className="ml-2" />(
                          <div className="text-right">
                            <WalletAmountUsdRate
                              type={item.id}
                              amount={item.amountUSD}
                              // sym={item.sym}
                            />
                          </div>
                          )
                        </div>
                      )}
                    </div>
                  </div>
                  {Boolean(pendingNum) && (
                    <div className="absolute top-2 right-2">
                      <Badge num={pendingNum} />
                    </div>
                  )}
                </button>
              </div>
              <div className="z-0 border-b border-slate-200" />
            </div>
          );
        })}
      </div>
    </Fade>
  );
}
