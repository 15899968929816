import React from "react";
import AppShortcutRoundedIcon from "@mui/icons-material/AppShortcutRounded";
import { Button, Container } from "components";
import { PageContainer } from "shared";

export default function DownloadApp() {
  return (
    <PageContainer>
      <div className="m-auto h-full w-full md:w-2/3 lg:w-1/3">
        <div className="relative flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-6 text-center shadow-card-box">
          <div className="absolute -top-6 left-1/2 -translate-x-1/2 rounded-full border-8 border-white">
            <div className="flex h-14 w-14 items-center justify-center rounded-full bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
              <AppShortcutRoundedIcon
                sx={{ fontSize: 40 }}
                className="iconShadow-alt"
              />
            </div>
          </div>
          <div className="mt-6">
            <h4 className="text-lg font-bold">Download Our App!</h4>
            <p className="my-4 text-base leading-7">
              We have our mobile app that's available for both Android & IPhones
              users, the mobile app is available for download on both App Store
              and Play Store.
            </p>
          </div>
          <div className="m-auto mt-4 flex w-full justify-center gap-4 text-center">
            {[
              {
                icon: "Apple",
                label: "Apple Store",
                linkTo:
                  "https://apps.apple.com/us/app/com.esetech.mobile/id6443486350",
              },
              {
                icon: "Android",
                label: "Google Play",
                linkTo:
                  "https://play.google.com/store/apps/details?id=com.esetech.mobile",
              },
            ].map((item) => (
              <Button
                key={item.label}
                label={item.label}
                variant="outlined"
                labelIcon={item.icon}
                handleClick={() => {
                  return window.open(item.linkTo, "_blank");
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
