import React from "react";
import { Button, Container } from "components";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";

const productList = [
  {
    name: "Utilities",
    imgSrc: require("assets/images/utilities.svg").default,
    btnText: "Purchase",
    linkTo: "/utilities",
  },
];

export default function ProductCard() {
  const navigate = useNavigate();
  return (
    <Fade>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        <div className="col-span-2">
          <Container otherclass="p-1 h-full">
            <div className="flex h-full w-full flex-row items-center justify-center rounded-xl bg-primary/5 p-4">
              <div className="box-image">
                <img
                  src={require("assets/images/esetech-cart.png")}
                  // alt={item.name}
                  className="h-32 w-auto object-cover"
                />
              </div>
              {/* items-center */}
              <div className="ml-8 flex w-[60%] flex-col items-start md:items-center">
                <p className="mb-3 text-base md:text-center">
                  Kaapo is now your go-to marketplace for Giftcard trade,
                  Airtime to Cash and Digital Asset Services.
                </p>
                <Button
                  label={"Go to Kaapo"}
                  handleClick={() => {
                    return window.open("https://dashboard.kaapo.ng", "_blank");
                  }}
                  gradient
                  otherClass="!rounded-full !h-12 !text-base to-[#4275b3] from-[#3790c0]"
                />
              </div>
            </div>
          </Container>
        </div>
        <div className="col-span-2 md:col-span-1">
          {productList.map((item) => (
            <div className="col-span-1" key={item.name}>
              <Container otherclass="p-1">
                <div className=" bg-hexagons-dark flex h-full w-full flex-col items-center justify-center rounded-xl bg-white p-4">
                  <div className="box-image">
                    <img
                      src={item.imgSrc}
                      alt={item.name}
                      className="h-16 w-auto object-cover"
                    />
                  </div>
                  <div className="my-2 font-bold">{item.name}</div>
                  <Button
                    label={item.btnText}
                    handleClick={() => {
                      navigate(item.linkTo);
                    }}
                    gradient
                  />
                </div>
              </Container>
            </div>
          ))}
        </div>
      </div>
    </Fade>
  );
}
