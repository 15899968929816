import React from "react";
import { Bars } from "react-loader-spinner";
import { classNames } from "utils";

export default function Loader({ fullPage }) {
  return (
    <div
      className={classNames(
        fullPage ? "h-screen" : "",
        "flex items-center justify-center"
      )}
    >
      <Bars
        heigth="100"
        width="100"
        color="#388951"
        ariaLabel="loading-indicator"
      />
    </div>
  );
}
