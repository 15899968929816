import React from "react";

import { Form } from "components";
import { AuthContainer } from "shared";
import { resetPassValidationSchema } from "utils/formSchema";
import { useLogin, useResetPass } from "api";

const fieldList = [
  {
    name: "email",
    type: "email",
    label: "Email",
    inputType: "textfield",
  },
];

export default function ForgetPass() {
  const [isLoading, isLoaded, error, resetPass] = useResetPass();

  const initialFieldValues = { email: "" };

  const handleSubmit = (values) => {
    const { email } = values;
    resetPass(email);
  };

  return (
    <AuthContainer
      title="Reset Password"
      subTitle="Forgot your password? Not to worry. Input your email and click on send button to continue."
      footer={{ link: "/login", text: "Remembered password?" }}
    >
      <Form
        fieldList={fieldList}
        initialFieldValues={initialFieldValues}
        buttonText="Send Password Reset Email"
        buttonLoading={isLoading}
        handleSubmit={handleSubmit}
        validationSchema={resetPassValidationSchema}
        fullWidth
      />
    </AuthContainer>
  );
}
