import React, { useState } from "react";
import { useEnableTwoFactor } from "api";
import { Button } from "components";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

export default function TwoFactorPhone({ pageId }) {
  const [checked, isChecked] = useState(false);
  const {
    user_details: {
      twoFactorAuth,
      emailVerified,
      phoneVerified,
      email,
      phoneNumber,
    },
  } = useSelector((state) => state?.profile);

  const { enable2factorAuth, factorType } = twoFactorAuth || {};

  const isEnabled = enable2factorAuth && factorType === pageId;

  const [isLoading, isLoaded, enableFactor] = useEnableTwoFactor();

  if (!phoneVerified && pageId === "phoneAuthentication") {
    return (
      <div className="p-4 text-sm">
        You need to verify your phone number to continue.
      </div>
    );
  }

  if (
    !emailVerified &&
    ["appAuthentication", "emailAuthentication"].includes(pageId)
  ) {
    return (
      <div className="p-4 text-sm">
        You need to verify your email address to continue.
      </div>
    );
  }

  return (
    <div className="p-4">
      <div>
        <div className="text-sm">
          {pageId === "phoneAuthentication" && (
            <>
              Your phone number - +{phoneNumber} will be used for two-factor
              authentication.
            </>
          )}
          {pageId === "emailAuthentication" && (
            <>
              Your email address - {email} will be used for two-factor
              authentication.
            </>
          )}
          {pageId === "appAuthentication" && (
            <>
              This uses the Google Authenticator. Your email address - {email}
              will be used for two-factor authentication.
            </>
          )}
        </div>
        <div className="my-4 flex flex-row items-start">
          <Checkbox
            checked={isEnabled ? true : checked}
            disabled={isEnabled}
            size="small"
            classes={{ root: "!p-0" }}
            onChange={() => {
              isChecked(!checked);
            }}
          />
          <div className="ml-2 text-sm">
            I consent to using the above details for multi-factor authentication.
            And which includes receiving sms or email one-time-password.
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button
          label={isEnabled ? "Disable" : "Enable"}
          color={isEnabled ? "error" : "primary"}
          handleClick={() => enableFactor(!isEnabled, pageId)}
          isSubmitting={isLoading}
          disabled={isEnabled ? false : !checked}
        />
      </div>
    </div>
  );
}
