import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { Button, IconBox, SimpleIconButton } from "components";
import { capitalize, startCase } from "lodash";
import { useSelector } from "react-redux";
import { useWalletStatus } from "api";
import { classNames, useBreakpoint } from "utils";

import WithdrawNaira from "../sub-page/WithdrawNaira";
import WithdrawCedis_Cefa from "../sub-page/WithdrawFiat";
import FundNaira from "../sub-page/FundNaira";
import Fund_Cedis_Cefa from "../sub-page/FundFiat";
import TransferFiat from "../sub-page/TransferFiat";
import FiatBonus from "../sub-page/BonusFiat";
import ConvertFiat from "../sub-page/ConvertFiat";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupContentMobile() {
  const [displayPage, setDisplayPage] = useState(null);

  const { transactionStatus } = useSelector((state) => state.transaction);
  const { user_details } = useSelector((state) => state.profile);

  const {
    walletStatus: { currentWallet, currentWalletAction: currentAction },
  } = useSelector((state) => state.utils);

  const { fiatAuth } = useSelector((state) => state.utils);

  const [walletStatus] = useWalletStatus();

  const open = Boolean(currentAction);

  useEffect(() => {
    setDisplayPage(fiatAuth?.[currentWallet]?.[currentAction]);
  }, [currentWallet, currentAction]);

  const handleClose = () => {
    if (!transactionStatus) {
      walletStatus({ currentWalletAction: null });
    }
  };

  const pages = {
    "own-account": WithdrawNaira,
    "beneficiary-account": WithdrawNaira,
    "other-account": WithdrawNaira,
    withdrawCedis: WithdrawCedis_Cefa,
    withdrawCefa: WithdrawCedis_Cefa,
    transfer: TransferFiat,
    fundNaira: FundNaira,
    fundCedis: Fund_Cedis_Cefa,
    fundCefa: Fund_Cedis_Cefa,
    bonus: FiatBonus,
    convert: ConvertFiat,
  };

  const Pages = pages[currentAction];

  const withdrawP = ["own-account", "beneficiary-account", "other-account"];

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="flex justify-between">
            <h6 className="ml-2 text-lg font-bold">
              {withdrawP.includes(currentAction)
                ? `Withdraw Naira | ${capitalize(currentAction)}`
                : currentAction === "bonus"
                ? `Transfer ${capitalize(currentWallet)} Bonus`
                : `${
                    ["transfer", "convert"].includes(currentAction)
                      ? `${startCase(currentAction)} ${capitalize(
                          currentWallet
                        )}`
                      : startCase(currentAction)
                  }`}
            </h6>

            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
              <SimpleIconButton
                icon="Close"
                frontColor="text-primary"
                onClick={handleClose}
                fontSize="small"
                className="!p-1"
              />
            </div>
          </Toolbar>
        </AppBar>

        <div
          className={classNames(
            "h-full overflow-auto rounded-2xl bg-gray-50 text-text-color"
          )}
        >
          {/* {user_details?.transactionPIN ? ( */}
          <div className="mt-0 h-full bg-[#f4f8f5] p-2">
            {currentAction && (
              <Pages
                displayPage={displayPage}
                currentWalletName={currentWallet}
                currentAction={currentAction}
              />
            )}
          </div>
          {/* ) : (
            <div className="col-span-1 h-full bg-gray-50 p-2 md:col-span-3 lg:col-span-2">
              <div className="rounded-lg bg-white p-6 shadow-card-box">
                <div className="flex flex-col items-center justify-center">
                  <IconBox icon={"PriorityHigh"} gradient shadowAlt />
                  <p className="mt-4 text-sm">Create PIN to continue.</p>
                  <div className="action mt-2">
                    <div className="action-buttons">
                      <Button
                        label="Create PIN"
                        gradient
                        link="/settings"
                        state={{ page: 1 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </Dialog>
    </div>
  );
}
