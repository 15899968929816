import React from "react";
import { SimpleTable } from "components";

import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { classNames, formatDate, formatMoney, useBreakpoint } from "utils";

import { statProps } from "utils/constants";
import { useUserTransCompletedForWallet } from "api";
import { capitalize } from "lodash";

const RightContentTable = () => {
  const { completedSingleTrans, isTransactionListLoading } = useSelector(
    (state) => state.transaction
  );

  const columns = [
    { id: "date", label: "Date" },
    { id: "asset", label: "Asset" },
    { id: "action", label: "Action" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
  ];

  const rows = completedSingleTrans?.map((item) => ({
    date: formatDate(item.dateCreated),
    asset: capitalize(item?.productType?.split("-")[1]),
    action: "withdrawal",
    amount: <>{formatMoney(item?.withdrawDetails?.amount?.totalAmount)}</>,
    status: (
      <div
        className={classNames(
          statProps[item.status].bg,
          statProps[item.status].text,
          "w-fit rounded p-2 text-center text-xs font-bold"
        )}
      >
        {item.status}
      </div>
    ),
  }));

  return (
    <>
      <div className="mb-4 flex font-bold">
        <div className="mr-2 text-gray-500">Recent Transaction</div>
      </div>
      <div className="relative rounded-lg bg-white">
        <SimpleTable
          rows={rows}
          columns={columns}
          isLoading={isTransactionListLoading}
          coloredHeader
        />
      </div>
    </>
  );
};

export default function RightContent() {
  const {
    walletStatus: { currentWallet },
  } = useSelector((state) => state.utils);
  const breakpoint = useBreakpoint();

  useUserTransCompletedForWallet({
    col: "transWithdrawals",
    productType: `withdrawal-${currentWallet}`,
  });

  const Block = ({ children }) =>
    breakpoint.md ? (
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeightMin={285}
      >
        {children}
      </Scrollbars>
    ) : (
      <div>{children}</div>
    );

  return (
    <div className="col-span-6 xl:col-span-7">
      <div
        className={classNames(
          "relative w-full bg-gray-100",
          breakpoint.md ? "h-full" : "h-[320px]"
        )}
      >
        <Block>
          <div className="p-6">
            <RightContentTable />
          </div>
        </Block>
      </div>
    </div>
  );
}
