import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";

const Messanger = () => {
  const {
    auth,
    profile: { user_details },
  } = useSelector((state) => state);

  return (
    <HelmetProvider>
      <Helmet>
        <script type="text/javascript" id="zsiqchat">
          {`
            var $zoho=$zoho || {};
            $zoho.salesiq = $zoho.salesiq ||
            {
              widgetcode: 'ca71301f7d6b275364a356e0b2b0a4320363ae7115c7f8a4121352ab2defbb81', 
              values:{},
              ready:function(){}
            };
            var d=document;
            s=d.createElement('script');
            s.type="text/javascript";
            s.id="zsiqscript";
            s.defer=true;
            s.src='https://salesiq.zoho.com/widget';
            t=d.getElementsByTagName('script')[0];
            t.parentNode.insertBefore(s,t);
          `}
        </script>
        <script>
          {` 
            $zoho.salesiq.ready=function(){
              $zoho.salesiq.visitor.name(${
                user_details ? JSON.stringify(user_details.displayName) : null
              }); 
                $zoho.salesiq.visitor.email(${
                  user_details ? JSON.stringify(user_details.email) : null
                });
                $zoho.salesiq.visitor.uniqueuserid(${
                  user_details ? JSON.stringify(user_details.email) : null
                });
               $zoho.salesiq.visitor.id(${
                 auth ? JSON.stringify(auth.currentUser.current_user) : null
               });
                $zoho.salesiq.visitor.contactnumber(${
                  user_details ? JSON.stringify(user_details.phoneNumber) : null
                });
            }
          `}
        </script>
      </Helmet>
    </HelmetProvider>
  );
};

export default Messanger;
