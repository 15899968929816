import React, { useState, useRef, Fragment } from "react";

import { classNames, useBreakpoint } from "utils";
import {
  BackdropSection,
  Container,
  IconButton,
  SectionContent,
  SimpleIconButton,
  WalletAmount,
  WalletAmountUsdRate,
  WalletIcon,
} from "components";
import { Slide, Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useCryptoWalletListProps } from "api";

const singleColumn = false;

const CryptoWallet = (props) => {
  const { id, color, name, amount, amountUSD, sym, breakpoint } = props;
  const [hideAmt, setHideAmt] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="relative top-0 col-span-1 m-1 scale-100 overflow-hidden rounded-2xl shadow-pullup1 duration-500 hover:-top-1 hover:scale-[1.01]">
      <Container otherclass={breakpoint ? "p-0" : "p-1"}>
        {/* hover:shadow-pullup */}
        <div
          className="absolute top-2 right-2"
          onMouseOver={() => {
            setOpenPopup(true);
          }}
          onMouseLeave={() => {
            setOpenPopup(false);
          }}
        >
          <div className="absolute top-0 right-0">
            <SimpleIconButton icon="MoreHoriz" frontColor="text-gray-400" />
          </div>
          {openPopup && (
            <Slide right duration={200}>
              <div className="absolute top-8 right-0 rounded-lg bg-white shadow-md">
                <div className="flex p-2">
                  {[{ name: "Sell" }, { name: "Buy" }].map((item) => (
                    <button title={item.name} key={item.name}>
                      <span className="rounded px-2 py-1 text-sm font-semibold text-dark hover:bg-dark/5">
                        {item.name}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </Slide>
          )}
        </div>
        <button
          className="bg-hexagons-dark h-full w-full rounded-2xl border border-slate-50 bg-white p-4"
          onClick={() => {
            navigate("/wallets-crypto", { state: { currentId: id } });
          }}
        >
          <div className="flex flex-row items-start justify-between">
            <div
              className={classNames(
                !singleColumn
                  ? "flex-row items-center"
                  : "flex-col items-start",
                "flex justify-between"
              )}
            >
              <WalletIcon
                type={id}
                fontSize={singleColumn ? "small" : "small"}
              />

              <div
                className={classNames(
                  // color,
                  !singleColumn ? "ml-2" : "",
                  "text-base font-bold"
                )}
              >
                {name}
              </div>
            </div>
          </div>
          <div className="mt-4 text-left">
            {!singleColumn && (
              <div className="text-xs tracking-wider">Available Balance</div>
            )}
            <div className="mt-1 flex justify-between">
              <div className="flex flex-col justify-between">
                <div className={classNames("font-bold")}>
                  {!hideAmt ? (
                    <WalletAmount type={id} amount={amount} sym={sym} />
                  ) : (
                    "**********"
                  )}
                </div>
                <div
                  // text-[14px]
                  className={classNames(color, "-mt-1 font-bold text-primary")}
                >
                  {!hideAmt ? (
                    <WalletAmountUsdRate type={id} amount={amountUSD} />
                  ) : (
                    "******"
                  )}
                </div>
              </div>
            </div>
          </div>
        </button>
        <div className="absolute bottom-3 right-2">
          <SimpleIconButton
            icon={hideAmt ? "Visibility" : "VisibilityOff"}
            frontColor="text-gray-500"
            fontSize="small"
            onClick={() => setHideAmt(!hideAmt)}
          />
        </div>
      </Container>
    </div>
  );
};

export default function WalletCrypto() {
  const slider = useRef(null);

  const { walletProps, isLoadedWalletProps } = useCryptoWalletListProps();

  const breakpoint = useBreakpoint();

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: breakpoint.lg ? 3 : breakpoint.sm ? 2 : 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: breakpoint.sm ? false : true,
  };

  return (
    <Fade>
      <div className="col-span-8 md:col-span-5">
        {isLoadedWalletProps ? (
          <div className="relative">
            {breakpoint.lg && (
              <>
                <div className="absolute top-[45%] -left-4 z-20">
                  <IconButton
                    icon="ChevronLeft"
                    otherClass="h-6 w-6 bg-primary/50"
                    handleClick={() => slider?.current?.slickPrev()}
                  />
                </div>

                <div className="absolute top-[45%] -right-4 z-20">
                  <IconButton
                    icon="ChevronRight"
                    otherClass="h-6 w-6 bg-primary/50"
                    handleClick={() => slider?.current?.slickNext()}
                  />
                </div>
              </>
            )}

            <div className="relative m-auto w-full">
              <Container
                otherclass={classNames(
                  "flex-1 p-0 overflow-hidden",
                  !breakpoint.lg
                    ? "shadow-none p-0 rounded-none bg-transparent"
                    : ""
                )}
              >
                <Slider ref={slider} {...settings}>
                  {walletProps
                    ?.filter((item) => !item?.noDisplay)
                    ?.map((item) => (
                      <CryptoWallet
                        key={item.id}
                        {...item}
                        breakpoint={breakpoint.lg}
                      />
                    ))}
                </Slider>
              </Container>
            </div>
          </div>
        ) : (
          <div className="relative h-36 w-full overflow-auto rounded-xl bg-white shadow-md">
            <BackdropSection open={true} />
          </div>
        )}
      </div>
    </Fade>
  );
}
