import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";

import {
  useGetSiteSendCharge,
  useGetUSDCryptoRate,
  useSendCrypto,
  useVerifyAddress,
} from "api";
import {
  BackdropSection,
  Button,
  EnterPIN,
  SectionContent,
  TextField,
  WalletBalance,
} from "components";
import { CustomInput } from "../components/CustomInput";
import { add, lowerCase } from "lodash";
import { classNames, toFixed } from "utils";
import NoDisplay from "../components/NoDisplay";

export default function SendCrypto({
  currentWalletProps: currentWallet,
  displayPage,
}) {
  const [amt, setAmt] = useState({
    crypto: "",
    usd: "",
    feeC: "",
    feeD: "",
    totalC: "",
    totalD: "",
    totalText: "",
  });

  const [recipientAddress, setRecipientAddress] = useState({
    address: "",
    errorText: "",
  });

  const [deficit, setDeficit] = useState(false);
  const [deficitText, setDeficitText] = useState("");

  const { cryptoWallet } = useSelector((state) => state.account || {});

  const [isLoadingAddress, isValidAddress, setValid, verifyAddress] =
    useVerifyAddress();

  const [isSubmitting, isSubmitted, sendCrypto] = useSendCrypto();

  const rate = useGetUSDCryptoRate();
  const siteCharges = useGetSiteSendCharge(lowerCase(currentWallet?.name));
  const sendChargesPerc = parseFloat(siteCharges);

  const cryptoToUsdRate = rate?.[currentWallet.sym];
  const currentCryptoWallet = cryptoWallet?.[currentWallet.wallet];

  const { crypto, usd, feeC, feeD, feesText, totalC, totalD, totalText } = amt;
  const { address, errorText } = recipientAddress;

  const handleAmt = (v, id) => {
    let crypto, usd, feeC, feeD, totalC, totalD, feesText, totalText;

    const e = v.replace(/[^0-9.]/g, "");
    const disabledMutlipleDot = /^[0-9]*\.?[0-9]*$/;
    const toDecimalLength = e.toString().split(".")?.[1]?.length || 0;
    const countDecimal = id === "crypto" ? 8 : 2;

    if (!disabledMutlipleDot.test(e) || toDecimalLength > countDecimal) {
      return;
    }

    if (id === "usd") {
      usd = e;
      crypto = toFixed(parseFloat(e) / parseFloat(cryptoToUsdRate), 8);
    } else if (id === "crypto") {
      const cryptoPerUsd = parseFloat(cryptoToUsdRate) * parseFloat(e);

      crypto = e;
      usd = toFixed(cryptoPerUsd, 2);
    }

    feeC = toFixed(sendChargesPerc / cryptoToUsdRate, 8);
    feeD = toFixed(sendChargesPerc, 2);
    totalC = toFixed(add(parseFloat(crypto), parseFloat(feeC)), 8);
    totalD = toFixed(add(parseFloat(usd), parseFloat(feeD)), 2);
    totalText = Boolean(e)
      ? `$${toFixed(add(parseFloat(feeD), parseFloat(usd)), 2)} (${totalC} ${
          currentWallet.sym
        })`
      : "";
    feesText = Boolean(e) ? `$${feeD} (${feeC} ${currentWallet.sym})` : "";

    setAmt((prevS) => ({
      ...prevS,
      crypto,
      usd,
      feeC,
      feeD,
      totalC,
      totalD,
      feesText,
      totalText,
    }));

    setValid(false);
    setRecipientAddress((prevS) => ({
      ...prevS,
      errorText: "",
    }));
  };

  const handleRecipientAddress = (e) => {
    const address = e.target.value;

    setRecipientAddress({
      address,
      errorText: "",
    });
  };

  const handleVerifyAddress = () => {
    const callback = (text) =>
      setRecipientAddress((prevS) => ({
        ...prevS,
        errorText: text,
      }));
    verifyAddress(
      { address: recipientAddress?.address, crypto: currentWallet?.sym_verify },
      callback
    );
  };

  useEffect(() => {
    const underLimitAmt = 0.99;
    const overLimitAmt = 200;

    const underLimit =
      parseFloat(amt?.usd) <= underLimitAmt ||
      parseFloat(amt?.usd) > overLimitAmt;
    const overLimit =
      parseFloat(amt?.totalC) >=
      parseFloat(currentCryptoWallet || 0).toFixed(8);

    const isDeficit = overLimit || underLimit;
    setDeficit(isDeficit);
    if (isDeficit) {
      const t = overLimit
        ? `You have exceeded your ${currentWallet?.type} wallet balance`
        : underLimit
        ? `You can only send from $1 ~ $200 worth`
        : "";
      setDeficitText(t);
    } else {
      setDeficitText("");
    }
  }, [amt, currentWallet]);

  useEffect(() => {
    if (!isSubmitting) {
      handleAmt(amt.crypto, "crypto");
    }
  }, [cryptoToUsdRate]);

  const handleSubmitSend = () => {
    const tradeValues = {
      crypto: parseFloat(crypto),
      fees: parseFloat(feeC),
      feesUsd: parseFloat(feeD),
      usd: parseFloat(usd),
      recipientAddress: address,
      total: parseFloat(totalC),
      totalUsd: parseFloat(totalD),
    };
    sendCrypto({
      tradeValues,
      sym: currentWallet?.sym,
      tradeType: "send",
      walletN: currentWallet?.type,
      cryptowallet: currentWallet?.wallet,
    });
  };

  if (!displayPage) {
    return <NoDisplay type="sending" />;
  }

  return (
    <SectionContent>
      {cryptoToUsdRate && sendChargesPerc ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <Fade spy={currentWallet}>
              <WalletBalance
                type={currentWallet.type}
                amount={currentWallet.amount}
                amountUSD={currentWallet.amountUSD}
                sym={currentWallet.sym}
              />
            </Fade>
          </div>
          <div className="col-span-1">
            <div className="relative py-2">
              <div className="grid grid-cols-1 gap-6">
                {[
                  { sym: "USD", name: "dollar", id: "usd", label: `Send` },
                  {
                    sym: currentWallet?.sym,
                    name: currentWallet?.type,
                    id: "crypto",
                    label: `Receive`,
                  },
                ].map((item, index, arr) => (
                  <div className="col-span-1" key={item.sym}>
                    <CustomInput
                      currentWallet={item}
                      label={item.label}
                      value={amt?.[item.id]}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />

                    {deficit && index === arr.length - 1 && (
                      <Slide down duration={300}>
                        <div className="mt-4 text-xs font-semibold text-red-500">
                          {deficitText}
                        </div>
                      </Slide>
                    )}
                  </div>
                ))}
                <div className="col-span-1">
                  <TextField
                    label="Fees"
                    value={feesText}
                    readOnly
                    disabled={isSubmitting}
                  />
                </div>
                <div className="col-span-1">
                  <TextField
                    label="Total Send"
                    value={totalText}
                    readOnly
                    disabled={isSubmitting}
                  />
                </div>
                <div className="col-span-1">
                  <TextField
                    label="Recipient's Address"
                    value={address || ""}
                    disableAutoComplete
                    handleChange={handleRecipientAddress}
                    disabled={isSubmitting}
                    endAdornment={
                      <Button
                        label="Verify"
                        gradient
                        handleClick={handleVerifyAddress}
                        isSubmitting={isLoadingAddress}
                        disabled={isSubmitting}
                      />
                    }
                  />
                  {Boolean(errorText) && (
                    <Slide down duration={300} spy={isLoadingAddress}>
                      <div
                        className={classNames(
                          isValidAddress ? "text-emerald-500" : "text-red-500",
                          "mt-4 text-xs font-semibold"
                        )}
                      >
                        {errorText}
                      </div>
                    </Slide>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!deficit &&
            crypto !== "" &&
            parseFloat(crypto) > 0 &&
            isValidAddress && (
              <Slide up duration={300}>
                <div className="col-span-1">
                  <EnterPIN
                    btnText={`Send ${currentWallet?.type}`}
                    processSubmit={handleSubmitSend}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </Slide>
            )}
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </SectionContent>
  );
}
