import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import OTPInput from "otp-input-react";

import { AuthContainer } from "shared";
import { useRegister } from "api";
import { Button } from "components";
import { formatTime } from "utils";
import { RadioInput } from "components/Input";

const RegisterOTP = ({ navigation, route }) => {
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailcodeCounter, setEmailCodeCounter] = useState(0);
  const [phonecodeCounter, setPhoneCodeCounter] = useState(0);
  const [messageChannel, setMessageChannel] = useState("sms");
  const [
    isLoading,
    isLoaded,
    register,
    sendEmailCodeStatus,
    setSendEmailCodeStatus,
    sendPhoneCodeStatus,
    setSendPhoneCodeStatus,
    phoneOTPRef,
    resendEmailOtpCode,
    resendPhoneOtpCode,
  ] = useRegister();

  const { state } = useLocation();

  const navigate = useNavigate();

  const handleEmailComparePIN = (e) => {
    setEmailOtp(e);
  };

  const handleComparePhonePIN = (e) => {
    setPhoneOtp(e);
  };

  const handleResendEmailCode = () => {
    resendEmailOtpCode(state?.userDetails);
    setSendEmailCodeStatus(true);
  };

  const handleResendPhoneCode = () => {
    resendPhoneOtpCode(state?.userDetails);
    setSendPhoneCodeStatus(true);
  };

  const fieldList = [
    {
      name: "verifyChannel",
      defaultValue: false,
      options: [
        {
          label: "sms",
          value: false,
          checked: true,
        },
        // {
        //   label: "whatsapp",
        //   value: false,
        // },
      ],
      type: "radio",
      label: "phone verification channel",
      disabled: false,
      inputType: "verifyChannel",
    },
  ];

  const handleVerify = () => {
    register({
      values: state.userDetails,
      code: emailOtp,
      phoneCode: phoneOtp,
      phoneOTPRef: phoneOTPRef == null ? state.phoneOTPRef : phoneOTPRef,
      referralParam:
        state.marketingReferral || state.bitMediaReferral || "organic",
    });
  };

  useEffect(() => {
    if (!state) {
      navigate("/register", {
        replace: true,
      });
    }
  }, [navigate, state]);

  useEffect(() => {
    setSendEmailCodeStatus(false);
    setSendPhoneCodeStatus(false);

    if (!state) {
      navigate("/register", {
        replace: true,
      });
    }
  }, [navigate, setSendEmailCodeStatus, state, setSendPhoneCodeStatus]);

  const handleTimer = (timerStatus, setTimerStatus, timerCount, time) => {
    let downloadTimer;

    if (timerStatus) {
      let timeleft = time;

      downloadTimer = setInterval(() => {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setTimerStatus(false);
        } else {
          timerCount(timeleft);
        }
        timeleft -= 1;
      }, 1000);
    }

    return () => {
      clearInterval(downloadTimer);
    };
  };

  useEffect(() => {
    const cleanupEmailTimer = handleTimer(
      sendEmailCodeStatus,
      setSendEmailCodeStatus,
      setEmailCodeCounter,
      60
    );

    return () => {
      cleanupEmailTimer();
    };
  }, [sendEmailCodeStatus, setSendEmailCodeStatus]);

  useEffect(() => {
    const cleanupPhoneTimer = handleTimer(
      sendPhoneCodeStatus,
      setSendPhoneCodeStatus,
      setPhoneCodeCounter,
      60
    );

    return () => {
      cleanupPhoneTimer();
    };
  }, [sendPhoneCodeStatus, setSendPhoneCodeStatus]);

  return (
    <AuthContainer
      title="Account Verification"
      subTitle="We'll like to verify you! This will only take a few minutes."
    >
      <div>
        <div className="my-2 w-[90%] rounded-sm bg-gray-100/60 px-3 pt-3 pb-5 shadow-sm">
          <p className="font-bold capitalize">Email verification</p>

          <div className="my-1">
            <button
              className="text-xs text-color-main"
              onClick={handleResendEmailCode}
              disabled={sendEmailCodeStatus}
            >
              {emailcodeCounter <= 1 ? (
                <p className="pt-1 pb-2 text-left text-xs ">
                  Send sms code to <br />
                  {state?.userDetails?.email}
                  <span className="pl-1 text-sm text-primary">Send Now</span>
                </p>
              ) : (
                <>Retry send code - {formatTime(emailcodeCounter)}s</>
              )}
            </button>
          </div>

          <OTPInput
            value={emailOtp}
            onChange={handleEmailComparePIN}
            OTPLength={6}
            otpType="number"
            disabled={false}
            // secure={secure}
            inputClassName="border rounded-md border-gray-300 text-gray-500 !mr-1 !h-10 !w-10 focus:border-color-main outline-none focus:border-2 focus: rounded-sm"
          />
        </div>
        <div className="my-2 w-[90%] rounded-sm bg-gray-100/60 px-3 pt-3 pb-5 shadow-sm">
          <p className="font-bold capitalize">Phone Number verification</p>

          <div className="my-3 text-xs">
            <button
              className="text-xs text-color-main"
              onClick={handleResendPhoneCode}
              disabled={sendPhoneCodeStatus}
            >
              {phonecodeCounter <= 1 ? (
                <p className="pt-1 pb-2 text-left text-xs ">
                  Send sms code to &nbsp;
                  {state?.userDetails?.phoneNumber}
                  <span className="pl-1 text-sm text-primary">Send Now</span>
                </p>
              ) : (
                <>Retry send code - {formatTime(phonecodeCounter)}s</>
              )}
            </button>

            {/* {fieldList.map((f) => (
              <>
                <RadioInput
                  className={"!flex bg-yellow-500 "}
                  handleChange={(e) => {
                    setMessageChannel(e);
                  }}
                  inputLabel={f.label}
                  options={f.options}
                  type={f.type}
                  disabled={f.disabled}
                  checked={f.options.map((p) => p.checked)}
                  sendPhoneCodeStatus={sendPhoneCodeStatus}
                  handleResendPhoneCode={handleResendPhoneCode}
                  phonecodeCounter={phonecodeCounter}
                  handleSubmit={handleSendCode}
                  userDetails={state?.userDetails}
                />
              </>
            ))} */}
          </div>

          <OTPInput
            value={phoneOtp}
            onChange={handleComparePhonePIN}
            OTPLength={6}
            otpType="number"
            disabled={false}
            // secure={secure}
            inputClassName="border rounded-md border-gray-300 text-gray-500 !mr-1 !h-10 !w-10 focus:border-color-main outline-none focus:border-2 focus: rounded-sm"
          />
        </div>

        <div className="mt-6">
          <Button
            label="Verify"
            gradient
            handleClick={handleVerify}
            disabled={emailOtp.length < 6 || phoneOtp.length < 4}
            isSubmitting={isLoading}
            // fullWidth
          />
        </div>
      </div>
    </AuthContainer>
  );
};
export default RegisterOTP;
