import React from "react";
import { formatMoney } from "utils";
import { Divider } from "@mui/material";

export default function TotalTrans({ totalAmt, charges, per, sym = "NGN" }) {
  return (
    <div className="col-span-1">
      <div className="flex w-full items-center justify-between rounded-lg border-2 border-dotted border-color-main/25 p-1">
        <div className="w-full flex-1 p-3 text-center">
          <div className="text-xs">
            {per ? "Amount Received" : "Total Amount"}
          </div>
          <h2 className="text-center text-lg font-bold text-color-main">
            {formatMoney(totalAmt || 0, "hidden")}{" "}
            <span className="md:text-sm">{sym}</span>
          </h2>
        </div>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="!border-color-main/25"
        />
        <div className="w-full flex-1 p-3 text-center">
          <div className="text-xs">Charges</div>
          <h2 className="text-center text-lg font-bold text-color-main">
            {charges.toString() && (
              <>
                {formatMoney(charges, "hidden")}{" "}
                <span className="md:text-sm">{sym}</span>
              </>
            )}
            {per && (
              <>
                {per}
                <span className="md:text-sm">%</span>
              </>
            )}
          </h2>
        </div>
      </div>
    </div>
  );
}
