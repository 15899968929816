import React, { useState, useEffect, useRef } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useSelector } from "react-redux";
import { useDisableTransactionCompleted, useWalletStatus } from "api";
import { SuccessTransaction, WalletIcon } from "components";
import { walletCryptoProps } from "utils/constants";
import theme from "tailwindcss/defaultTheme";
import LeftContent from "./components/LeftContent";
import RightContent from "./components/RightContent";
import PopupContentMobile from "./components/PopupContentMobile";
import { useScroll } from "utils";

const drawerBleeding = 64;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function MobileContent() {
  const [openIsSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = useState(false);

  const scrollRef = useRef(null);

  const { boxShadow } = theme;

  const {
    walletStatus: { currentWallet, mobilePopup },
  } = useSelector((state) => state.utils || {});

  const [setWalletStatus] = useWalletStatus();

  const [executeScroll, elRef] = useScroll();

  const cWProp = walletCryptoProps.find((i) => i.id === currentWallet);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen ? newOpen && Boolean(currentWallet) : newOpen);
    if (!newOpen) {
      setWalletStatus({ mobilePopup: false });
    }
  };

  useEffect(() => {
    if (Boolean(currentWallet) && mobilePopup) {
      setOpen(true);
    }
  }, [currentWallet, mobilePopup]);

  useEffect(() => {
    executeScroll();
  }, [mobilePopup]);

  useDisableTransactionCompleted(setIsSubmitted);

  return (
    <div className="">
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      {cWProp && (
        <SwipeableDrawer
          // container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              visibility: "visible",
              right: 0,
              left: 0,
              background: "rgb(248 250 252)",
              boxShadow: boxShadow.sm,
              borderTop: "1px solid rgb(226 232 240)",
            }}
          >
            <Puller />

            <div className="flex h-[64px] items-center px-4">
              <div className="flex-1">
                <div className="flex items-center">
                  <WalletIcon type={currentWallet} fontSize="medium" />
                  <h5 className="ml-2 font-bold">{cWProp?.name}</h5>
                </div>
              </div>
            </div>
          </StyledBox>
          <div className="h-full overflow-auto" ref={elRef}>
            <div className="rounded-lg">
              <LeftContent />
              <RightContent />
            </div>
          </div>
        </SwipeableDrawer>
      )}
      <PopupContentMobile />
      {openIsSubmitted && (
        <SuccessTransaction open={openIsSubmitted} setOpen={setIsSubmitted} />
      )}
    </div>
  );
}

export default MobileContent;
