import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: null,
  unread: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNofications: (state, { payload }) => {
      state.notifications = payload;
    },
    getUnReadNofications: (state, { payload }) => {
      state.unread = payload;
    },
  },
});

export const { getNofications, getUnReadNofications } =
  notificationSlice.actions;

export default notificationSlice.reducer;
