import React, { useEffect, useState } from "react";
import { useLogout } from "api";
import { IconBox, PopupDrawer, Button } from ".";

export default function SetTimerLogout({ logoutTimer }) {
  const [timer, setTimer] = useState(20);
  const [_, __, ___, logout] = useLogout();

  useEffect(() => {
    let downloadTimer;
    setTimer(20);
    if (logoutTimer) {
      let timeleft = 20;
      downloadTimer = setInterval(() => {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          logout();
        } else {
          setTimer(timeleft);
        }
        timeleft -= 1;
      }, 1000);
    }
    return () => {
      clearInterval(downloadTimer);
    };
  }, [logoutTimer]);

  return (
    <PopupDrawer open={logoutTimer} showCloseButton={false}>
      <div className="p-4">
        <div className="flex justify-center border-b border-slate-200 pb-4">
          <IconBox icon="Logout" gradient />
          <div className="ml-4" front>
            You will be automatically logged out in {timer} seconds. Kindly log
            back in with your new login details.
          </div>
        </div>
        <div className="pt-4 text-right">
          <Button label="Logout" handleClick={logout} />
        </div>
      </div>
    </PopupDrawer>
  );
}
