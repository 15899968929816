import React from "react";
import { Fade } from "react-reveal";
import { IconBox, WalletIcon } from "components";
import { formatCrypto, formatInputNum } from "utils";

export function WalletBalance({
  type,
  amount,
  amountUSD,
  sym,
  symUnit,
  title,
}) {
  return (
    <Fade duration={300}>
      <div className="col-span-1 md:col-span-1">
        {/* shadow-card-box */}
        <div className="rounded-lg border border-slate-200 bg-white p-4 shadow-none">
          <div className="flex items-center justify-between">
            <WalletIcon type={type} fontSize="medium" />

            <div className="text-right">
              <div className="mb-1 text-sm text-gray-400">
                {Boolean(title) ? `${title} ` : ""}Wallet Balance
              </div>
              <h4 className="text-lg font-bold text-color-main">
                <WalletAmount {...{ type, amount, sym }} />
              </h4>
              <div className="-mt-1 text-lg font-bold text-light">
                <WalletAmountUsdRate {...{ type, amount: amountUSD }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export const WalletAmount = ({ type, amount, sym, resize }) => {
  const fiatW = [
    "naira",
    "nairaBonus",
    "cedis",
    "cedisBonus",
    "cefa",
    "cefaBonus",
  ];

  return (
    <>
      {fiatW.includes(type) ? (
        <>
          {/* <span className="text-sm">{sym}</span> */}
          {formatInputNum(amount || 0, 2)} {sym}
        </>
      ) : (
        <>
          {formatCrypto(amount)}{" "}
          {resize ? <span className="text-base">{sym}</span> : sym}
        </>
      )}
    </>
  );
};

export const WalletAmountUsdRate = ({ type, amount }) => {
  return <>${Number.isFinite(amount) ? formatInputNum(amount, 2) : "---"}</>;
};
