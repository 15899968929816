import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { Store } from "react-notifications-component";
import { capitalize, replace, startCase, toLower } from "lodash";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";

dayjs.extend(advancedFormat);

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const truncate = (str, len) =>
  str.length <= len ? `${str.slice(0, len)}` : `${str.slice(0, len)}...`;

export const formatDate = (date, format = "Do MMM, YYYY hh:mm a") => {
  let d = new Date(date);

  if (date instanceof Object) {
    d = date.seconds * 1000;
  }
  return dayjs(d).format(format);
};

export const formatDateToMill = (date) => {
  return dayjs(date).valueOf();
};

export const formatDateToValue = (date, type) => {
  const d =
    type === "start" ? dayjs(date).startOf("day") : dayjs(date).endOf("day");
  return d.valueOf();
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

export const getFirstName = (name) => name?.split(" ")[0];

export const formatMoney = (
  num,
  sym = "visible",
  decimalScale = 2,
  prefix = "₦"
) => (
  <NumberFormat
    value={num}
    displayType="text"
    thousandSeparator
    {...(sym === "visible" && { prefix })}
    decimalScale={decimalScale}
    fixedDecimalScale
  >
    {num}
  </NumberFormat>
);

export const formatCrypto = (amt) =>
  amt !== "" ? `${parseFloat(amt).toFixed(8)}` : "";

export const formatMoneyNoDec = (num, prefix = "₦") => (
  <NumberFormat
    value={num}
    displayType="text"
    thousandSeparator
    prefix={prefix}
    decimalScale={0}
    // fixedDecimalScale
  >
    {num}
  </NumberFormat>
);

export const formatInputNum = (amt, fixed = 0) => {
  return amt !== ""
    ? parseFloat(amt)
        .toFixed(fixed)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    : // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      "";
};

export const symToCur = (cur) => getSymbolFromCurrency(cur);

export const notification = (type, message, title) => {
  return Store.addNotification({
    title: capitalize(title || type),
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const createRefID = (userId, tag = "ESET") => {
  const refD = replace(new Date().toISOString(), /[^a-zA-Z0-9 ]/g, "");

  return `${tag}_${refD}_${userId}`;
};

export const copyText = async (text, msg) => {
  const copied = copy(text);
  if (copied) {
    notification("success", msg);
  } else {
    notification("danger", "Failed to copy!", "error");
  }
};

export const toFixed = (num, fixed = 0) => {
  try {
    const s =
      Number.isNaN(num) || num === "NaN" || num === 0
        ? ""
        : `${parseFloat(num)?.toFixed(fixed)}`;
    return s;
  } catch (error) {
    return "";
  }
};

export const useBreakpoint = () => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  return { lg, md, sm, xs };
};

export const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => {
    elRef?.current?.scrollTo({ behavior: "smooth", top: 0 });
  };

  return [executeScroll, elRef];
};

export const getDeviceType = () => "other_device_app";

export const trimName = (name) =>
  startCase(
    toLower(
      name
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/\s\s+/g, " ")
        .trim()
    )
  );
