import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";

import { SimpleIconButton, BackdropSection, SimpleIcon } from "components";

import { classNames } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsContentMobile({
  pageContent,
  selectedIndex,
  mobilePopup,
  setMobilePopup,
}) {
  const { Comp, label, IconAlt } = pageContent;
  const [pageDelay, setPageDelay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [selectedIndex]);

  const open = Boolean(pageContent && mobilePopup);

  const handleClose = () => {
    setMobilePopup(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar className="flex justify-between">
          <div className="flex items-center">
            <div
              className={classNames(
                "flex h-8 w-8 items-center justify-center rounded-full bg-slate-50"
              )}
            >
              <SimpleIcon
                fontSize="small"
                icon={IconAlt}
                frontColor={"text-primary"}
              />
            </div>
            <h5 className="ml-2 text-lg font-bold">{label}</h5>
          </div>

          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
            <SimpleIconButton
              icon="Close"
              frontColor="text-primary"
              onClick={handleClose}
              fontSize="small"
              className="!p-1"
            />
          </div>
        </Toolbar>
      </AppBar>

      <div
        className={classNames(
          "h-full overflow-auto rounded-2xl bg-dark/5 p-4 text-text-color"
        )}
      >
        <Comp />
      </div>
      <BackdropSection open={!pageDelay} />
    </Dialog>
  );
}
