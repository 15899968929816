/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Transition, Dialog } from "@headlessui/react";

import { classNames } from "utils";
import { SimpleIcon } from "components";
import { Slide } from "react-reveal";

export function useDropDownVisible(initialIsVisible, dropdownId) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    try {
      if (
        event &&
        event.target.className?.includes(`dropdown-button-${dropdownId}`)
      ) {
        setIsDropdownVisible(!isDropdownVisible);
      } else if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDropdownVisible]);

  return { ref, isDropdownVisible, setIsDropdownVisible };
}

const navigation = [
  { name: "Dashboard", linkTo: "/dashboard", Icon: "DashboardCustomize" },
  {
    name: "Fiat Wallets",
    linkTo: "/wallets-fiat",
    Icon: "AccountBalanceWallet",
  },

  {
    name: "Crypto Wallets",
    linkTo: "/wallets-crypto",
    Icon: "AccountBalanceWalletTwoTone",
  },
  // {
  //   name: "Wallets",
  //   Icon: "AccountBalanceWallet",
  //   subLink: [
  //     { name: "Fiat Wallets", linkTo: "/wallets-fiat" },
  //     { name: "Crypto Wallets", linkTo: "/wallets-crypto" },
  //   ],
  // },
  { name: "Transactions", linkTo: "/transactions", Icon: "TableChart" },
  { name: "Giftcard", href: true, Icon: "CardGiftcardOutlined" },
  { name: "Utilities", linkTo: "/utilities", Icon: "BubbleChart" },
  { name: "Download App", linkTo: "/download-app", Icon: "Download" },
  { name: "Settings", linkTo: "/settings", Icon: "SettingsOutlined" },
  { name: "Logout", logout: true, Icon: "Logout" },
];

export default function NavbarMobileDrawer({ open, setOpen, logout }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { ref, isDropdownVisible, setIsDropdownVisible } = useDropDownVisible(
    false,
    "link"
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1200] overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {/* w-screen  */}
              <div className="pointer-events-auto w-screen max-w-[300px]">
                <div className="flex h-full flex-col overflow-y-scroll rounded-r-xl bg-white shadow-drawer">
                  <div className="flex items-center justify-between border-b-[1px] border-slate-50 p-4">
                    <Dialog.Title className="flex flex-row items-center justify-center text-xl font-bold text-primary">
                      <div className="">
                        <img
                          src={require("../assets/images/e-logo.svg").default}
                          className="h-4 w-4"
                        />
                      </div>
                      <div className="ml-1">esetech</div>
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="-m-2 h-8 w-8  rounded-full border-0 text-gray-400 outline-0 hover:text-gray-500"
                        onClick={() => setOpen(false)}
                      >
                        <SimpleIcon icon="CloseRounded" />
                      </button>
                    </div>
                  </div>
                  <div className="flex-1 overflow-y-auto px-4">
                    <div className="md:hidden">
                      <div className="space-y-1 divide-y divide-slate-50 px-2 py-3 sm:px-3">
                        {navigation.map((item, index) => {
                          const currentItem = pathname.includes(item.linkTo);
                          return (
                            <Fragment key={index}>
                              <button
                                key={item.name}
                                onClick={() => {
                                  if (item.logout) {
                                    logout();
                                  } else if (item.href) {
                                    return window.open(
                                      "https://dashboard.kaapo.ng",
                                      "_blank"
                                    );
                                  } else if (item.linkTo) {
                                    navigate(item.linkTo);
                                  }
                                }}
                                className={classNames(
                                  item.current
                                    ? "text-primary-600"
                                    : "hover:text-primary-600 text-gray-700",
                                  item.subLink ? "dropdown-button-link" : "",
                                  "relative flex w-full items-center justify-between p-3 text-base font-normal",
                                  currentItem
                                    ? "rounded-lg bg-primary text-white"
                                    : ""
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                <div
                                  className={classNames(
                                    "relative flex w-full",
                                    currentItem ? "font-bold text-white" : ""
                                  )}
                                >
                                  <SimpleIcon
                                    icon={item.Icon}
                                    fontSize="small"
                                    frontColor={currentItem ? "text-white" : ""}
                                  />
                                  <div className="ml-3">{item.name}</div>
                                  {item.href && (
                                    <div className="absolute top-[1px] right-[75px] flex animate-bounce items-center justify-center rounded-lg border border-cyan-300 bg-cyan-100 px-1 font-normal text-cyan-400">
                                      <span className="text-xs">Trade</span>
                                    </div>
                                  )}
                                </div>

                                {item.subLink && (
                                  <SimpleIcon
                                    icon={"KeyboardArrowDown"}
                                    fontSize="small"
                                  />
                                )}
                              </button>
                              {isDropdownVisible && item.subLink && (
                                <div
                                  ref={ref}
                                  // absolute
                                  className={`dropdown-link top-14 left-0 z-[10] w-full`}
                                >
                                  <Slide up duration={300}>
                                    {item.subLink.map((subItem) => (
                                      <button
                                        key={subItem.name}
                                        onClick={() => {
                                          navigate(subItem.linkTo);
                                        }}
                                        className={classNames(
                                          subItem.current
                                            ? "text-primary-600"
                                            : "hover:text-primary-600 text-gray-500",
                                          "flex w-full items-center p-3 text-sm font-normal"
                                        )}
                                      >
                                        <SimpleIcon
                                          icon={"MoreHoriz"}
                                          fontSize="small"
                                        />
                                        <span className="ml-3">
                                          {subItem.name}
                                        </span>
                                      </button>
                                    ))}
                                  </Slide>
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
