import React, { useCallback } from "react";
import { Container, SimpleTable, SectionContent } from "components";
import Fade from "react-reveal/Fade";
import { useUserTransCompletedForWallet } from "api";
import { useSelector } from "react-redux";
import {
  classNames,
  formatCrypto,
  formatDate,
  formatMoney,
  formatMoneyNoDec,
} from "utils";
import { capitalize } from "lodash";
import { statProps } from "utils/constants";

export default function TableSection() {
  const { completedSingleTrans, isTransactionListLoading } = useSelector(
    (state) => state.transaction
  );

  useUserTransCompletedForWallet({
    col: "transCrypto",
  });

  const amountCompute = (productType, transaction) =>
    productType?.includes("tether") && transaction?.tradeType === "convert"
      ? formatCrypto(transaction?.usdtTotal || transaction?.usdt)
      : formatCrypto(transaction?.cryptoTotal || transaction?.crypto);

  const rows = useCallback(() => {
    if (isTransactionListLoading) {
      return null;
    } else {
      return completedSingleTrans?.map((item) => ({
        id: item.id,
        product:
          item.product === "crypto"
            ? item.product
            : `${capitalize(item.product)} (${item.productType})`,
        productType: item.productType,
        amount:
          item.product === "crypto" ? (
            `${amountCompute(item?.productType, item?.transaction)}${
              item?.transaction?.sym
            }`
          ) : (
            <>{formatMoney(item?.transaction?.total)}</>
          ),
        action: item?.transaction?.tradeType,
        status: (
          <div
            className={classNames(
              statProps[item.status].bg,
              statProps[item.status].text,
              "w-fit rounded p-2 text-center text-xs font-bold"
            )}
          >
            {item.status}
          </div>
        ),
        date: formatDate(item.dateCreated),
      }));
    }
  }, [isTransactionListLoading])();

  return (
    <Fade>
      <SectionContent otherClass="p-1">
        <div className="relative rounded-xl bg-white">
          <SimpleTable
            emptyListText="No Recent Transaction"
            columns={[
              { id: "date", label: "Date" },
              { id: "product", label: "Product" },
              { id: "productType", label: "Product Type" },
              { id: "action", label: "Action" },
              { id: "amount", label: "Amount" },
              { id: "status", label: "Status" },
            ]}
            rows={rows}
            coloredHeader
            isLoading={isTransactionListLoading}
          />
        </div>
      </SectionContent>
    </Fade>
  );
}
