/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { capitalize, filter } from "lodash";

import { SimpleIconButton } from "components";
import SellCrypto from "../sub-page/SellCrypto";
import BuyCrypto from "../sub-page/BuyCrypto";
import SendCrypto from "../sub-page/SendCrypto";
import DepositCrypto from "../sub-page/DepositCrypto";
import ConvertCrypto from "../sub-page/ConvertCrypto";
import TransferCrypto from "../sub-page/TransferCrypto";

import { useSelector } from "react-redux";
import {
  useCryptoWalletListProps,
  useGetSiteTradeRate,
  useWalletStatus,
} from "api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupContent() {
  const { walletProps, rate } = useCryptoWalletListProps();

  const walletPropsFiltered = walletProps.map((i) => ({
    ...i,
    type: i.id,
    id: "crypto",
  }));

  const [dropdownList, setDropdownList] = useState(walletPropsFiltered);
  const [displayPage, setDisplayPage] = useState(null);

  const [currentWalletProps, setCurrentWalletProps] = useState(dropdownList[0]);

  const { transactionStatus } = useSelector((state) => state.transaction);

  const { user_details } = useSelector((state) => state.profile);

  const { cryptoAuth } = useSelector((state) => state.utils);

  const {
    walletStatus: {
      currentWallet,
      currentWalletAction: currentAction,
      currentWalletActionSublinkProps,
    },
  } = useSelector((state) => state.utils);

  const [walletStatus] = useWalletStatus();

  const siteRate = useGetSiteTradeRate(
    currentWallet,
    user_details?.defaultCurrency,
    currentAction
  );

  useEffect(() => {
    const dropdownListFil = filter(walletPropsFiltered.slice(), (o) => {
      const sym = ["BTC", "ETH"];
      return sym.includes(o.sym);
    });

    if (currentAction === "convert" && currentWallet === "tether") {
      setDropdownList(dropdownListFil);
      setCurrentWalletProps(dropdownListFil[0]);
    } else {
      let r = walletPropsFiltered.find((i) => i.type === currentWallet);
      if (currentWalletActionSublinkProps) {
        const { sym, network, assetType, address } =
          currentWalletActionSublinkProps;
        r = { ...r, network, sym, assetType, address };
      }
      setCurrentWalletProps(r);

      setDropdownList(walletPropsFiltered);
    }
  }, [currentWallet, currentAction, rate]);

  useEffect(() => {
    setDisplayPage(cryptoAuth?.[currentWallet]?.[currentAction]);
  }, [currentWallet, currentAction]);

  const open = Boolean(currentAction);

  const handleClose = () => {
    if (!transactionStatus) {
      walletStatus({
        currentWalletAction: null,
        currentWalletActionSublinkProps: null,
      });
    }
  };

  const pages = {
    sell: SellCrypto,
    buy: BuyCrypto,
    send: SendCrypto,
    receive: DepositCrypto,
    convert: ConvertCrypto,
    transfer: TransferCrypto,
  };

  const Pages = pages[currentAction];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        paper: "!w-[400px] !rounded-lg !overflow-visible",
      }}
    >
      <div className="absolute -right-2 -top-2 z-[502] flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
        <SimpleIconButton
          icon="Close"
          frontColor="text-primary"
          onClick={handleClose}
          fontSize="small"
          className="!p-1"
        />
      </div>

      <div className="overflow-hidden rounded-2xl bg-card p-2 text-text-color shadow-md">
        <div className="bg-gray-50 px-4 py-4 sm:flex sm:px-4">
          <div className="flow-root">
            <h5 className="font-bold">{capitalize(currentAction)} Crypto</h5>
          </div>
        </div>

        <div className="mt-0 bg-[#f4f8f5] p-2">
          {currentAction && (
            <Pages
              siteRate={siteRate}
              displayPage={displayPage}
              currentWalletName={currentWallet}
              {...{ currentWalletProps, setCurrentWalletProps, dropdownList }}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}
