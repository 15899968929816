import React, { Fragment, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Slide } from "react-reveal";
import { startCase } from "lodash";
import { SimpleIcon, SimpleIconButton } from ".";
import { classNames, copyText } from "utils";

const SummaryDropDown = ({ item }) => {
  const [openD, setOpenD] = useState(false);
  const val = item[1]?.value;
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <p className="text-sm font-normal uppercase text-gray-400">
          {startCase(item[0])}
        </p>
        <SimpleIconButton
          icon={openD ? "ExpandLess" : "ExpandMore"}
          onClick={() => setOpenD(!openD)}
        />
      </div>
      {openD && val}
    </div>
  );
};

export function Summary({ tradeSummary, title = "Trade Summary", useValue }) {
  return (
    <div className="relative grid gap-6 bg-white ">
      {Object.entries(tradeSummary).map((item, index) => {
        const disableField = item[1]?.disableDisplay;

        return (
          <Fragment key={index}>
            {!disableField && (
              <div className="-m-3 flex items-start rounded border-b border-gray-300 p-3 hover:bg-gray-50">
                <SimpleIcon icon="CheckCircleOutline" fontSize="small" />

                <div className="ml-4">
                  {item[1]?.type === "dropdown" ? (
                    <SummaryDropDown item={item} />
                  ) : (
                    <>
                      <p className="text-sm font-medium text-gray-900">
                        {startCase(item[0])}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {useValue ? item[1]?.value : item[1]}
                        {item[1]?.clickable && (
                          <span className="ml-2">
                            <SimpleIconButton
                              fontSize={18}
                              icon="ContentCopy"
                              onClick={() => {
                                copyText(
                                  item[1]?.value,
                                  "Copied successfully."
                                );
                              }}
                            />
                          </span>
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export const TransactionSummary = ({ summary, disableIcon }) => {
  return (
    <>
      {summary && Object?.entries(summary)?.length ? (
        <>
          {Object.entries(summary).map((item) => {
            const disableField = item[1]?.disableDisplay;
            return (
              <Fragment key={item[0]}>
                {!disableField && item[1]?.value && (
                  <div className="-m-3 flex items-start border-b border-slate-100 p-3 hover:bg-gray-50">
                    <div
                      className={classNames(
                        disableIcon ? "" : "",
                        "flex w-full items-center justify-between"
                      )}
                    >
                      {item[1]?.type === "dropdown" ? (
                        <SummaryDropDown item={item} />
                      ) : (
                        <>
                          <div className="flex-1 text-sm font-normal uppercase text-gray-400">
                            {startCase(item[0])}
                          </div>
                          <div className="flex text-right text-sm text-gray-500">
                            <span className="ml-2 break-all">
                              {" "}
                              {item[1]?.value}
                            </span>

                            {item[1]?.clickable && (
                              <span className="ml-2">
                                <SimpleIconButton
                                  fontSize={18}
                                  icon="ContentCopy"
                                  classes={{ root: "!p-0" }}
                                  onClick={() => {
                                    copyText(
                                      item[1]?.value,
                                      "Copied successfully."
                                    );
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export const EditSummary = ({ children, title, value }) => {
  return (
    <div className="-m-3 flex items-start border-b border-gray-300 p-3 hover:bg-gray-50">
      <EditIcon sx={{ fontSize: 20 }} className="text-color-main" />
      <div className="ml-4 w-full">
        <p className="text-sm font-medium text-gray-600">{title}</p>
        {value?.type === "component" && (
          <div className="text-sm text-gray-500">{value?.value}</div>
        )}

        {value?.type === "text" && (
          <p className="mt-1 text-sm text-gray-500">{value?.value}</p>
        )}

        <div className="mt-4 text-sm text-gray-500">{children}</div>
      </div>
    </div>
  );
};
