import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { CheckIcon } from "@heroicons/react/outline";
import { IconBox } from "components";
import { formatInputNum } from "utils";

export const ProductCard = ({ product }) => {
  return (
    <Fade>
      <div className="relative top-0 col-span-1 shadow-card-box duration-500 ease-in hover:-top-2 hover:shadow-lg md:col-span-1 lg:col-span-1">
        <div className="card h-full flex-1 rounded-sm border border-slate-200 bg-white p-6">
          <Link to={`/utilities/${product.linkTo}`} className="flex">
            <div className="flex items-start justify-between">
              <IconBox
                Icon={product.IconToned}
                iconColor={product.iconColor}
                otherClass={`bg-gradient-to-l ${product.bgGradient}`}
              />
            </div>

            <div className="ml-4 text-sm">
              <div className="flex flex-col">
                <h6
                  className={`text-md font-bold uppercase tracking-wide ${product.textColor}`}
                >
                  {product.title}
                </h6>
              </div>
              <div className="mt-2 text-gray-500">{product.subtitle}</div>
            </div>
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export const UtilityCard = ({ product }) => {
  return (
    <Fade>
      <div className="relative top-0 rounded-sm  border border-slate-200  duration-500 ease-in hover:-top-2 hover:shadow-lg md:col-span-2 lg:col-span-1 ">
        {/* <Container otherclass="bg-slate-50 rounded-lg"> */}
        <div className="h-full rounded-lg bg-slate-50">
          <Link
            to={`/utilities/${product.linkTo}`}
            key={product.title}
            className="block p-6"
          >
            <div className="flex items-start justify-start">
              <div className="mr-4 flex items-start justify-between">
                <IconBox Icon={product.Icon} iconColor={product.iconColor} />
              </div>

              <div className="text-sm">
                <div className="flex flex-col">
                  <h6
                    className={`text-base font-bold  tracking-wide ${product.textColor}`}
                  >
                    {product.title}
                  </h6>
                </div>
                <div className="mt-2 text-gray-500">{product.subtitle_alt}</div>
              </div>
            </div>
          </Link>
        </div>
        {/* </Container> */}
      </div>
    </Fade>
  );
};

export const GiftCard = ({ card, handleClick, type = "small" }) => {
  const Block = ({ children, ...otherProps }) => {
    return (
      <>
        {handleClick ? (
          <button onClick={handleClick} {...otherProps}>
            {children}
          </button>
        ) : (
          <div {...otherProps}>{children}</div>
        )}
      </>
    );
  };

  const typeL = {
    small: "h-16",
    large: "h-28",
  };
  // bg-card p-2
  // slate-50
  return (
    <div className="bg-background_main relative top-0 rounded-lg p-2 shadow-md duration-300 ease-in hover:-top-2">
      <div className="rounded-lg bg-white p-2">
        <Block className="w-full">
          <div className={typeL[type]}>
            <img
              src={card.imageUrl}
              className="h-full w-full rounded-lg object-cover"
            />
          </div>
          <div className="mt-2">
            <p className="text-center text-sm font-bold text-text-color">
              {card.title}
            </p>
          </div>
        </Block>
      </div>
    </div>
  );
};

export const CurrentData = ({ currData, listData }) => {
  return (
    <>
      {currData === listData && (
        <div className="pointer-events-none absolute top-0 left-0 h-full w-full">
          <div className="h-full w-full rounded-2xl border-2 border-primary bg-transparent" />
          <div className="absolute -top-1 -right-1">
            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-br from-bg_gradient_from to-bg_gradient_to text-white shadow">
              <CheckIcon className="h-4 w-4" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const SelectChip = ({
  title,
  list,
  handleClick,
  currData,
  disabled,
}) => {
  return (
    <Autocomplete
      multiple
      id="tags-readOnly"
      // options={list.map((option) => option)}
      options={list.map((option) => option)}
      value={list.map((option) => option)}
      readOnly
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <div key={index} className="relative">
            <Chip
              variant="contained"
              label={`₦${formatInputNum(option)}`}
              {...getTagProps({ index })}
              classes={{ root: "!text-gray-500 " }}
              onClick={() => handleClick(option)}
            />
            <CurrentData currData={currData} listData={option} />
          </div>
        ))
      }
      classes={{
        input: "!hidden",
        endAdornment: "!hidden",
        inputRoot: "!pr-0 !rounded-lg",
      }}
    />
  );
};
