import { useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import { difference } from "lodash";
import localStorage from "local-storage";

import {
  getNofications,
  getUnReadNofications,
} from "redux/reducers/notificationReducers";

import * as i from "../lib";

const auth = i.auth;

export const useGetNotification = () => {
  const dispatch = useDispatch();

  const { createdAt } =
    useStore().getState()?.auth?.currentUser?.current_user_details?.metadata ||
    {};

  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        const queries = [
          i.collection(i.db, "userNotifications"),

          i.where("notifyCustomerIds", "array-contains-any", [userUid, "all"]),
          i.where("dateCreated", ">=", parseInt(createdAt)),
          i.orderBy("dateCreated", "desc"),
          i.limit(10),
        ];

        const q = i.query(...queries);
        unsub = i.onSnapshot(q, async (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getNofications(t));
        });
      } catch (error) {
        dispatch(getNofications(null));
      }
    } else {
      dispatch(getNofications(null));
    }
    return unsub;
  }, [auth?.currentUser, dispatch]);
};

export const useUnreadNotification = () => {
  const { notifications } = useStore().getState().notification;
  const notifyIds = notifications?.map((i) => i.id);

  const dispatch = useDispatch();

  try {
    const getUnread = localStorage.get("ES_seenNotif[unWdYy]");
    const getUnreadParse = getUnread ? JSON.parse(getUnread) : [];

    const unread = difference(notifyIds, getUnreadParse);

    dispatch(getUnReadNofications(unread?.length));
  } catch (error) {}
};

export const useSetNotification = () => {
  const { notifications } = useStore().getState().notification;

  const setNotify = () => {
    try {
      const notifyIds = notifications.map((i) => i.id);

      localStorage.set("ES_seenNotif[unWdYy]", JSON.stringify(notifyIds));
    } catch (error) {}
  };
  return [setNotify];
};
