import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import Slide from "react-reveal/Slide";
import {
  MenuRounded as MenuIcon,
  CloseRounded as XIcon,
} from "@mui/icons-material";

import { classNames } from "utils";
import { SimpleIcon } from "components";
import MenuList from "./MenuList";

const navigation = [
  { name: "Dashboard", linkTo: "/dashboard", Icon: "DashboardCustomize" },
  {
    name: "Wallets",
    Icon: "AccountBalanceWallet",
    subLink: [
      { name: "Fiat Wallets", linkTo: "/wallets-fiat" },
      { name: "Crypto Wallets", linkTo: "/wallets-crypto" },
    ],
  },
  { name: "Transactions", linkTo: "/transactions", Icon: "TableChart" },
  { name: "Giftcard", href: true, Icon: "CardGiftcardOutlined" },
  { name: "Utilities", linkTo: "/utilities", Icon: "BubbleChart" },
  { name: "Download App", linkTo: "/download-app", Icon: "Download" },
  { name: "Settings", linkTo: "/settings", Icon: "SettingsOutlined" },
  { name: "Logout", logout: true, Icon: "Logout" },
];

export default function NavbarBottom({ logout, avatarUrl }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset > 24);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const Block = ({ children }) => {
    return <>{offset ? <Slide top>{children}</Slide> : <>{children}</>}</>;
  };

  return (
    <>
      <Block>
        <nav
          className={classNames(
            // offset ? "fixed top-16 left-0 right-0" : "relative animate-none",
            "z-5 fixed top-16 hidden h-16 w-full bg-white shadow md:block"
          )}
        >
          <div className="mx-auto h-full max-w-7xl px-4 sm:px-6 lg:px-8">
            {/* large screen */}
            <div className="hidden md:block">
              <div className="flex h-16 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white p-2 text-gray-400 md:hidden"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Flyout menus */}
                {/* hidden  */}
                <Popover.Group className="m-auto h-full py-1">
                  <div className="relative flex h-full space-x-1 ">
                    {navigation.map((page) => {
                      return (
                        <Fragment key={page.name}>
                          {/* page.name === "Wallets" */}
                          {page.subLink ? (
                            <MenuList
                              pathname={pathname}
                              icon={page.Icon}
                              subLink={page?.subLink}
                            />
                          ) : (
                            <button
                              key={page.name}
                              to={page.linkTo}
                              className={classNames(
                                pathname.includes(page.linkTo)
                                  ? "rounded-lg bg-primary !text-white"
                                  : "",
                                // nav-link
                                "relative flex items-center rounded-lg px-4 py-1 text-sm font-bold text-color-main duration-500 hover:bg-primary hover:text-slate-50"
                              )}
                              onClick={() => {
                                if (page.logout) {
                                  logout();
                                } else if (page.href) {
                                  return window.open(
                                    "https://dashboard.kaapo.ng",
                                    "_blank"
                                  );
                                } else {
                                  navigate(page.linkTo);
                                }
                              }}
                            >
                              <div className="flex items-center justify-center">
                                <SimpleIcon
                                  icon={page.Icon}
                                  // frontColor="text-color-main"
                                  otherClass="!text-[18px] !text-inherit"
                                />

                                <span className="ml-1 text-sm font-bold leading-[5px]">
                                  {page.name}
                                </span>
                              </div>
                              {/* {page.href && (
                                <div className="absolute top-[4px] -right-[2px] flex animate-bounce items-center justify-center rounded-full border border-cyan-300 bg-cyan-100 px-2 py-1 font-normal text-cyan-500">
                                  <span className="text-xs">Trade</span>
                                </div>
                              )} */}
                            </button>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </Popover.Group>
              </div>
            </div>
          </div>
        </nav>
      </Block>
    </>
  );
}
