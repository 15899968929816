import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import {
  BackdropSection,
  EnterPIN,
  SectionContent,
  WalletBalance,
} from "components";
import {
  useConvertFund,
  useGetFiatCharges,
  useGetSiteFiatRate,
  useGetUSDFiatRate,
} from "api";
import { classNames, toFixed } from "utils";
import {
  CustomInput,
  CustomInputWithDropdown,
} from "../components/CustomInput";
import NoDisplay from "../components/NoDisplay";
import { walletFiatProps } from "utils/constants";

export default function ConvertFiat({ currentWalletName, displayPage }) {
  const fiatRate = useGetSiteFiatRate() || {};
  const { cedisRate, cefaRate, nairaRate } = fiatRate;

  // useState Hooks
  const [amount, setAmount] = useState({
    convertFrom: "",
    convertTo: "",
  });

  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const [dropdownList, setDropdownList] = useState(null);

  // const { convertFrom, convertTo } = amount;

  // useSelector Hooks

  const { nairaWallet, cedisWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const {
    currentUser: { current_user: userId },
  } = useSelector((state) => state.auth || {});

  //Variables

  const { NGN = 0, GHS = 0, XOF = 0 } = useGetUSDFiatRate() || {};

  const [isSubmitting, isSubmitted, convertFund] = useConvertFund();
  const chargesObj = useGetFiatCharges(currentWalletName);
  const {
    minAmount: minAmt,
    maxAmount: maxAmt,
    maintainMinimum: maintainAmt,
  } = chargesObj || {};

  // Hooks

  const currentWalletObj = useCallback(() => {
    let amount,
      sym,
      symUnit,
      type,
      rate,
      maxAmount,
      minAmount,
      maintainMax,
      convertRate;
    switch (currentWalletName) {
      case "naira":
        amount = nairaWallet?.amount || 0;
        symUnit = "₦";
        sym = "NGN";
        type = "naira";
        rate = NGN;
        minAmount = minAmt;
        maxAmount = maxAmt;
        maintainMax = maintainAmt;
        convertRate = nairaRate;
        break;
      case "cedis":
        amount = cedisWallet?.amount || 0;
        symUnit = "₵";
        sym = "GHS";
        type = "cedis";
        rate = GHS;
        minAmount = Math.ceil(minAmt * cedisRate);
        maxAmount = Math.ceil(maxAmt * cedisRate);
        maintainMax = Math.ceil(maintainAmt * cedisRate);
        convertRate = cedisRate;
        break;
      case "cefa":
        amount = cefaWallet?.amount || 0;
        symUnit = "F";
        sym = "XOF";
        type = "cefa";
        rate = XOF;
        minAmount = Math.ceil(minAmt * cefaRate);
        maxAmount = Math.ceil(maxAmt * cefaRate);
        maintainMax = Math.ceil(maintainAmt * cefaRate);
        convertRate = cefaRate;
        break;
      default:
        break;
    }
    return {
      amount,
      sym,
      symUnit,
      type,
      rate,
      maxAmount,
      minAmount,
      maintainMax,
      convertRate,
    };
  }, [
    nairaWallet,
    cedisWallet,
    cefaWallet,
    currentWalletName,
    NGN,
    GHS,
    XOF,
    fiatRate,
    chargesObj,
  ])();

  useEffect(() => {
    const dd = walletFiatProps
      .filter((i) => i.id !== currentWalletName)
      .map((i) => ({
        type: i?.id,
        name: i?.id,
        id: "convertTo",
        sym: i?.sym,
        symUnit: i?.symUnit,
        convertRate: fiatRate?.[`${i?.id}Rate`],
      }));
    setCurrentDropdown(dd[0]);
    setDropdownList(dd);
  }, [fiatRate, walletFiatProps]);

  useEffect(() => {
    const {
      amount: walletAmount,
      minAmount,
      maxAmount,
      maintainMax,
      symUnit,
    } = currentWalletObj;

    const total = parseInt(amount?.["convertFrom"]);
    const limitLow = parseInt(amount?.["convertFrom"]) < minAmount;
    const limitHigh = parseInt(amount?.["convertFrom"]) > maxAmount;

    const overlimit = total >= parseInt(walletAmount || 0);
    const maintainMaximum = parseInt(walletAmount || 0) - total < maintainMax;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMaximum || limitLow || limitHigh);

    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Funds must be ${symUnit}${minAmount} and above`
        : limitHigh
        ? `Withdrawal must be ${symUnit}${maxAmount} and below`
        : maintainMaximum
        ? `Maintain a minimum of ${symUnit}${maintainMax} in wallet`
        : ""
    );
  }, [amount]);

  useEffect(() => {
    setAmount({
      convertFrom: "",
      convertTo: "",
    });
  }, [currentDropdown]);

  //Methods

  const { convertRate: convertRateFrom } = currentWalletObj || {};
  const { convertRate: convertRateTo } = currentDropdown || {};

  const handleAmt = (v, id) => {
    let convertFrom, convertTo;
    let e = v.replace(/[^0-9.]/g, "");

    if (e.length > 12) {
      e = e.slice(0, 12);
    }

    if (id === "convertFrom") {
      convertFrom = e;
      convertTo = toFixed(
        (parseFloat(e) / parseFloat(convertRateFrom)) * convertRateTo,
        2
      );
    } else if (id === "convertTo") {
      convertTo = e;
      convertFrom = toFixed(
        (parseFloat(e) / parseFloat(convertRateTo)) * convertRateFrom,
        2
      );
    }

    setAmount((prevS) => ({ ...prevS, convertFrom, convertTo }));
  };

  const processConvert = () => {
    const { convertFrom, convertTo } = amount;
    const { maintainMax, minAmount, maxAmount } = currentWalletObj;

    const values = {
      productType: `convert-${currentWalletName}`,
      productTypeTo: `convert-${currentDropdown?.name}`,
      amountToDebit: parseFloat(convertFrom),
      amountToCredit: parseFloat(convertTo),
      walletToCredit: `${currentDropdown?.name}Wallet`,
      walletToDebit: `${currentWalletName}Wallet`,
      amount: parseFloat(convertFrom),
      totalAmount: parseFloat(convertFrom),
      convertDetails: {
        sym: currentWalletObj?.sym,
        symTo: currentDropdown?.sym,
        rate: convertRateFrom,
        rateTo: convertRateTo,
        amount: {
          amount: parseFloat(convertFrom),
          totalAmount: parseFloat(convertFrom),
          maintainMax: parseFloat(maintainMax),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
      },
    };
    convertFund(values);
  };

  if (!displayPage) {
    return <NoDisplay type="converting" />;
  }

  return (
    <>
      {fiatRate && currentDropdown && dropdownList && chargesObj ? (
        <SectionContent>
          <div className="grid grid-cols-1 gap-4 p-2">
            <div className="col-span-1">
              <div className="mb-2 rounded bg-[#e7f7ea]/40 p-3">
                <div className="font-bold text-[#50875a]">
                  <>
                    Conversion Rate: 1 {currentWalletObj?.sym} ~{" "}
                    {(
                      (1 / parseFloat(convertRateFrom)) *
                      convertRateTo
                    )?.toFixed(4)}{" "}
                    {currentDropdown?.sym}
                  </>
                </div>
              </div>

              <WalletBalance
                type={currentWalletObj?.type}
                amount={currentWalletObj?.amount || 0}
                amountUSD={
                  (currentWalletObj?.amount || 0) / currentWalletObj?.rate
                }
                sym={currentWalletObj?.sym}
                symUnit={currentWalletObj?.symUnit}
              />
            </div>

            <div className="col-span-1">
              <div className="relative">
                <div className="grid grid-cols-1 gap-6">
                  {[
                    ...[currentWalletObj].map((i) => ({
                      name: i?.type,
                      id: i?.type,
                      value: amount?.["convertFrom"] || "",
                      sym: i?.symUnit,
                      symUnit: i?.sym,
                      label: `Convert (${i?.sym})`,
                    })),
                  ].map((item) => (
                    <div key={item.name} className={classNames("col-span-1")}>
                      <CustomInput
                        currentWallet={item}
                        label={item?.label}
                        value={item.value}
                        handleChange={(e, name) => {
                          handleAmt(e, "convertFrom");
                        }}
                        disabled={isSubmitting}
                      />

                      {errorAmt && (
                        <Slide down duration={300}>
                          <div className="mt-2 text-xs font-normal text-red-500">
                            {errorText}
                          </div>
                        </Slide>
                      )}
                    </div>
                  ))}

                  <div className="col-span-1">
                    <CustomInputWithDropdown
                      {...{
                        currentWallet: currentDropdown,
                        setCurrentWallet: (item) => setCurrentDropdown(item),
                      }}
                      dropdownList={dropdownList}
                      dropdownId="convert-fiat"
                      label={`Receive (${currentDropdown.sym})`}
                      value={amount?.["convertTo"] || ""}
                      handleChange={(e, name) => handleAmt(e, name)}
                      disabled={isSubmitting}
                    />
                  </div>

                  {parseInt(amount.convertFrom) &&
                  parseInt(amount.convertTo) &&
                  !errorAmt ? (
                    <div className="col-span-1">
                      <Fade up duration={500}>
                        <EnterPIN
                          processSubmit={processConvert}
                          btnText="Convert Fiat"
                          isSubmitting={isSubmitting}
                        />
                      </Fade>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </>
  );
}
