import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import {
  SimpleIconButton,
  PopupDrawer,
  TableData,
  TransactionSummary,
  BackdropSection,
  SimpleIcon,
} from "components";

import { useSelector } from "react-redux";
import { useUserTransCompletedForWallet } from "api";

import CalendarPicker from "./components/CalendarPicker";

import { classNames } from "utils";
import { concat } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransactionContentMobile({
  pageContent,
  selectedIndex,
  mobilePopup,
  setMobilePopup,
}) {
  const { Page, collection, pendingCollection, other_collection, id, name } =
    pageContent;
  const [pageDelay, setPageDelay] = useState(false);
  const [queryDates, setQueryDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [currentTrans, setCurrentTrans] = useState(null);
  const [open, setOpenDrawer] = useState(false);

  const {
    completedSingleTrans,
    otherCompletedSingleTrans,
    isOtherTransactionListLoading,
    isTransactionListLoading,
    pendingSingleTrans,
  } = useSelector((state) => state.transaction);
  const { currentUser } = useSelector((state) => state.auth);

  const { current_user } = currentUser;

  useUserTransCompletedForWallet({
    col: collection,
    pendingCol: pendingCollection,
    other_col: other_collection,
    productType: null,
    limit: 20,
    dates: queryDates,
  });

  const allTransactions = useCallback(() => {
    if (completedSingleTrans && otherCompletedSingleTrans) {
      return concat(completedSingleTrans, otherCompletedSingleTrans);
    } else {
      return null;
    }
  }, [completedSingleTrans, otherCompletedSingleTrans])();

  const pendingTransactions = useCallback(() => {
    if (pendingSingleTrans?.length) {
      return pendingSingleTrans;
    } else {
      return null;
    }
  }, [pendingSingleTrans])();

  const handleRowClick = (id) => {
    const concatAllTrans = concat(allTransactions, pendingTransactions);
    setCurrentTrans(concatAllTrans.find((i) => i.id === id));
    setOpenDrawer(true);
  };

  const columns = Page()?.columns || [];
  const rows = Page(current_user)?.rows(allTransactions) || [];
  const pendingRows = Page(current_user)?.rows(pendingTransactions) || [];
  const summary = Page(current_user)?.summary(currentTrans);

  const isLoadingTrans =
    isTransactionListLoading && isOtherTransactionListLoading;

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [selectedIndex]);

  const openPopup = Boolean(pageContent && mobilePopup);

  const handleClose = () => {
    setMobilePopup(false);
  };

  return (
    <Dialog
      fullScreen
      open={openPopup}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar className="flex justify-between">
          <div className="flex items-center">
            <div
              className={classNames(
                "flex h-8 w-8 items-center justify-center rounded-full bg-slate-50"
              )}
            >
              <SimpleIcon
                fontSize="small"
                icon={"TableChart"}
                frontColor={"text-primary"}
              />
            </div>
            <h5 className="ml-2 text-lg font-bold">{name}</h5>
          </div>

          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
            <SimpleIconButton
              icon="Close"
              frontColor="text-primary"
              onClick={handleClose}
              fontSize="small"
              className="!p-1"
            />
          </div>
        </Toolbar>
      </AppBar>

      <div
        className={classNames(
          "h-full overflow-auto rounded-2xl bg-dark/5 p-4 text-text-color"
        )}
      >
        {!isLoadingTrans && pageDelay ? (
          <>
            {pendingTransactions ? (
              <div className="mb-6">
                <TableData
                  columns={columns}
                  rows={pendingRows}
                  hideFooter={false}
                  onRowClick={handleRowClick}
                  // defaultMsg={
                  //   allTransactions ? null : "Filter to view transactions"
                  // }
                  coloredHeader
                />
              </div>
            ) : null}

            <div className="flex items-center justify-end">
              <CalendarPicker
                {...{
                  queryDates,
                  setQueryDates,
                  isTransactionListLoading,
                }}
              />
            </div>
            <TableData
              columns={columns}
              rows={rows}
              hideFooter={false}
              onRowClick={handleRowClick}
              defaultMsg={
                allTransactions ? null : "Filter to view transactions"
              }
              coloredHeader
            />
          </>
        ) : (
          <div className="relative h-[200px] rounded-lg bg-white">
            <BackdropSection open={true} />
          </div>
        )}
      </div>
      <PopupDrawer {...{ setOpenDrawer, open }}>
        <div className="rounded bg-slate-50 p-2">
          <div className="bg-gray-50 px-4 py-4 sm:flex sm:px-4">
            <div className="flow-root">
              <h5 className="font-bold">Transaction Details</h5>
            </div>
          </div>

          <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
            <TransactionSummary summary={summary} />
          </div>
        </div>
      </PopupDrawer>
    </Dialog>
  );
}
