import React from "react";
import { classNames } from "utils";
import { SimpleIcon } from "components";

export default function TransactionDrawer({
  tabList,
  selectedIndex,
  setSelectedIndex,
  setMobilePopup,
}) {
  const d = {
    0: "top-[0]",
    1: "top-[60px]",
    2: "top-[120px]",
    3: "top-[180px]",
    4: "top-[240px]",
    5: "top-[300px]",
    6: "top-[360px]",
    7: "top-[420px]",
  };

  return (
    <div className="relative mr-0 h-full min-w-[280px] rounded-xl rounded-r-none border-r border-slate-200 bg-white shadow-md md:mr-0">
      <div className="z-1 absolute left-0 h-full w-full">
        <div
          className={classNames(
            d[selectedIndex],
            "absolute left-0 flex h-[60px] w-full justify-end duration-500"
          )}
        >
          <div className="h-full w-1 rounded-sm bg-gradient-to-b from-dark/60 to-dark shadow-md shadow-dark/30" />
        </div>
      </div>

      {tabList.map((item, index) => {
        const cur = selectedIndex === index;
        const curTextCol = cur ? "text-dark" : "text-gray-600";
        return (
          <div
            key={index}
            className={classNames("nav-list h-[60px]", cur ? "bg-dark/5" : "")}
          >
            <div className="z-2 relative flex h-full w-full items-center px-3">
              <button
                className={classNames(
                  "relative left-0 flex w-full flex-row items-center justify-between rounded border-0 p-2 outline-none duration-500",
                  cur ? "left-2" : "left-0"
                )}
                onClick={() => {
                  setSelectedIndex(index);
                  setMobilePopup(true);
                }}
              >
                <div className="mr-2 flex flex-col items-start justify-between">
                  <div className={classNames(curTextCol, "font-semibold")}>
                    {item.name}
                  </div>
                  {/* <div className="flex flex-col text-left">
                    <span className={classNames("mt-1 text-sm", curTextCol)}>
                      {item.subtitle}
                    </span>
                  </div> */}
                </div>
                <div
                  className={classNames(
                    "flex h-6 w-6 items-center justify-center rounded-full shadow-md duration-500 hover:bg-slate-50/25",
                    cur ? "bg-primary" : ""
                  )}
                >
                  <SimpleIcon
                    fontSize={18}
                    icon={"NavigateNext"}
                    frontColor={cur ? "text-white" : "text-dark"}
                  />
                </div>
              </button>
            </div>
            <div className="z-0 border-b border-slate-200" />
          </div>
        );
      })}
    </div>
  );
}
