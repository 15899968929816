import { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import axios from "axios";
import { axiosCall, errorHandler } from "api/helpers";

import * as i from "../lib";
import { cryptoArray, walletCryptoProps, walletProps } from "utils/constants";
import {
  getWalletAddresses,
  isLoadingAddresses,
  isLoadingAddressesNew,
} from "redux/reducers/cryptoReducers";
import { useNavigate } from "react-router-dom";
import {
  getTransactionIsCompleted,
  getTransactionStatus,
} from "redux/reducers/transactionReducers";
import { createRefID, getDeviceType } from "utils";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/crypto_trans/${endpoint}`;

export const useGetNGNRate = (sym) => {
  const [rate, setRate] = useState();

  useEffect(() => {
    async function getR() {
      const ngn = await axios.get(
        `https://api.coinbase.com/v2/prices/${sym}-NGN/spot`
      );

      setRate(ngn.data.data.amount);
    }
    getR();
    const getPrice = setInterval(() => {
      getR();
    }, 5000);
    return () => clearInterval(getPrice);
  }, []);

  return rate;
};

export const useGetUSDFiatRate = () => {
  const [rate, setRate] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    const func = async () => {
      try {
        const q = i.doc(i.db, "utils", "realTimeMarketCryptoPrice");

        unsub = i.onSnapshot(q, (doc) => {
          const { fiat } = doc.data();

          setRate(fiat);
        });
      } catch (error) {
        setRate(null);
      }
    };
    func();
    return () => unsub();
  }, []);

  return rate;
};

export const useGetUSDCryptoRate = () => {
  const [rate, setRate] = useState(null);

  const orderCoinBy = {
    BTC: 1,
    ETH: 2,
    USDT: 3,
    USDT_TRON: 4,
    BNB: 5,
    LTC: 6,
  };

  useEffect(() => {
    let unsub = () => {};
    const func = async () => {
      try {
        const q = i.doc(i.db, "utils", "realTimeMarketCryptoPrice");

        unsub = i.onSnapshot(q, (doc) => {
          const { crypto } = doc.data();

          const dataOrderBy = Object.entries(crypto)
            .sort((a, b) => orderCoinBy[a[0]] - orderCoinBy[b[0]])
            .reduce((acc, item) => {
              acc[item[0]] = item[1];
              return acc;
            }, {});

          setRate(dataOrderBy);
        });
      } catch (error) {
        setRate(null);
      }
    };
    func();
    return () => unsub();
  }, []);

  return rate;
};

export const useGetUSDPriceChange = () => {
  const [change, setChange] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    const func = async () => {
      try {
        const q = i.doc(i.db, "utils", "realTimeMarketCryptoPrice");

        unsub = i.onSnapshot(q, (doc) => {
          const { priceChange } = doc.data();

          setChange(priceChange);
        });
      } catch (error) {
        setChange(null);
      }
    };
    func();
    return () => unsub();
  }, []);

  return change;
};

export const useGetSiteSellRate = () => {
  const [rate, setRate] = useState(null);

  useEffect(async () => {
    const data = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          BTC: {
            NGN: 590,
            GHS: 8.1,
            XOS: 600,
          },
          ETH: {
            NGN: 570,
            GHS: 8.05,
            XOS: 600,
          },
          USDT: {
            NGN: 570,
            GHS: 8.05,
            XOS: 600,
          },
          USDT_TRON: {
            NGN: 570,
            GHS: 8.05,
            XOS: 600,
          },
          BNB: {
            NGN: 570,
            GHS: 8.05,
            XOS: 600,
          },
        });
      }, 2000);
    });

    const d = await data;
    setRate(d);
  }, []);

  return rate;
};

export const useGetSiteBuyRate = () => {
  const [rate, setRate] = useState(null);

  useEffect(async () => {
    const data = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          BTC: {
            NGN: 750,
            GHS: 8.6,
            XOS: 600,
          },
          ETH: {
            NGN: 640,
            GHS: 8.6,
            XOS: 600,
          },
          USDT: {
            NGN: 610,
            GHS: 8.6,
            XOS: 600,
          },
          USDT_TRON: {
            NGN: 610,
            GHS: 8.6,
            XOS: 600,
          },
          BNB: {
            NGN: 610,
            GHS: 8.6,
            XOS: 600,
          },
        });
      }, 2000);
    });

    const d = await data;
    setRate(d);
  }, []);

  return rate;
};

export const useGetSiteSendCharge = () => {
  const [rate, setRate] = useState(null);

  useEffect(async () => {
    const data = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          BTC: 10,
          ETH: 10,
          USDT: 5,
          USDT_TRON: 5,
          BNB: 5,
        });
      }, 2000);
    });

    const d = await data;
    setRate(d);
  }, []);

  return rate;
};

export const useVerifyAddress = () => {
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);

  const verifyAddress = async (transData, callback) => {
    setLoading(true);
    const { crypto } = transData;
    try {
      const resp = await axiosCall(url("verify_wallet_address"), {
        ...transData,
        testnet: false,
      });
      const resIsValid = resp.data.data.isValid;
      setLoading(false);
      setValid(resIsValid);
      if (callback)
        callback(
          resIsValid ? `Valid ${crypto} address` : `Invalid ${crypto} address`
        );
    } catch (error) {
      setLoading(false);
      if (error === "Invalid params") {
        if (callback) callback("Invalid bitcoin address");
      } else {
        i.notification("danger", "An error occured", "error");
      }
    }
  };
  return [isLoading, isValid, setValid, verifyAddress];
};

export const useGetWalletAddress = (obj) => {
  const [getAddress, setGetAddress] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const dispatch = useDispatch();

  // const userUid = auth?.currentUser?.uid;

  const transData = obj;

  useEffect(() => {
    const getCryptoAddress = async () => {
      setLoading(true);
      try {
        const resp = await axiosCall(url("get_address"), transData, true);

        setLoading(false);
        setGetAddress(resp.data.data);
      } catch (error) {
        setLoading(false);
        setError(true);
        setGetAddress(null);

        i.notification("danger", "An error occured", "error");
      }
    };
    getCryptoAddress();
  }, [dispatch, auth?.currentUser]);

  return { isLoading, isError, getAddress };
};

export const useGenerateWalletAddress = () => {
  const [getAddress, setGetAddress] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const generateAddress = async ({ type, walletAddressType }) => {
    setSubmitting(true);
    try {
      dispatch(getTransactionStatus(true));
      const transData = {
        type,
        userUid: auth.currentUser.uid,
        walletAddressType,
        deviceType: getDeviceType(),
      };

      const resp = await axiosCall(url("generate_address"), transData, true);

      setGetAddress(resp.data.data);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      i.notification("success", `${type} Address created!`);
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      i.notification("danger", "An error occured.", "error");
    }
  };

  return [isSubmitting, isSubmitted, getAddress, generateAddress];
};

export const useSellCrypto = () => {
  const transRef = createRefID(auth.currentUser.uid, "CRY_SELL");

  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const sellCrypto = async ({
    tradeValues,
    sym,
    tradeType,
    walletN,
    cryptowallet,
    fiatWallet,
  }) => {
    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));
      setSubmitting(true);
      const transaction = {
        ...tradeValues,
        tradeType,
        sym,
        total: tradeValues.fiat,
      };

      const values = {
        transaction,
        amountToCredit: tradeValues.fiat,
        amountToDebit: tradeValues.crypto,
        walletToCredit: fiatWallet,
        walletToDebit: cryptowallet,
        comment: "",
        customerId: auth.currentUser.uid,
        agentId: null,
        imageUID: null,
        product: "crypto",
        productType: walletN,
        transactionUpload: null,
        confirmationUpload: null,
        transRef,
        deviceType: getDeviceType(),
        // deviceType: "ios_app",
      };

      // const resp = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(values);
      //   }, 3000);
      // });

      const resp = await axiosCall(
        url("sell_crypto"),
        values,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionIsCompleted(true));
      dispatch(getTransactionStatus(false));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, sellCrypto];
};

export const useBuyCrypto = () => {
  const transRef = createRefID(auth.currentUser.uid, "CRY_BUY");

  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const buyCrypto = async ({
    tradeValues,
    sym,
    tradeType,
    walletN,
    cryptowallet,
    fiatWallet,
  }) => {
    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);
      const transaction = {
        ...tradeValues,
        tradeType,
        sym,
        total: tradeValues.crypto,
      };

      const values = {
        transaction,
        amountToCredit: tradeValues.crypto,
        amountToDebit: tradeValues.fiat,
        walletToCredit: cryptowallet,
        walletToDebit: fiatWallet,
        comment: "",
        customerId: auth.currentUser.uid,
        agentId: null,
        imageUID: null,
        product: "crypto",
        productType: walletN,
        transactionUpload: null,
        confirmationUpload: null,
        transRef,
        deviceType: getDeviceType(),
      };

      // const resp = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     setSubmitting(true);
      //     resolve(values);
      //   }, 20000);
      // });

      const resp = await axiosCall(
        url("buy_crypto"),
        values,
        true,
        "useLimitedToken"
      );
      // console.log(resp);
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, buyCrypto];
};

export const useSendCrypto = () => {
  const transRef = createRefID(auth.currentUser.uid, "CRY_SEND");
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const sendCrypto = async ({
    tradeValues,
    sym,
    tradeType,
    walletN,
    cryptowallet,
  }) => {
    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const transaction = {
        ...tradeValues,
        tradeType,
        sym: sym,
      };

      const values = {
        transaction,
        amountToCredit: "none",
        walletToCredit: "none",
        amountToDebit: tradeValues.total,
        walletToDebit: cryptowallet,
        comment: "",
        customerId: auth.currentUser.uid,
        agentId: null,
        imageUID: null,
        product: "crypto",
        productType: walletN,
        transactionUpload: null,
        confirmationUpload: null,
        transRef,
        deviceType: getDeviceType(),
      };

      const resp = await axiosCall(
        url("send_crypto"),
        values,
        true,
        "useLimitedToken"
      );
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, sendCrypto];
};

export const useConvertCrypto = () => {
  const transRef = createRefID(auth.currentUser.uid, "CRY_CONVT");
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const convertCrypto = async ({
    tradeValues,
    sym,
    symTo,
    tradeType,
    walletN,
    walletNTo,
    cryptowallet,
    cryptowalletTo,
    amountToCredit,
    amountToDebit,
  }) => {
    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const transaction = {
        ...tradeValues,
        tradeType,
        sym: sym,
        symTo,
      };

      const values = {
        transaction,
        amountToCredit,
        walletToCredit: cryptowalletTo,
        amountToDebit,
        walletToDebit: cryptowallet,
        comment: "",
        customerId: auth.currentUser.uid,
        agentId: null,
        imageUID: null,
        product: "crypto",
        productType: walletN,
        productTypeTo: walletNTo,
        transactionUpload: null,
        confirmationUpload: null,
        transRef,
        deviceType: getDeviceType(),
      };

      const resp = await axiosCall(
        url("convert_crypto"),
        values,
        true,
        "useLimitedToken"
      );
      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, convertCrypto];
};

export const useTransferCrypto = () => {
  const transRef = createRefID(auth.currentUser.uid, "CRY_TRANSFER");

  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const transferCrypto = async ({
    tradeValues,
    sym,
    symTo,
    tradeType,
    walletN,
    walletNTo,
    cryptowallet,
    fiatWallet,
  }) => {
    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const transaction = {
        ...tradeValues,
        tradeType,
        sym,
        symTo,
      };

      const values = {
        transaction,
        amountToCredit: tradeValues.usdt,
        amountToDebit: tradeValues.crypto,
        walletToCredit: fiatWallet,
        walletToDebit: cryptowallet,
        comment: "",
        customerId: auth.currentUser.uid,
        agentId: null,
        imageUID: null,
        product: "crypto",
        productType: walletN,
        productTypeTo: walletNTo,
        transactionUpload: null,
        confirmationUpload: null,
        transRef,
        deviceType: getDeviceType(),
      };

      const resp = await axiosCall(
        url("transfer_crypto"),
        values,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, transferCrypto];
};
