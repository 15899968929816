import { useCallback, useEffect, useState } from "react";
import { axiosCall } from "api/helpers";
import * as i from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { useGetUSDCryptoRate, useGetUSDFiatRate } from "./crypto";
import { walletCryptoProps, walletFiatBonusProps } from "utils/constants";
import axios from "axios";
import { getTransactionIsCompleted } from "redux/reducers/transactionReducers";
import {
  getWalletStatus,
  getCryptoAuth,
  getFiatAuth,
  getUtilityAuth,
  getAdvertPopup,
} from "redux/reducers/utilReducers";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}${endpoint}`;

export const useVerifyTransferEmail = () => {
  const [isLoading, setLoading] = useState(false);
  const [getDetails, setGetDetails] = useState({ details: null, error: false });

  const verifyDetails = async ({ transferUserEmail }) => {
    try {
      setLoading(true);
      setGetDetails({ details: null, error: false });
      const resp = await axiosCall(
        url("/fiat_trans/check_tranfer_user"),
        { transferUserEmail },
        true,
        "useLimitedToken"
      );
      const resIsValid = resp.data.data;
      setLoading(false);
      setGetDetails({ details: resIsValid.transferUser, error: false });
    } catch (error) {
      // console.log(error);
      setLoading(false);
      const msg =
        error === '"transferUserEmail" must be a valid email'
          ? "Invalid Email"
          : error ===
            "There is no user record corresponding to the provided identifier."
          ? "User does not exist"
          : error === "Cannot transfer funds to owner account"
          ? "Funds cannot be transfered to same account"
          : "Cannot fetch user. An error occured";
      setGetDetails({ details: msg, error: true });
      // console.log(error);
      // i.notification("danger", error, "error");
    }
  };
  return [isLoading, getDetails, setGetDetails, verifyDetails];
};

export const useFiatWalletListProps = () => {
  const { cedisWallet, nairaWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const { amount: nairaAmount, nairaBonus } = nairaWallet || {};
  const { amount: cedisAmount, cedisBonus } = cedisWallet || {};
  const { amount: cefaAmount, cefaBonus } = cefaWallet || {};

  const rate = useGetUSDFiatRate();

  return useCallback(() => {
    const wallet = walletFiatBonusProps.map((item) => {
      const r = rate?.[item?.sym] || 0;
      let amount;

      if (item.id === "naira") {
        amount = nairaAmount || 0;
      } else if (item.id === "cedis") {
        amount = cedisAmount || 0;
      } else if (item.id === "cefa") {
        amount = cefaAmount || 0;
      } else if (item.id === "nairaBonus") {
        amount = nairaBonus || 0;
      } else if (item.id === "cedisBonus") {
        amount = cedisBonus || 0;
      } else if (item.id === "cefaBonus") {
        amount = cefaBonus || 0;
      }

      const amountType = amount;
      const amountUSD = amount / r;

      return { ...item, amount: amountType, amountUSD };
    });

    const walletProps = wallet;
    let isLoadedWalletProps = false;
    if (rate) {
      isLoadedWalletProps = true;
    } else {
      isLoadedWalletProps = false;
    }
    return { walletProps, isLoadedWalletProps };
  }, [rate, cedisWallet, nairaWallet, cefaWallet])();
};

export const useCryptoWalletListProps = () => {
  const { cryptoWallet } = useSelector((state) => state.account || {});

  const rate = useGetUSDCryptoRate();

  const rr = useCallback(() => {
    const walletPropsCrypto = walletCryptoProps
      .map((item) => {
        const wallet = cryptoWallet?.[item?.wallet] || 0;
        const r = rate?.[item?.sym] || 0;

        const amountType = wallet;
        const amountUSDType = r * wallet;
        return { ...item, amount: amountType, amountUSD: amountUSDType };
      })
      .sort((a, b) => b.amountUSD - a.amountUSD);

    let isLoadedWalletProps = false;
    if (rate) {
      isLoadedWalletProps = true;
    } else {
      isLoadedWalletProps = false;
    }

    return { walletProps: walletPropsCrypto, isLoadedWalletProps, rate };
  }, [rate, cryptoWallet])();

  return rr;
};

export const useGetCryptoTransAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        unsub = i.onSnapshot(i.doc(i.db, "utils", "crypto"), (doc) => {
          dispatch(getCryptoAuth(doc.data()));
        });
      } catch (error) {
        dispatch(getCryptoAuth(null));
      }
    } else {
      dispatch(getCryptoAuth(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetFiatTransAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        unsub = i.onSnapshot(i.doc(i.db, "utils", "fiat"), (doc) => {
          dispatch(getFiatAuth(doc.data()));
        });
      } catch (error) {
        dispatch(getFiatAuth(null));
      }
    } else {
      dispatch(getFiatAuth(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetUtilityTransAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        unsub = i.onSnapshot(i.doc(i.db, "utils", "utility"), (doc) => {
          dispatch(getUtilityAuth(doc.data()));
        });
      } catch (error) {
        dispatch(getUtilityAuth(null));
      }
    } else {
      dispatch(getUtilityAuth(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetAdvertPopup = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    if (auth?.currentUser) {
      try {
        unsub = i.onSnapshot(i.doc(i.db, "utils", "siteAdvertPopup"), (doc) => {
          dispatch(getAdvertPopup(doc.data()));
        });
      } catch (error) {
        dispatch(getAdvertPopup(null));
      }
    } else {
      dispatch(getAdvertPopup(null));
    }
    return unsub;
  }, [dispatch, auth?.currentUser]);
};

export const useGetSiteTradeRate = (cryptoType, fiatCur, actionType) => {
  const [rate, setRate] = useState(0);

  useEffect(async () => {
    let unsub = () => {};
    try {
      if (["sell", "buy"].includes(actionType)) {
        const q = i.doc(i.db, "cryptoRate1", cryptoType);

        unsub = i.onSnapshot(q, (doc) => {
          const r = doc.data()?.rate;
          const rr = r?.[fiatCur]?.[actionType];
          setRate(rr || 0);
        });
      }
    } catch (error) {
      setRate(0);
    }
    return () => unsub();
  }, [cryptoType, fiatCur, actionType]);

  return rate;
};

export const useGetSiteSendCharge = (cryptoType) => {
  const [rate, setRate] = useState(0);

  useEffect(async () => {
    let unsub = () => {};
    try {
      const q = i.doc(i.db, "cryptoRate1", cryptoType);

      unsub = i.onSnapshot(q, (doc) => {
        const r = doc.data()?.sendFee;
        setRate(r || 10);
      });
    } catch (error) {
      setRate(0);
    }
    return () => unsub();
  }, [cryptoType]);

  return rate;
};

export const useGetSiteConvertCharge = (cryptoType) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    let unsub = () => {};
    const convertCharge = async () => {
      try {
        const q = i.doc(i.db, "cryptoRate1", cryptoType);

        unsub = i.onSnapshot(q, (doc) => {
          const r = doc.data()?.convertRate;

          setRate(r || 10);
        });
      } catch (error) {
        setRate(0);
      }
    };
    convertCharge();
    return () => unsub();
  }, [cryptoType]);

  return rate;
};

export const useGetSiteFiatRate = () => {
  const [rate, setRate] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    const func = async () => {
      try {
        const q = i.doc(i.db, "utils", "fiatRate");

        unsub = i.onSnapshot(q, (doc) => {
          const { GHS, XOF, NGN } = doc.data();
          setRate({
            cedisRate: GHS.rate,
            cefaRate: XOF.rate,
            nairaRate: NGN.rate,
          });
        });
      } catch (error) {
        setRate(null);
      }
    };
    func();
    return () => unsub();
  }, []);

  return rate;
};

export const useGetFiatCharges = (type) => {
  const [rate, setRate] = useState(null);

  useEffect(() => {
    let unsub = () => {};
    const getResp = async () => {
      try {
        const q = i.doc(i.db, "utils", "charges");

        unsub = i.onSnapshot(q, (doc) => {
          const { maxAmount, minAmount, charges, maintainMinimum } = doc.data();
          setRate({
            maxAmount,
            minAmount,
            maintainMinimum,
            charges: charges?.[type],
          });
        });
      } catch (error) {
        setRate(null);
      }
    };
    getResp();
    return () => unsub();
  }, []);

  return rate;
};

export const useWalletStatus = () => {
  const dispatch = useDispatch();

  const setWalletStatus = (value) => {
    return dispatch(getWalletStatus(value));
  };

  return [setWalletStatus];
};

export const useDisableTransactionCompleted = (setIsSubmitted) => {
  const [walletStatus] = useWalletStatus();

  const { transactionIsCompleted } = useSelector((state) => state.transaction);

  const dispatch = useDispatch();

  useEffect(() => {
    let timerId = () => {};
    if (transactionIsCompleted) {
      dispatch(getTransactionIsCompleted(true));
      walletStatus({ currentWalletAction: null, mobilePopup: false });
      setIsSubmitted(true);

      timerId = setTimeout(() => {
        dispatch(getTransactionIsCompleted(false));
      }, 2000);
    } else {
      setIsSubmitted(false);
    }

    return () => clearTimeout(timerId);
  }, [transactionIsCompleted, dispatch]);
};

export const appCheck = async (useLimitedToken) => {
  if (useLimitedToken) {
    return await i.getLimitedUseToken(i.firebaseAppCheck, true);
  }
  return await i.getToken(i.firebaseAppCheck, true);
};
