import React, { Fragment } from "react";
import { classNames } from "utils";
import { SimpleIcon, WalletAmount, WalletAmountUsdRate } from "components";
import { CircularProgress } from "@mui/material";
import { useFiatWalletListProps, useWalletStatus } from "api";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { SubLinkDropdown } from "./CustomInput";
import { actionFiatList } from "utils/constants";

export default function LeftContent() {
  const {
    walletStatus: { currentWallet },
  } = useSelector((state) => state.utils);

  const [walletStatus] = useWalletStatus();

  const { walletProps, isLoadedWalletProps } = useFiatWalletListProps();

  const cWProp = walletProps.find((i) => i.id === currentWallet);

  const cWPropBonus = walletProps.find((i) => i.id === `${currentWallet}Bonus`);

  return (
    //
    <div className="col-span-4 xl:col-span-3">
      <div className="h-[420px] border-r border-slate-50">
        <div className="h-0 min-h-[30%]  border-b border-slate-50 p-4">
          <div className="flex h-full items-center rounded-lg bg-gray-100 p-4 shadow-md">
            {Boolean(isLoadedWalletProps) ? (
              <div className="flex flex-1 justify-between">
                <div className="text-base">
                  <div className="mb-1 text-sm text-gray-400">
                    Available Balance
                  </div>
                  {/* text-gray-500 */}
                  <div className="text-base font-bold tracking-tight">
                    <WalletAmount
                      type={currentWallet}
                      amount={cWProp.amount}
                      sym={cWProp.sym}
                    />
                  </div>
                  <div className="-mt-1 text-base font-bold text-green-400">
                    <WalletAmountUsdRate
                      type={currentWallet}
                      amount={cWProp.amountUSD}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <div className="mb-1 text-sm text-gray-400">Bonus</div>
                  <div className="text-base font-bold tracking-tight text-gray-400">
                    <WalletAmount
                      type={currentWallet}
                      amount={cWPropBonus.amount}
                      sym={cWProp.sym}
                    />
                  </div>
                  <div className="-mt-1 text-base font-bold text-gray-400">
                    <WalletAmountUsdRate
                      type={currentWallet}
                      amount={cWPropBonus.amountUSD}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex h-full w-full items-center justify-center">
                <CircularProgress color="primary" size={15} thickness={5} />
              </div>
            )}
          </div>
        </div>
        <div className="h-[68%]">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={285}
          >
            {/* p-4 */}
            <div className="px-4">
              {actionFiatList?.[currentWallet]?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <button
                      className={classNames(
                        item.subLinks
                          ? `dropdown-button-sublink-dropdown `
                          : "",

                        index === 0 ? "" : "border-t-0",
                        "relative left-0 h-[57px] w-full border-0 border-slate-50/50 px-2 outline-none transition-all duration-500 hover:left-2 hover:rounded-lg hover:bg-primary/5 hover:shadow-sm"
                      )}
                      key={item.id}
                    >
                      <div
                        className={classNames(
                          item.subLinks
                            ? `dropdown-button-sublink-dropdown pointer-events-none`
                            : "",
                          "flex w-full items-center justify-between"
                        )}
                        onClick={() => {
                          if (!item.subLinks) {
                            walletStatus({ currentWalletAction: item.id });
                          }
                        }}
                      >
                        <div className="flex items-center">
                          <div
                            className={classNames(
                              item.bg,
                              "h-9 w-9 rounded-full"
                            )}
                          >
                            <div className="flex h-full w-full items-center justify-center">
                              <SimpleIcon
                                frontColor={item.text}
                                // fontSize="small"
                                icon={item.icon}
                              />
                            </div>
                          </div>
                          <div className="ml-2 font-semibold text-gray-500">
                            {item.name}
                          </div>
                        </div>
                        <div className="h-8 w-8 rounded-lg bg-white shadow-md">
                          <div className="flex h-full w-full items-center justify-center">
                            <SimpleIcon
                              frontColor="text-gray-400"
                              fontSize="small"
                              icon="ChevronRight"
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                    {item.subLinks && (
                      <SubLinkDropdown
                        subLink={item.subLinks}
                        dropdownId="sublink-dropdown"
                        handleClick={(subLinkId) => {
                          walletStatus({
                            currentWalletAction: subLinkId,
                          });
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}
