import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as i from "../lib";

import {
  getPendingWithdrawal,
  getPendingCrypto,
  getCompletedSingleTrans,
  getOtherCompletedSingleTrans,
  getTransactionList,
  getOtherTransactionList,
  getUserTransCount,
  getTransCountLoaded,
  getPendingUtilities,
  getPendingSingleTrans,
} from "redux/reducers/transactionReducers";
import { axiosCall } from "api/helpers";
import { formatDateToValue } from "utils";

const auth = i.auth;

export const useTrans = (params, dispatcher, collection, statusType) => {
  const { startDate, endDate } = params || {};
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    try {
      if (userUid) {
        const completedQueries = [
          i.collection(i.db, collection),
          i.where("customerId", "==", userUid),
          i.where("status", "in", ["approved", "declined"]),
          ...(startDate ? [i.where("dateCreated", ">=", startDate)] : []),
          ...(endDate ? [i.where("dateCreated", "<=", endDate)] : []),
          i.orderBy("dateCreated", "desc"),
          // i.limit(startDate && endDate ? 5 : 10),
          i.limit(50),
        ];
        const pendingQueries = [
          i.collection(i.db, collection),
          i.where("customerId", "==", userUid),
          // i.where("status", "in", ["pending", "active"]),
          i.where("status", "in", ["pending", "active", "approved"]),
          i.orderBy("dateCreated", "desc"),
          // i.limit(10),
        ];
        const qCompleted = i.query(...completedQueries);
        const qPending = i.query(...pendingQueries);

        const q = statusType === "pending" ? qPending : qCompleted;

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(dispatcher(t));
        });
      }
    } catch (error) {}
    return unsub;
  }, [dispatch, userUid, startDate, endDate]);
};

export const useUserPendingWithdrawal = () => {
  useTrans({}, getPendingWithdrawal, "pendingTransWithdrawals", "pending");
};

export const useUserPendingUtilities = () => {
  useTrans({}, getPendingUtilities, "pendingTransUtilities", "pending");
};

export const useUserCryptoPending = () => {
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    let unsub;
    try {
      if (userUid) {
        const pendingCrypto = [
          i.collection(i.db, "bitPowrCryptoNotifications"),
          i.where("label", "==", userUid),
          i.orderBy("created_at", "desc"),
        ];

        const q = i.query(...pendingCrypto);

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];
          querySnapshot.forEach((doc) => {
            const {
              amount,
              assetType: currency,
              ref: transId,
              created_at: dateCreated,
            } = doc.data();
            t.push({
              id: doc.id,
              ...{ amount, currency, transId, dateCreated },
            });
          });

          dispatch(getPendingCrypto(t));
        });
      }
    } catch (error) {
      dispatch(getPendingCrypto([]));
    }
    return () => {
      unsub && unsub();
    };
  }, [dispatch, userUid]);
};

export const useUserTransCompletedForWallet = ({
  col,
  pendingCol,
  other_col,
  productType,
  limit = 4,
  dates,
}) => {
  const [queryDates, setQueryDates] = useState({
    startDate: null,
    endDate: null,
  });
  const dispatch = useDispatch();
  const userUid = auth?.currentUser?.uid;

  useEffect(() => {
    const { startDate, endDate } = dates || {};
    setQueryDates({
      startDate: startDate ? formatDateToValue(startDate, "start") : null,
      endDate: endDate ? formatDateToValue(endDate, "end") : null,
    });
  }, [dates]);

  useEffect(() => {
    let unsub = () => {};
    dispatch(getTransactionList(true));
    dispatch(getOtherTransactionList(true));
    try {
      if (userUid && col) {
        const completedQueries = [
          i.collection(i.db, col),
          ...(col === "transTransferFiat"
            ? [i.where("transCustomerIds", "array-contains", userUid)]
            : [i.where("customerId", "==", userUid)]),
          ...(productType ? [i.where("productType", "==", productType)] : []),
          ...(queryDates?.startDate
            ? [i.where("dateCreated", ">=", queryDates.startDate)]
            : []),
          ...(queryDates?.endDate
            ? [i.where("dateCreated", "<=", queryDates.endDate)]
            : []),
          i.orderBy("dateCreated", "desc"),
          i.limit(limit),
        ];

        const q = i.query(...completedQueries);

        unsub = i.onSnapshot(q, (querySnapshot) => {
          const t = [];

          querySnapshot.forEach((doc) => {
            t.push({ id: doc.id, ...doc.data() });
          });

          dispatch(getCompletedSingleTrans(t));
          dispatch(getTransactionList(false));
        });

        if (other_col) {
          const oldQueries = [
            i.collection(i.db, other_col),
            i.where("customerId", "==", userUid),
            ...(other_col === "transactions"
              ? [i.where("product", "==", "crypto")]
              : []),
            ...(queryDates?.startDate
              ? [i.where("dateCreated", ">=", queryDates.startDate)]
              : []),
            ...(queryDates?.endDate
              ? [i.where("dateCreated", "<=", queryDates.endDate)]
              : []),
            i.where("status", "in", ["approved", "declined"]),
            i.orderBy("dateCreated", "desc"),
            i.limit(limit),
          ];
          const q = i.query(...oldQueries);

          unsub = i.onSnapshot(q, (querySnapshot) => {
            const t = [];

            querySnapshot.forEach((doc) => {
              t.push({ id: doc.id, ...doc.data() });
            });

            dispatch(getOtherCompletedSingleTrans(t));
            dispatch(getOtherTransactionList(false));
          });
        } else {
          dispatch(getOtherCompletedSingleTrans([]));
          dispatch(getOtherTransactionList(false));
        }

        if (pendingCol) {
          const pendingQueries = [
            i.collection(i.db, pendingCol),
            i.where("customerId", "==", userUid),
            i.where("status", "in", ["pending", "active"]),
            i.orderBy("dateCreated", "desc"),
            // i.limit(10),
          ];
          const qPending = i.query(...pendingQueries);

          unsub = i.onSnapshot(qPending, (querySnapshot) => {
            const t = [];
            querySnapshot.forEach((doc) => {
              t.push({ id: doc.id, ...doc.data() });
            });

            dispatch(getPendingSingleTrans(t));
          });
        } else {
          dispatch(getPendingSingleTrans([]));
        }
      } else {
        dispatch(getTransactionList(false));
        dispatch(getOtherTransactionList(false));
      }
    } catch (error) {
      // console.log(error);
      dispatch(getTransactionList(false));
      dispatch(getOtherTransactionList(false));
    }
    return () => {
      unsub();
      dispatch(getCompletedSingleTrans(null));
      dispatch(getOtherCompletedSingleTrans(null));
    };
  }, [dispatch, userUid, productType, col, other_col, queryDates?.endDate]);
};

export const useUserTransAnalysisCount = () => {
  const dispatch = useDispatch();
  let count = {
    pending: 0,
    active: 0,
    approved: 0,
    declined: 0,
  };
  const userUid = auth?.currentUser?.uid;

  const counterFunc = async ({ collection, type }) => {
    const queries = [
      i.collection(i.db, collection),
      i.where("customerId", "==", userUid),
      i.where("status", "==", type),
    ];

    const q = i.query(...queries);
    const snapshot = await i.getCountFromServer(q);

    count[type] = count?.[type] + snapshot.data().count || 0;
  };

  useEffect(() => {
    dispatch(getTransCountLoaded(false));
    dispatch(getUserTransCount({}));

    const allCounterFunc = async () => {
      if (userUid) {
        await counterFunc({
          collection: "pendingTransWithdrawals",
          type: "pending",
        });
        await counterFunc({
          collection: "pendingTransWithdrawals",
          type: "active",
        });
        await counterFunc({ collection: "transCrypto", type: "approved" });
        await counterFunc({ collection: "transWithdrawals", type: "approved" });

        dispatch(getUserTransCount(count));
        dispatch(getTransCountLoaded(true));
      } else {
        dispatch(getUserTransCount({}));
      }
    };
    allCounterFunc();
  }, [dispatch, userUid]);
};

export const useGetCryptoPendingDetails = (params) => {
  const [details, setDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const url = (endpoint) => `${i.baseURL}/crypto_trans/${endpoint}`;

  const { type, transId } = params || {};

  useEffect(() => {
    const getReceiveTrans = async () => {
      if (type && transId) {
        try {
          setLoading(true);
          const transData = {
            type,
            transId,
          };

          const resp = await axiosCall(
            url("get_receive_transaction"),
            transData,
            true
          );

          setLoading(false);
          setDetails(resp.data.data?.[0]);
        } catch (error) {
          setLoading(false);
          setError(false);
          i.notification("danger", "An error occured.", "error");
        }
      }
    };
    getReceiveTrans();
  }, [auth?.currentUser, params]);

  return [isLoading, isError, details];
};
