import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Form } from "components";
import { changePasswordSchema, verifyPasswordSchema } from "utils/formSchema";
import { useVerifyPass, useChangePass } from "api";
import { changePassFormList, verifyPassFormList } from "utils/constants";

export default function ChangePass({ setDrawer }) {
  const {
    user_details: { email, displayName },
  } = useSelector((state) => state.profile);

  const [isUpdating, isUpdated, verifyPass] = useVerifyPass();
  const [isUpdatingPass, isUpdatedPass, changePass] = useChangePass();

  const verifyPassfield = verifyPassFormList;
  const changePassfield = changePassFormList(email);

  const verifyPassInitialVal = {
    currentPass: "",
  };

  const changePassInitialVal = {
    newPass: "",
    confirmNewPass: "",
    code: "",
  };

  useEffect(() => {
    if (isUpdatedPass) setDrawer(false);
  }, [isUpdatedPass]);

  const handleVerify = (values) => {
    verifyPass({ ...values, displayName });
  };

  const handleSubmit = (values, resetForm) => {
    changePass(values, resetForm);
  };

  return (
    <div className="p-4">
      <Form
        fieldList={verifyPassfield}
        initialFieldValues={verifyPassInitialVal}
        buttonText="Verify"
        buttonLoading={isUpdating}
        handleSubmit={handleVerify}
        validationSchema={verifyPasswordSchema}
        disableVisibilty={isUpdated}
        isDisabled={isUpdating}
        isDisabledFormFirstForm={isUpdated}
        disableAutoComplete
      />
      {isUpdated && (
        <>
          <div className="mt-6" />
          <Form
            fieldList={changePassfield}
            initialFieldValues={changePassInitialVal}
            buttonText="Change Password"
            buttonLoading={isUpdatingPass}
            handleSubmit={handleSubmit}
            validationSchema={changePasswordSchema}
            isDisabled={isUpdatingPass}
            disableAutoComplete
          />
        </>
      )}
    </div>
  );
}
