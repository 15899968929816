import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducers from "./reducers/authReducers";
import profileReducers from "./reducers/profileReducers";
import walletReducers from "./reducers/walletReducers";
import utilityReducers from "./reducers/utilityReducers";
import transactionReducers from "./reducers/transactionReducers";
import notificationReducers from "./reducers/notificationReducers";
import cryptoReducers from "./reducers/cryptoReducers";
import utilReducers from "./reducers/utilReducers";

export const store = configureStore({
  reducer: {
    auth: authReducers,
    profile: profileReducers,
    account: walletReducers,
    utility: utilityReducers,
    transaction: transactionReducers,
    notification: notificationReducers,
    crypto: cryptoReducers,
    utils: utilReducers,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
