import { capitalize, startCase, toUpper, upperCase } from "lodash";
import { useSelector } from "react-redux";
import {
  copyText,
  formatCrypto,
  formatDate,
  formatInputNum,
  formatMoney,
} from "utils";

const tradeStatus = {
  approved: {
    bg: "bg-green-100/50",
    text: "text-green-500",
    color: "bg-green-500",
  },
  declined: {
    bg: "bg-red-100/50",
    text: "text-red-500",
    color: "bg-red-500",
  },
  pending: {
    bg: "bg-yellow-100/50",
    text: "text-yellow-500",
    color: "bg-amber-500",
  },
  active: {
    bg: "bg-sky-100/50",
    text: "text-sky-500",
    color: "bg-cyan-500",
  },
};

const StatusObj = ({ type }) => {
  const { text, bg } = tradeStatus?.[type] || {};
  return (
    <div className={`flex items-center rounded p-2 ${text} ${bg}`}>
      <span className="text-xs font-bold">{type}</span>
    </div>
  );
};

const WalletBalance = ({ balance }) => {
  const cryptos = {
    tetherWallet: { sym: "USDT", type: "crypto", no: 7 },
    binanceWallet: { sym: "BNB", type: "crypto", no: 6 },
    bitcoinWallet: { sym: "BTC", type: "crypto", no: 4 },
    ethereumWallet: { sym: "ETH", type: "crypto", no: 5 },
    tetherTronWallet: { sym: "USDT_TRON", type: "crypto", no: 8 },
    nairaWallet: { sym: "NGN", type: "fiat", no: 1 },
    cedisWallet: { sym: "GHS", type: "fiat", no: 2 },
    cefaWallet: { sym: "XOF", type: "fiat", no: 3 },
  };

  return (
    <div className="p-2">
      {Object.entries(balance)
        ?.sort((b, c) => {
          return cryptos?.[c[0]].no < cryptos?.[b[0]].no ? 1 : -1;
        })
        ?.map((item, index) => (
          <div className="flex justify-between py-2" key={index}>
            <p className="text-sm text-gray-400">{startCase(item[0])}</p>
            <p className="text-sm text-gray-400">
              {cryptos?.[item[0]].type === "crypto"
                ? formatCrypto(item[1])
                : formatInputNum(item[1], 2)}{" "}
              {cryptos?.[item[0]].sym}
            </p>
          </div>
        ))}
    </div>
  );
};

const Amount = ({ amount, walletType }) => {
  const sym = {
    nairaWallet: "NGN",
    nairaBonusWallet: "NGN",
    cedisWallet: "GHS",
    cedisBonusWallet: "GHS",
    cefaWallet: "XOF",
    cefaBonusWallet: "XOF",
  };

  return (
    <span className="">
      {formatInputNum(amount, 2)} {sym?.[walletType]}
    </span>
  );
};

export function withdrawFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "product",
      headerName: "Product",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary Details",
      width: 200,
      renderCell: (params) => (
        <span>{params?.value?.accountName || params?.value}</span>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          product: item.product,
          productType: item.productType,
          amount: item.totalAmount || item.amountToDebit,
          beneficiary:
            item?.withdrawDetails?.accountDetails ||
            item?.withdrawDetails?.accountName,
          date: item.dateCreated,
          amountToDebit: item.amountToDebit,
          walletToDebit: item.walletToDebit,
        }))
    );
  };

  const summary = (item) => {
    const { withdrawDetails, dateCreated } = item || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.transId,
            type: "text",
            clickable: true,
            // disableDisplay: item?.transId,
          },
          // transactionRef: {
          //   value: item?.transRef,
          //   type: "text",
          //   clickable: true,
          //   disableDisplay: item?.reference_no,
          // },
          amountWithdrawn: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
            // disableDisplay: withdrawDetails?.amount?.amount,
          },
          charges: {
            value: withdrawDetails?.amount?.charges ? (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.charges,
                  walletType: item?.walletToDebit,
                }}
              />
            ) : null,
            // disableDisplay: withdrawDetails?.amount?.charges,
          },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount || item?.amountToDebit,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },

          beneficiaryName: {
            value:
              withdrawDetails?.accountDetails?.accountName ||
              withdrawDetails?.accountName,
            type: "text",
          },
          beneficiaryBank: {
            value:
              withdrawDetails?.accountDetails?.bankName ||
              withdrawDetails?.bankName,
          },
          beneficiaryAccountNo: {
            value:
              withdrawDetails?.accountDetails?.bankAccountNo ||
              withdrawDetails?.bankAccountNo,
          },
          beneficiaryMomoName: {
            value: withdrawDetails?.accountDetails?.momoName,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: withdrawDetails?.accountDetails?.momoNumber,
          },
          beneficiaryMomoType: {
            value: withdrawDetails?.accountDetails?.momoType,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary, pendingRows: rows, pendingSummary: summary };
}

export function cryptoTrades() {
  const symCompute = (productType, transaction) =>
    productType?.includes("tether") && transaction?.tradeType === "convert"
      ? transaction?.symTo
      : transaction?.sym;

  const amountCompute = (productType, transaction) =>
    productType?.includes("tether") && transaction?.tradeType === "convert"
      ? transaction?.usdtTotal || transaction?.usdt
      : transaction?.cryptoTotal || transaction?.crypto;

  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        const par = params.row;
        return (
          <span className="">
            {formatCrypto(params.value)} {par.sym}
          </span>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => <span>{params.value}</span>,
    },
    {
      field: "product",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        return <span className="">{capitalize(params.value)}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      product: item.product,
      productType: item.productType,
      amount: amountCompute(item?.productType, item?.transaction),
      // amount: item?.transaction?.crypto,
      sym: item?.transaction?.sym,
      action: item?.transaction?.tradeType,
      date: item.dateCreated,
    }));
  };

  const summary = (item) => {
    const { dateCreated, transaction } = item || {};
    const useF = ["receive", "buy", "convert", "transfer"];
    const useC = ["sell", "send", "convert", "transfer"];

    const typeWord = {
      buy: "bought",
      sell: "sold",
      receive: "received",
      send: "sent",
      convert: "converted",
    };

    const typeWordAlt = {
      buy: "buying",
      sell: "selling",
      receive: "receieving",
      send: "sending",
      convert: "converting",
    };

    const symUnit = symCompute(item?.productType, item?.transaction);

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },

          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          // transactionRef: {
          //   value: item?.transRef,
          //   type: "text",
          //   disableDisplay: !item?.transRef,
          //   clickable: true,
          // },

          assetType: {
            value: startCase(item?.productType),
            disableDisplay: !item?.productType,
          },

          network: {
            value: upperCase(item?.network),
            disableDisplay: !item?.network,
          },

          tradeType: {
            value: startCase(transaction?.tradeType),
            type: "text",
          },

          [`amount${capitalize(typeWord?.[item?.transaction?.tradeType])}`]: {
            value: `${formatCrypto(
              transaction?.crypto
            )} ${symUnit} (${formatInputNum(transaction?.usd, 2)} USD)`,
          },

          fees: {
            value: `${formatCrypto(transaction?.fees)} ${
              transaction?.sym
            } (${formatInputNum(transaction?.feesUsd, 2)} USD)`,
            disableDisplay: !transaction?.feesUsd,
          },

          [`${capitalize(typeWordAlt?.[item?.transaction?.tradeType])} rate`]: {
            value: `${formatInputNum(
              transaction?.fiatRate || transaction?.rate,
              2
            )} ${transaction?.fiatSym || transaction?.sym || "NGN"} / USD`,

            disableDisplay:
              (!transaction?.fiatRate && !transaction?.fiatSym) ||
              (!transaction?.rate && !transaction?.sym),
          },

          recipientAddress: {
            value: transaction?.recipientAddress,
            disableDisplay: !transaction?.recipientAddress,
            clickable: true,
          },
          // dollarAmount: {
          //   value: `${formatInputNum(transaction?.usd, 2)} USD`,
          // },

          amountCredited: {
            value:
              item?.amountToCredit === "none"
                ? "None"
                : useF.includes(transaction?.tradeType)
                ? `${formatCrypto(item?.amountToCredit)} ${
                    transaction?.symTo || transaction?.sym
                  }`
                : `${formatInputNum(item?.amountToCredit, 2)} ${
                    transaction?.fiatSym || "NGN"
                  }`,
          },
          amountDebited: {
            value:
              item?.amountToDebit === "none"
                ? "None"
                : useC.includes(transaction?.tradeType)
                ? `${formatCrypto(item?.amountToDebit)} ${transaction?.sym}`
                : `${formatInputNum(item?.amountToDebit, 2)} ${
                    transaction?.fiatSym || "NGN"
                  }`,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function fundFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return <span className="">{formatMoney(params.value)}</span>;
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: "Fiat",
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.settlementAmount,
      date: item.dateCreated,
    }));
  };

  const summary = (item) => {
    const { customerDepositDetails, dateCreated } = item || {};
    const { accountName, accountNumber, bank } = customerDepositDetails || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.transactionReference,
            type: "text",
            clickable: true,
          },
          totalAmount: {
            value: formatMoney(item?.totalAmountDeposit),
          },
          totalAmountFunded: {
            value: formatMoney(item?.settlementAmount),
          },
          charges: {
            value: formatMoney(item?.charges),
          },

          bankDetails: {
            value: `${accountName}/${accountNumber}/${bank?.name}`,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function transferFiat(current_user) {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: "Fiat",
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.totalAmount,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: 0,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          ...(current_user === item.customerId && {
            transferTo: {
              value: item?.transferDetails?.userTo?.email,
            },
          }),

          ...(current_user === item.transferCustomerId && {
            transferFrom: {
              value: item?.transferDetails?.userFrom?.email,
            },
          }),

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          ...(current_user === item.customerId && {
            walletsBalancesBefore: {
              value: <WalletBalance balance={item?.walletsBalance} />,
              type: "dropdown",
            },
          }),
        }
      : {};
  };

  return { columns, rows, summary };
}

export function utilities() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "product",
      headerName: "Product",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      // product: item.product,
      product: "Utility",
      productType: item.productType,
      amount: item.amount,
      beneficiary: item.beneficiary || item.phoneN,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.transId,
            type: "text",
            clickable: true,
          },
          // transactionRef: {
          //   value: item?.transRef,
          //   type: "text",
          //   clickable: true,
          // },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          productType: {
            value: item?.productType,
          },

          provider: {
            value: item?.provider,
          },
          beneficiary: {
            value: item?.beneficiary || item.phoneN,
          },

          productPlan: {
            value: item?.productPlan || item?.transaction,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary, pendingRows: rows, pendingSummary: summary };
}

export function transferBonus() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToCredit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize("fiat")}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: item.product,
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.amount,
      date: item.dateCreated,
      walletToCredit: item.walletToCredit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToCredit,
                }}
              />
            ),
          },
          bonusDescription: {
            value: item?.bonusDescription,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function convertFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    // { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize("fiat")}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{params.value}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      asset: item.product,
      assetType: item.productType?.split("-")[1],
      action: item.productType,
      amount: item.amount,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          convertFrom: {
            value: `${formatInputNum(item?.amountToDebit, 2)} ${
              item?.convertDetails?.sym
            }`,
          },
          convertTo: {
            value: `${formatInputNum(item?.amountToCredit, 2)} ${
              item?.convertDetails?.symTo
            }`,
          },
          conversionRate: {
            value: `${item?.convertDetails?.rate} ${item?.convertDetails?.sym} - ${item?.convertDetails?.rateTo} ${item?.convertDetails?.symTo}`,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function pendingWithdrawFiat() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        const par = params.row;
        return (
          <Amount
            {...{ amount: params.value, walletType: par.walletToDebit }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },

    {
      field: "asset",
      headerName: "Asset",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },

    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return (
      arr
        // ?.sort((b, c) => c.dateCreated - b.dateCreated)
        ?.map((item, index) => ({
          nos: index + 1,
          id: item.id,
          idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
          status: item.status,
          asset: "Fiat",
          assetType: item.productType?.split("-")[1],
          action: item.productType,
          amount: item.totalAmount,
          date: item.dateCreated,
          walletToDebit: item.walletToDebit,
        }))
    );
  };

  const summary = (item) => {
    const { withdrawDetails, totalAmount, dateCreated } = item || {};
    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.id,
            type: "text",
            clickable: true,
          },

          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.totalAmount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          charges: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.charges,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          amount: {
            value: (
              <Amount
                {...{
                  amount: withdrawDetails?.amount?.amount,
                  walletType: item.walletToDebit,
                }}
              />
            ),
          },
          beneficiaryMomoName: {
            value: withdrawDetails?.accountDetails?.momoName,
            type: "text",
          },

          beneficiaryMomoNumber: {
            value: withdrawDetails?.accountDetails?.momoNumber,
          },
          beneficiaryMomoType: {
            value: withdrawDetails?.accountDetails?.momoType,
          },
          beneficiaryName: {
            value:
              withdrawDetails?.accountDetails?.accountName ||
              withdrawDetails?.accountName,
            type: "text",
          },
          beneficiaryBank: {
            value:
              withdrawDetails?.accountDetails?.bankName ||
              withdrawDetails?.bankName,
          },
          beneficiaryAccountNo: {
            value:
              withdrawDetails?.accountDetails?.bankAccountNo ||
              withdrawDetails?.bankAccountNo,
          },
          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}

export function pendingUtilities() {
  const columns = [
    { field: "nos", headerName: "Nos", width: 80 },
    { field: "idNos", headerName: "ID", width: 140 },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Amount
            {...{
              amount: params.value,
              walletType: params?.row?.walletToDebit,
            }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const par = params.value;
        return <StatusObj type={par} />;
      },
    },
    {
      field: "product",
      headerName: "Product",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 130,
      renderCell: (params) => {
        const par = params.row;
        return <span className="">{capitalize(params.value)}</span>;
      },
    },
    {
      field: "beneficiary",
      headerName: "Beneficiary",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      width: 180,
      renderCell: (params) => (
        <span className="">{formatDate(params.value)}</span>
      ),
    },
  ];

  const rows = (arr) => {
    return arr?.map((item, index) => ({
      nos: index + 1,
      id: item.id,
      idNos: `ESET|${toUpper(item.id.slice(0, 7))}`,
      status: item.status,
      // product: item.product,
      product: "Utility",
      productType: item.productType,
      amount: item.amount,
      beneficiary: item.beneficiary || item.phoneN,
      date: item.dateCreated,
      walletToDebit: item.walletToDebit,
    }));
  };

  const summary = (item) => {
    const { dateCreated } = item || {};

    return item
      ? {
          status: {
            value: <StatusObj type={item.status} />,
          },
          dateCreated: {
            value: formatDate(dateCreated),
            type: "text",
          },
          transactionId: {
            value: item?.transId,
            type: "text",
            clickable: true,
          },
          // transactionRef: {
          //   value: item?.transRef,
          //   type: "text",
          //   clickable: true,
          // },
          totalAmount: {
            value: (
              <Amount
                {...{
                  amount: item?.amount,
                  walletType: item?.walletToDebit,
                }}
              />
            ),
          },
          productType: {
            value: item?.productType,
          },

          provider: {
            value: item?.provider,
          },
          beneficiary: {
            value: item?.beneficiary || item.phoneN,
          },

          productPlan: {
            value: item?.productPlan || item?.transaction,
          },

          walletCredited: {
            value: startCase(item.walletToCredit),
          },
          walletDebited: {
            value: startCase(item.walletToDebit),
          },
          walletsBalancesBefore: {
            value: <WalletBalance balance={item?.walletsBalance} />,
            type: "dropdown",
          },
        }
      : {};
  };

  return { columns, rows, summary };
}
