import React from "react";
import { toLower } from "lodash";
import {
  BackdropSection,
  Button,
  IconBox,
  SectionContent,
  WalletIcon,
} from "components";
import { copyText } from "utils";
import { useGenerateWalletAddress, useGetWalletAddress } from "api";
import NoDisplay from "../components/NoDisplay";
import QRCode from "react-qr-code";

export default function DepositCrypto({
  currentWalletProps: currentWallet,
  displayPage,
}) {
  const [isSubmitting, isSubmitted, getAddressOnCreate, generateAddress] =
    useGenerateWalletAddress();

  const { isLoading, isError, getAddress } = useGetWalletAddress({
    type: currentWallet?.sym,
  });

  const walletAddress = getAddress || getAddressOnCreate;

  if (!displayPage) {
    return <NoDisplay type="receiving" />;
  }

  return (
    <SectionContent>
      {!isLoading && !isError ? (
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-span-1">
            <div className="rounded">
              {walletAddress ? (
                <div className="flex flex-col items-center justify-center p-2">
                  <div className="flex items-center justify-center">
                    <WalletIcon type={currentWallet?.type} />
                    <div className="ml-2 font-bold">
                      {currentWallet?.name} Wallet Address
                    </div>
                  </div>
                  <div className="my-3 h-[200px] w-[200px] p-2">
                    <QRCode
                      size={256}
                      style={{
                        height: "100%",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={walletAddress}
                      viewBox={`0 0 256 256`}
                    />
                    {/* <img
                      src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${walletAddress}`}
                      className="h-full w-full"
                      alt=""
                    /> */}
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div className="mb-3 text-center text-sm">
                      Select{" "}
                      <span className="font-bold uppercase">
                        {currentWallet?.network}
                      </span>{" "}
                      network to send coins to this address. Send only{" "}
                      <span className="font-bold">
                        {currentWallet?.sym_alt}
                      </span>{" "}
                      into this address. Coins sent to the wrong address or the
                      wrong network can not be recovered.
                    </div>

                    <div className="mb-2 break-all">{walletAddress}</div>
                    <Button
                      // color="success"
                      variant="outlined"
                      label="Copy"
                      labelIcon="ContentCopy"
                      handleClick={() =>
                        copyText(
                          walletAddress,
                          "Wallet address successfully copied"
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <IconBox icon={"PriorityHigh"} gradient shadowAlt />
                  <p className="mt-3 text-sm">
                    Generate {toLower(currentWallet?.name)} wallet address.
                  </p>

                  <div className="action mt-4">
                    <div className="action-buttons">
                      <Button
                        label="Generate"
                        gradient
                        isSubmitting={isSubmitting}
                        handleClick={() =>
                          generateAddress({
                            type: currentWallet?.assetType,
                            walletAddressType: currentWallet?.address,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={isLoading || isError} />
        </div>
      )}
    </SectionContent>
  );
}
