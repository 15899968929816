import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "utils";
import DownloadButton from "components/DownloadButton";

export default function AuthContainer({
  title,
  subTitle,
  footer,
  showForgetPass,
  showRememberPass,
  showDownloadAppButton,
  children,
}) {
  return (
    <div className={classNames("flex bg-[#f4f5fa]")}>
      <div className="hidden h-screen w-7/12 items-center justify-around bg-[#1f9961] md:flex">
        <div className="flex h-full w-full flex-row flex-col items-center justify-center">
          <img
            src={require("../assets/images/auth-image.svg").default}
            className="h-full w-full"
            alt=""
          />
        </div>
      </div>
      <div className="bg-background_main relative left-0 m-auto h-full w-full p-4 sm:w-5/6 md:left-0 md:w-5/12 md:p-8">
        <div className="m-auto flex h-full flex-col justify-center md:w-full xl:w-4/5">
          <div className="mb-4 flex items-center justify-center ">
            <img
              src={require("../assets/images/e-logo.svg").default}
              className="h-8 w-8"
              alt=""
            />
            <div className="text-2xl font-semibold text-primary">esetech</div>
          </div>
          <div className="w-full rounded-lg bg-white p-6 shadow-md md:p-8">
            <div className="form-heading text-center">
              <h1 className="mb-1 text-lg font-semibold  text-gray-500">
                {title}
              </h1>
              <p className="mb-7 text-sm font-normal text-gray-600">
                {subTitle}
              </p>
            </div>
            <div className="mb-4 w-full">{children}</div>
            {footer && (
              <>
                <hr className="mb-6 border-t" />
                {showForgetPass && (
                  <div className="text-center">
                    <Link
                      className="inline-block align-baseline text-sm text-text-main hover:text-dark"
                      to="/forget-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                )}
                <div className="text-center">
                  <Link
                    className="inline-block align-baseline text-sm text-text-main hover:text-dark"
                    to={footer.link}
                  >
                    {footer.text}
                  </Link>
                </div>
              </>
            )}

            {showRememberPass && (
              <>
                <hr className="mb-4 border-t" />
                <div className="text-center">
                  <Link
                    className="inline-block align-baseline text-sm text-text-main hover:text-dark"
                    to={"/login"}
                  >
                    Remembered Password? Back to Login
                  </Link>
                </div>
              </>
            )}
          </div>
          {showDownloadAppButton && <DownloadButton />}
        </div>
      </div>
    </div>
  );
}
