import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";

import { SetTimerLogout } from "components";
import { PageContainer } from "shared";
import Account from "./sub-pages/Account";
import DefaultCurrency from "./sub-pages/DefaultCurrency";
import Security from "./sub-pages/Security";
import Referrals from "./sub-pages/Referrals";
import BankAccounts from "./sub-pages/BankAccounts";
import Notifications from "./sub-pages/Notifications";
import SettingsDrawer from "./SettingsDrawer";
import SettingsContent from "./SettingsContent";
import SettingsContentMobile from "./SettingsContentMobile";
import { classNames, useBreakpoint } from "utils";
import { useLocation } from "react-router-dom";

const tabList = [
  {
    label: "Account",
    Icon: "PersonOutline",
    IconAlt: "Person",
    subtitle: "Details about your personal information",
    Comp: Account,
  },

  {
    label: "Default Currency",
    Icon: "MonetizationOnOutlined",
    IconAlt: "MonetizationOn",
    subtitle: "Set default fiat currency",
    Comp: DefaultCurrency,
  },
  {
    label: "Security",
    Icon: "LockOutlined",
    IconAlt: "Lock",
    subtitle: "Secure your assets and portfolio",
    Comp: Security,
  },
  {
    label: "Bank Accounts",
    Icon: "AccountBalanceOutlined",
    IconAlt: "AccountBalanceRounded",
    subtitle: "Manage your withdrawal details",
    Comp: BankAccounts,
  },
  {
    label: "Referrals",
    Icon: "AddLink",
    IconAlt: "AddLink",
    subtitle: "Invite friends and earn",
    Comp: Referrals,
  },
  {
    label: "Notification",
    Icon: "NotificationAddOutlined",
    IconAlt: "NotificationAddRounded",
    subtitle: "Customize your notifications",
    Comp: Notifications,
  },
  // { label: 'Verification', Icon: BookmarkAddedOutlined, Comp:  },
];

export default function Index() {
  const { state } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(state?.page || 0);
  const [mobilePopup, setMobilePopup] = useState(null);

  const { logoutTimer } = useSelector((state) => state.auth);

  const breakpoint = useBreakpoint();

  const pageContent = tabList?.[selectedIndex];
  return (
    <PageContainer header="Settings">
      <div className="grid grid-cols-1 gap-6 ">
        <div className="col-span-1">
          <Fade>
            <div className="">
              <div className="relative overflow-hidden rounded-xl bg-white shadow-md">
                <div
                  className={classNames(
                    "flex flex-row overflow-hidden",
                    breakpoint.md ? "flex-row" : "flex-col"
                  )}
                >
                  <SettingsDrawer
                    {...{
                      selectedIndex,
                      setSelectedIndex,
                      tabList,
                      setMobilePopup,
                    }}
                  />
                  {breakpoint.md ? (
                    <SettingsContent
                      pageContent={pageContent}
                      selectedIndex={selectedIndex}
                    />
                  ) : (
                    <SettingsContentMobile
                      {...{
                        pageContent,
                        selectedIndex,
                        mobilePopup,
                        setMobilePopup,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <SetTimerLogout logoutTimer={logoutTimer} />
    </PageContainer>
  );
}
