import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  IconBox,
  SimpleTable,
  SectionContent,
  PopupDrawer,
  SimpleIcon,
} from "components";
import { securitActions } from "utils/constants";
import CreatePin from "../drawer-content/CreatePin";
import ChangePass from "../drawer-content/ChangePass";
// import ChangeEmail from "../drawer-content/ChangeEmail";
import ChangePhone from "../drawer-content/ChangePhone";
import TwoFactorPhone from "../drawer-content/TwoFactorPhone";

import { classNames, formatDate } from "utils";

const twofactorList = [
  {
    name: (
      <>
        SMS
        <br />
        Authenticator
      </>
    ),
    id: "phoneAuthentication",
    iconName: "Textsms",
    title: "Text Message 2FA",
  },
  {
    name: (
      <>
        Email
        <br />
        Authenticator
      </>
    ),
    id: "emailAuthentication",
    iconName: "Email",
    title: "Email Authenticator 2FA",
  },
  {
    name: (
      <>
        Google
        <br />
        Authenticator
      </>
    ),
    id: "appAuthentication",
    iconName: "Google",
    title: "Google Authenticator 2FA",
  },
];

export default function Security() {
  const [openDrawer, setDrawer] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);

  const {
    user_details: { recentDevices, twoFactorAuth },
  } = useSelector((state) => state?.profile);

  const { enable2factorAuth, factorType } = twoFactorAuth || {};

  const contents = {
    changePin: CreatePin,
    changePassword: ChangePass,
    changePhoneNumber: ChangePhone,
    phoneAuthentication: TwoFactorPhone,
    emailAuthentication: TwoFactorPhone,
    appAuthentication: TwoFactorPhone,
  };

  const title =
    [...securitActions, ...twofactorList]?.find((i) => i.id === currentContent)
      ?.title || null;
  const pageId =
    [...securitActions, ...twofactorList]?.find((i) => i.id === currentContent)
      ?.id || null;
  const Content = contents[currentContent] || null;

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <SectionContent>
            <div className="grid grid-cols-1 gap-4 p-2 md:grid-cols-3">
              {securitActions?.map((item, index) => (
                <div className="col-span-1" key={item.title}>
                  <button
                    onClick={() => {
                      setDrawer(true);
                      setCurrentContent(item.id);
                    }}
                    className="h-full w-full text-left"
                  >
                    <div className="relative top-0 flex h-full flex-col justify-between rounded-lg bg-primary/5 p-4 duration-500 ease-in hover:-top-2 hover:shadow-lg">
                      <div className="flex items-start justify-start">
                        <div className="mr-4 flex items-start justify-between">
                          <IconBox
                            icon={item.Icon}
                            // iconColor={'bg-primary-500'}
                            gradient
                          />
                        </div>

                        <div className="text-sm">
                          <div className="flex flex-col">
                            <h6
                              className={`text-base font-bold text-color-main`}
                            >
                              {item.title}
                            </h6>
                          </div>
                          <div className="mt-2 text-gray-500 md:block">
                            {item.subtitle_alt}
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </SectionContent>
        </div>

        <div className="col-span-1">
          <SectionContent>
            <div className="p-2">
              <h5 className="mb-6 font-semibold">Two-factor Authentication</h5>
              <div className="text-center">
                <div className="mb-4 font-bold">
                  Keep your account secure with authentication step.
                </div>

                <div className="m-auto w-full text-sm text-gray-400 md:w-3/5">
                  Two-factor authentication adds an additional layer of security
                  to your account by requiring more than just a password to log
                  in.
                </div>
                <div className="mt-8 flex flex-row items-center justify-center gap-4">
                  {twofactorList.map((item, index) => {
                    const is2faEnabled =
                      enable2factorAuth && factorType === item.id;

                    return (
                      <div
                        className="flex flex-col items-center justify-center"
                        key={index}
                      >
                        {is2faEnabled && (
                          <div className="mb-2 flex h-4 w-4 items-center justify-center rounded-full bg-gradient-to-br from-button_gradient_from to-button_gradient_to text-white shadow">
                            <SimpleIcon
                              icon="Check"
                              fontSize={12}
                              frontColor="text-white"
                            />
                          </div>
                        )}

                        <button
                          className=""
                          onClick={() => {
                            setDrawer(true);
                            setCurrentContent(item.id);
                          }}
                        >
                          <div
                            className={classNames(
                              "flex h-full w-28 flex-col items-center justify-center rounded-lg border border-b border-slate-50 p-2",
                              is2faEnabled
                                ? "bg-gradient-to-br from-button_gradient_from to-button_gradient_to"
                                : "bg-dark/5"
                            )}
                          >
                            <div
                              className={classNames(
                                "flex h-7 w-7 items-center justify-center rounded-full shadow",
                                is2faEnabled ? "bg-white" : "bg-primary"
                              )}
                            >
                              <SimpleIcon
                                icon={item.iconName}
                                fontSize={16}
                                frontColor={
                                  is2faEnabled ? "text-primary" : "text-white"
                                }
                                otherClass="iconShadow-alt"
                              />
                            </div>
                            <div
                              className={classNames("mt-4 max-w-3xl flex-1")}
                            >
                              <div
                                className={classNames(
                                  "text-sm font-semibold",
                                  is2faEnabled ? "text-white" : ""
                                )}
                              >
                                {item.name}
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </SectionContent>
        </div>

        <div className="col-span-1">
          <SectionContent>
            <div className="p-2">
              <h5 className="mb-6 font-semibold">Recent devices</h5>
              <div className="rounded bg-slate-50 p-2">
                <SimpleTable
                  columns={[
                    { label: "Client", id: "client" },
                    { label: "Device", id: "device" },
                    { label: "OS", id: "os" },
                    { label: "IP Address", id: "ipAddress" },
                    { label: "Logged In", id: "time" },
                  ]}
                  rows={recentDevices?.map((i) => ({
                    client: `${i?.client?.name} ${i?.client?.type}`,
                    device: `${i?.device?.type} ${i?.device?.brand}`,
                    os: `${i?.os?.name} ${i?.os?.platform} ${i?.os?.version}`,
                    ipAddress: i.ipAddress,
                    time: formatDate(i.time),
                  }))}
                />
              </div>
            </div>
          </SectionContent>
        </div>
      </div>

      <PopupDrawer
        open={openDrawer}
        setOpenDrawer={setDrawer}
        // keepOpen={isUpdating}
      >
        <div className="bg-gray-50 p-4 sm:flex sm:px-4">
          <h5 className="font-bold">{title}</h5>
        </div>
        <div className="mt-0 bg-[#f4f8f5] p-2">
          <div className="drawer-content">
            <SectionContent>
              {Content && <Content pageId={pageId} setDrawer={setDrawer} />}
            </SectionContent>
          </div>
        </div>
      </PopupDrawer>
    </>
  );
}
