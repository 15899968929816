import React from "react";
import { BackdropSection, PopupDrawer, Summary } from "components";

export default function TransactionPopup({
  open,
  setOpen,
  transDetails,
  useValue,
}) {
  return (
    <PopupDrawer {...{ setOpenDrawer: setOpen, open }}>
      <div className="rounded bg-slate-50 p-2">
        <div className="bg-gray-50 px-4 py-4 sm:flex sm:px-4">
          <div className="flow-root">
            <h5 className="font-bold">Transaction Details</h5>
          </div>
        </div>

        <div className="relative grid gap-6 bg-white px-4 py-4 sm:gap-8 sm:p-4">
          {transDetails ? (
            <Summary
              tradeSummary={transDetails}
              title="Transaction Details"
              // useValue
            />
          ) : (
            <div className="relative h-[350px]">
              <BackdropSection open={true} />
            </div>
          )}
        </div>
      </div>
    </PopupDrawer>
  );
}
