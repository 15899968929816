import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  BackdropSection,
  Button,
  EnterPIN,
  IconBox,
  SectionContent,
  WalletBalance,
} from "components";
import { createRefID } from "utils";
import { lowerCase, pick } from "lodash";
import {
  useGetFiatCharges,
  useGetUSDFiatRate,
  useWithdrawFundNaira,
} from "api";
import TotalTrans from "../shared/TotalTrans";
import OwnAccount from "./OwnAccount";
import OthersAccount from "./OthersAccount";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function WithdrawComp({ accounts, label, type }) {
  const [bankDetails, setBankDetails] = useState(null);
  const [amount, setAmount] = useState("");
  const [saveBene, setSaveBene] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [isSubmitting, isSubmitted, submitWithdraw] = useWithdrawFundNaira();

  const { nairaWallet } = useSelector((state) => state.account);

  const { NGN = 0 } = useGetUSDFiatRate() || {};
  const chargesObj = useGetFiatCharges("naira");
  const { minAmount, maxAmount, charges, maintainMinimum } = chargesObj || {};

  const { amount: naira_Wallet } = nairaWallet || {};

  const handleAmtChange = (val) => {
    let e = val;
    e = e.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }

    const total = parseInt(Boolean(e) ? e : 0) + charges;
    const limitLow = parseInt(e) < minAmount;
    const limitHigh = parseInt(e) > maxAmount;
    const overlimit = total >= parseInt(naira_Wallet || 0);
    const maintainMin = parseInt(naira_Wallet || 0) - total < maintainMinimum;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMin || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Withdrawal must be ₦${minAmount} and above`
        : limitHigh
        ? `Withdrawal must be ₦${maxAmount} and below`
        : maintainMin
        ? `Maintain a minimum of ₦${maintainMinimum} in wallet`
        : ""
    );
  };

  const processWithdraw = () => {
    const values = {
      productType: `withdrawal-naira`,
      accountType: type,
      amountToDebit: parseInt(totalAmt),
      amount: parseInt(amount),
      totalAmount: parseInt(totalAmt),
      type: "naira",
      withdrawDetails: {
        amount: {
          amount: parseInt(amount),
          charges,
          totalAmount: parseInt(totalAmt),
          maintainMax: parseFloat(maintainMinimum),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
        accountDetails: pick(bankDetails, [
          "bankAccountNo",
          "accountName",
          "bankName",
          "bankCode",
        ]),
      },
    };

    submitWithdraw({
      values,
      code: bankDetails?.bankCode,
      saveBene,
    });
  };

  const Block = type === "other-account" ? OthersAccount : OwnAccount;

  const accountL = ["own-account", "beneficiary-account"];

  const bankDetailsHasProps = (arr) => {
    return arr.every((item) => bankDetails?.hasOwnProperty(item));
  };

  return (
    <>
      {chargesObj ? (
        <SectionContent>
          <div className="p-2">
            {(accounts?.length && accountL.includes(type)) ||
            type === "other-account" ? (
              <div className="grid grid-cols-1 gap-6">
                <div className="col-span-1">
                  <div className="rounded">
                    <WalletBalance
                      type={"naira"}
                      amount={nairaWallet?.amount || 0}
                      amountUSD={(nairaWallet?.amount || 0) / NGN}
                      sym="NGN"
                    />
                  </div>
                </div>

                <div className="col-span-1">
                  <div className="grid grid-cols-1 gap-6">
                    <Block
                      {...{
                        errorAmt,
                        errorText,
                        handleAmtChange,
                        processWithdraw,
                        accounts,
                        setBankDetails,
                        bankDetails,
                        amount,
                        label,
                        isSubmitting,
                      }}
                    />
                    <TotalTrans {...{ totalAmt, charges }} />

                    {bankDetails &&
                    bankDetailsHasProps([
                      "bankAccountNo",
                      "accountName",
                      "bankName",
                      "bankCode",
                      "isVerified",
                    ]) &&
                    parseInt(amount) &&
                    !errorAmt ? (
                      <div className="col-span-1">
                        {bankDetails && type === "other-account" && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={saveBene}
                                size="small"
                                onChange={() => setSaveBene(!saveBene)}
                              />
                            }
                            label="Save Beneficiary"
                            classes={{ label: "!text-sm ml-2" }}
                          />
                        )}
                        <div className="mt-1">
                          <div>
                            <EnterPIN
                              processSubmit={processWithdraw}
                              btnText="Withdraw Fund"
                              isSubmitting={isSubmitting}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <IconBox icon={"PriorityHigh"} gradient />
                <p className="mt-3 text-sm">
                  No {lowerCase(type)} details saved.
                </p>
                {type === "own-account" && (
                  <div className="action mt-4">
                    <div className="action-buttons">
                      <Button
                        label="Save Own Account"
                        gradient
                        link="/settings"
                        state={{ page: 2 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </SectionContent>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </>
  );
}
