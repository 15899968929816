/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";

import { SimpleIconButton } from "components";
import { capitalize, filter } from "lodash";

import SellCrypto from "../sub-page/SellCrypto";
import BuyCrypto from "../sub-page/BuyCrypto";
import SendCrypto from "../sub-page/SendCrypto";
import DepositCrypto from "../sub-page/DepositCrypto";
import ConvertCrypto from "../sub-page/ConvertCrypto";
import { useSelector } from "react-redux";
import {
  useCryptoWalletListProps,
  useGetSiteTradeRate,
  useWalletStatus,
} from "api";
import { classNames, useBreakpoint } from "utils";
import TransferCrypto from "../sub-page/TransferCrypto";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupContentMobile() {
  const { walletProps, rate } = useCryptoWalletListProps();

  const walletPropsFiltered = walletProps.map((i) => ({
    ...i,
    type: i.id,
    id: "crypto",
  }));

  const [dropdownList, setDropdownList] = useState(walletPropsFiltered);
  const [displayPage, setDisplayPage] = useState(null);

  const [currentWalletProps, setCurrentWalletProps] = useState(dropdownList[0]);

  const { transactionStatus } = useSelector((state) => state.transaction);

  const { user_details } = useSelector((state) => state.profile);

  const { cryptoAuth } = useSelector((state) => state.utils);

  const {
    walletStatus: {
      currentWallet,
      currentWalletAction: currentAction,
      currentWalletActionSublinkProps,
    },
  } = useSelector((state) => state.utils);

  const [setWalletStatus] = useWalletStatus();

  const siteRate = useGetSiteTradeRate(
    currentWallet,
    user_details?.defaultCurrency,
    currentAction
  );

  useEffect(() => {
    const dropdownListFil = filter(walletPropsFiltered.slice(), (o) => {
      const sym = ["BTC", "ETH"];
      return sym.includes(o.sym);
    });

    if (currentAction === "convert" && currentWallet === "tether") {
      setDropdownList(dropdownListFil);
      setCurrentWalletProps(dropdownListFil[0]);
    } else {
      let r = walletPropsFiltered.find((i) => i.type === currentWallet);
      if (currentWalletActionSublinkProps) {
        const { network, sym, assetType, address } =
          currentWalletActionSublinkProps;
        r = { ...r, network, sym, assetType, address };
      }
      setCurrentWalletProps(r);

      setDropdownList(walletPropsFiltered);
    }
  }, [currentWallet, currentAction, rate]);

  useEffect(() => {
    setDisplayPage(cryptoAuth?.[currentWallet]?.[currentAction]);
  }, [currentWallet, currentAction]);

  const open = Boolean(currentAction);

  const handleClose = () => {
    if (!transactionStatus) {
      setWalletStatus({
        currentWalletAction: null,
        currentWalletActionSublinkProps: null,
      });
    }
  };

  const pages = {
    sell: SellCrypto,
    buy: BuyCrypto,
    send: SendCrypto,
    receive: DepositCrypto,
    convert: ConvertCrypto,
    transfer: TransferCrypto,
  };

  const Pages = pages[currentAction];

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="flex justify-between">
            <h6 className="ml-2 text-lg font-bold">
              {capitalize(currentAction)} Crypto
            </h6>

            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-dialog">
              <SimpleIconButton
                icon="Close"
                frontColor="text-primary"
                onClick={handleClose}
                fontSize="small"
                className="!p-1"
              />
            </div>
          </Toolbar>
        </AppBar>

        <div
          className={classNames(
            "h-full overflow-auto rounded-2xl bg-gray-50 text-text-color"
          )}
        >
          {/* {user_details?.transactionPIN ? ( */}
          <div className="mt-0 p-2">
            {currentAction && (
              <Pages
                siteRate={siteRate}
                displayPage={displayPage}
                currentWalletName={currentWallet}
                {...{
                  currentWalletProps,
                  setCurrentWalletProps,
                  dropdownList,
                }}
              />
            )}
          </div>
          {/* ) : (
            <div className="col-span-1 h-full bg-gray-50 p-2 md:col-span-3 lg:col-span-2">
              <div className="rounded-lg bg-white p-6 shadow-card-box">
                <div className="flex flex-col items-center justify-center">
                  <IconBox icon={"PriorityHigh"} gradient sh adowAlt />
                  <p className="mt-4 text-sm">Create PIN to continue.</p>
                  <div className="action mt-2">
                    <div className="action-buttons">
                      <Button
                        label="Create PIN"
                        gradient
                        link="/settings"
                        state={{ page: 1 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </Dialog>
    </div>
  );
}
