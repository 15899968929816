import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddCircleOutline, HighlightOff } from "@mui/icons-material";

import { IconBox, SectionContent } from "components";
import { AddAccount, RemoveAccount } from "../drawer-content/UpdateAccount";

export default function BankAccounts() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [acctDelete, setAcctDelete] = useState(null);
  const [accountName, setAccountName] = useState(null);

  const {
    account: { withdrawalAccount: bank_Account },
  } = useSelector((state) => state);

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <h5 className="mb-2 font-semibold">Own Accounts</h5>
          <SectionContent>
            <div className="p-2">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                {bank_Account?.ownDetails?.length ? (
                  bank_Account?.ownDetails?.map((item) => (
                    <div className="col-span-1" key={item.id}>
                      <div className="relative top-0 h-full bg-transparent duration-300 ease-in hover:-top-2 hover:shadow-lg ">
                        <div className="h-full rounded-lg bg-primary/5">
                          <div className="flex flex-col p-4">
                            <div className="flex flex-row items-start">
                              <div className="mr-4 flex items-start">
                                <IconBox
                                  icon={"AccountBalanceOutlined"}
                                  // iconColor="bg-primary-500"
                                  gradient
                                />
                              </div>

                              <div className="text-sm md:my-0">
                                <div className="flex items-center">
                                  <h6 className="text-sm font-bold tracking-wide">
                                    {item.bankName}
                                  </h6>
                                  <button
                                    className="ml-2 text-red-500"
                                    onClick={() => {
                                      setAcctDelete(item);
                                      setOpenPopup(true);
                                      setAccountName("ownDetails");
                                    }}
                                  >
                                    <HighlightOff />
                                  </button>
                                </div>
                                <div className="mt-2 text-sm text-gray-400">
                                  <div>
                                    {item.bankAccountNo} | {item.accountName}
                                  </div>
                                  {/* <div>{item.bankAccountNo}</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-1 flex flex-col items-center justify-center rounded-lg bg-slate-50 p-2">
                    <IconBox icon={"PriorityHigh"} gradient />
                    <p className="mt-3 text-sm">No account details saved.</p>
                  </div>
                )}
                <div className="col-span-1">
                  <button
                    className="relative h-full w-full rounded-lg border-2 border-dashed border-slate-200 py-4"
                    onClick={() => setOpenDrawer(true)}
                  >
                    <div className="flex items-center justify-center">
                      <AddCircleOutline
                        // iconShadow
                        className="text-slate-300"
                        sx={{ fontSize: 50 }}
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </SectionContent>
        </div>
        <div className="col-span-1">
          {bank_Account?.beneficiaryDetails?.length ? (
            <div className="mt-6">
              <h5 className="mb-2 font-semibold">Beneficiary Accounts</h5>
              <SectionContent>
                <div className="p-2">
                  {/* <h5 className="-mt-2 pb-4">Own Account Details</h5> */}
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    {bank_Account?.beneficiaryDetails?.length
                      ? bank_Account?.beneficiaryDetails?.map((item) => (
                          <div className="col-span-1" key={item.id}>
                            <div className="relative top-0 h-full bg-transparent duration-300 ease-in hover:-top-2 hover:shadow-lg ">
                              <div className="h-full rounded-lg bg-primary/5">
                                <div className="flex flex-col p-4">
                                  <div className="flex flex-row items-start">
                                    <div className="mr-4 flex items-start">
                                      <IconBox
                                        icon={"AccountBalanceOutlined"}
                                        // iconColor="bg-primary-500"
                                        gradient
                                      />
                                    </div>

                                    <div className="text-sm md:my-0">
                                      <div className="flex items-center">
                                        <h6 className="text-sm font-bold tracking-wide">
                                          {item.bankName}
                                        </h6>
                                        <button
                                          className="ml-2 text-red-500"
                                          onClick={() => {
                                            setAcctDelete(item);
                                            setOpenPopup(true);
                                            setAccountName(
                                              "beneficiaryDetails"
                                            );
                                          }}
                                        >
                                          <HighlightOff />
                                        </button>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-400">
                                        <div>
                                          {item.bankAccountNo} |{" "}
                                          {item.accountName}
                                        </div>
                                        {/* <div>{item.bankAccountNo}</div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </SectionContent>
            </div>
          ) : null}
        </div>
      </div>

      <AddAccount openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

      <RemoveAccount
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        accountDelete={acctDelete}
        accountsName={accountName}
      />
    </>
  );
}
