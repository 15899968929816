import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, SectionContent, TextField } from "components";
import { formatDate } from "utils";
import { useUpdateProfile } from "api";
import { displayNameSchema } from "utils/formSchema";
import ProfileImage from "./ProfileImage";

export default function Account() {
  const {
    profile: { user_details },
    auth: {
      currentUser: { current_user_details: currUser },
    },
  } = useSelector((state) => state);

  const [getProfile, setProfile] = useState({
    displayName: user_details.displayName,
  });

  const [error, setError] = useState({ displayName: false });
  const [errorText, setErrorText] = useState({ displayName: "" });

  const [isUpdating, isUpdated, updateProfile] = useUpdateProfile();

  const handleDisplayName = async (e) => {
    setProfile((prevS) => ({ ...prevS, displayName: e.target.value }));
    try {
      await displayNameSchema.validate({ displayName: e.target.value });
      setError((prevS) => ({ ...prevS, displayName: false }));
    } catch (error) {
      setError((prevS) => ({ ...prevS, displayName: true }));
      setErrorText((prevS) => ({ ...prevS, displayName: error.errors }));
    }
  };

  const handleUpdateProfile = async () => {
    updateProfile(getProfile);
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="col-span-1">
        <SectionContent>
          <ProfileImage />
        </SectionContent>
      </div>
      <div className="col-span-1">
        <SectionContent>
          <div className="p-2">
            <h5 className="pb-4">Account Details</h5>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-2">
              <div className="col-span-1">
                <TextField
                  name="displayName"
                  // type="text"
                  label="Full Name"
                  value={getProfile.displayName}
                  handleChange={handleDisplayName}
                  error={error.displayName}
                  helperText={errorText.displayName}
                  disableAutoComplete
                />
              </div>

              <div className="col-span-1">
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  value={user_details.email}
                  disabled
                />
              </div>
              <div className="col-span-1">
                <TextField
                  name="phoneNumber"
                  // type="text"
                  label="Phone Number"
                  value={user_details.phoneNumber}
                  disabled
                />
              </div>
              <div className="col-span-1">
                <TextField
                  name="status"
                  // type="text"
                  label="Visibility Status"
                  value="Online"
                  disabled
                />
              </div>
              <div className="col-span-1">
                <TextField
                  name="dateJoined"
                  // type="text"
                  label="Date Joined"
                  value={formatDate(
                    user_details.dateCreated,
                    "dddd, MMM Do YYYY hh:mm a"
                  )}
                  disabled
                />
              </div>
              <div className="col-span-1">
                <TextField
                  name="lastLogin"
                  // type="text"
                  label="Last Login"
                  value={formatDate(
                    parseInt(currUser?.metadata?.lastLoginAt),
                    "dddd, MMM Do YYYY hh:mm a"
                  )}
                  disabled
                />
              </div>
              <div className="action col-span-1">
                <div className="action-buttons flex space-x-2">
                  <Button
                    label="Save Changes"
                    gradient
                    handleClick={handleUpdateProfile}
                    disabled={error.displayName || isUpdating}
                    isSubmitting={isUpdating}
                  />
                  <Button
                    label="Cancel"
                    handleClick={() =>
                      setProfile((prevS) => ({
                        ...prevS,
                        displayName: user_details.displayName,
                      }))
                    }
                    variant="outlined"
                    color="neutral"
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      </div>
    </div>
  );
}
