import React from "react";
import { useSelector } from "react-redux";
import { Button, SectionContent, SimpleIconButton } from "components";
import { copyText } from "utils";
import { useUpdateUserCode } from "api";

export default function Referrals() {
  const {
    profile: { user_details },
  } = useSelector((state) => state);

  const { userCode } = user_details;

  const [isUpdating, isUpdated, updateUserCode] = useUpdateUserCode();

  return (
    <SectionContent>
      <div className="p-2">
        <div className="mb-4 rounded-md bg-[#e7f7ea] p-4 text-sm">
          <div className="font-bold text-[#50875a]">
            Refer a friend and earn 1% when they make their first trade on
            Esetech.
          </div>
        </div>

        <div className="mt-3 flex items-center justify-between rounded-lg border border-gray-100 p-4 text-gray-500">
          {/* <div className="flex flex-col space-y-1">
            <h4 className="text-xs font-bold leading-none tracking-widest ">
              Referral Bonus
            </h4>

            <div className="flex items-center">
              <h2 className="text-xl font-bold">
                <span className="md:text-sm">₦</span>
                {formatMoney(0, "hidden")}
              </h2>
            </div>
            <div className="!mt-4">
              <Button label="Withdraw" gradient handleClick={() => {}} />
            </div>
          </div> */}

          {userCode ? (
            <div className="flex flex-col space-y-1">
              <h4 className="text-xs font-bold leading-none tracking-widest">
                Referral Code
              </h4>

              <div className="flex items-center">
                <h2 className="text-base font-bold">{userCode}</h2>
                <SimpleIconButton
                  icon="FileCopyOutlined"
                  fontSize={22}
                  onClick={() =>
                    copyText(userCode, "Referral Code successfully copied")
                  }
                />
              </div>
              <div className="!mt-4">
                <Button
                  label="Invite Friends"
                  gradient
                  handleClick={() =>
                    copyText(
                      `https://dashboard.esetech.com.ng/register?referralCode=${userCode}`,
                      "Referral Link successfully copied"
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div className="">
              <Button
                label="Generate Code"
                isSubmitting={isUpdating}
                gradient
                handleClick={() => {
                  updateUserCode();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </SectionContent>
  );
}
