import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import CompassCalibrationIcon from "@mui/icons-material/CompassCalibration";
import PermPhoneMsgTwoToneIcon from "@mui/icons-material/PermPhoneMsgTwoTone";
import CompassCalibrationTwoToneIcon from "@mui/icons-material/CompassCalibrationTwoTone";
import ConnectedTvTwoToneIcon from "@mui/icons-material/ConnectedTvTwoTone";
import ElectricBoltTwoToneIcon from "@mui/icons-material/ElectricBoltTwoTone";
import { LockOutlined } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import FiberPinTwoToneIcon from "@mui/icons-material/FiberPinTwoTone";
import MoneyIcon from "@mui/icons-material/Money";
import MoneyTwoToneIcon from "@mui/icons-material/MoneyTwoTone";

export const products = [
  {
    title: "Buy Airtime",
    subtitle: "Top-up all all Nigerian Lines with airtime here.",
    subtitle_alt: "Tap to top-up airtime",
    linkTo: "buy-airtime",
    iconColor: "bg-cyan-600",
    textColor: "text-cyan-600",
    Icon: PermPhoneMsgIcon,
    IconToned: PermPhoneMsgTwoToneIcon,
    bgGradient: "from-[#0891b2] to-[#076c85]",
  },
  {
    title: "Buy Data",
    subtitle: "Purchase Internet SME/Gifting and Data here.",
    subtitle_alt: "Tap to buy data",
    linkTo: "buy-data",
    iconColor: "bg-lime-600",
    textColor: "text-lime-600",
    Icon: CompassCalibrationIcon,
    IconToned: CompassCalibrationTwoToneIcon,
    bgGradient: "from-lime-600 to-lime-900",
  },
  {
    title: "TV Subscription",
    subtitle: "Subscribe to your favourite TV Channels.",
    subtitle_alt: "Tap to pay for TV subscription",
    linkTo: "buy-cables",
    iconColor: "bg-yellow-700",
    textColor: "text-yellow-600",
    Icon: ConnectedTvIcon,
    IconToned: ConnectedTvTwoToneIcon,
    bgGradient: "from-yellow-600 to-yellow-900",
  },
  {
    title: "Electricity",
    subtitle: "Pay your electricity bills with ease.",
    subtitle_alt: "Tap to pay electricity bills",
    linkTo: "buy-electricity",
    iconColor: "bg-orange-600",
    textColor: "text-orange-600",
    Icon: ElectricBoltIcon,
    IconToned: ElectricBoltTwoToneIcon,
    bgGradient: "from-orange-500 to-orange-900",
  },
  {
    title: "Scratch Card Pin",
    subtitle: "Generate WAEC, NECO, NABTEB Result Checker Pin.",
    subtitle_alt: "Tap to purchase scratch cards",
    linkTo: "buy-pin",
    iconColor: "bg-[#576574]",
    textColor: "text-[#576574]",
    Icon: FiberPinIcon,
    IconToned: FiberPinTwoToneIcon,
    bgGradient: "from-[#576574] to-[#c2b6b6]",
  },
  {
    title: "Airtime to Cash",
    subtitle: "Convert your excess airtime to cash instantly.",
    subtitle_alt: "Tap to sell airtime",
    linkTo: "convert-airtime",
    iconColor: "bg-[#7f5a83]",
    textColor: "text-[#7f5a83]",
    Icon: MoneyIcon,
    IconToned: MoneyTwoToneIcon,
    bgGradient: "from-[#0d324d] to-[#7f5a83]",
  },
];

export const analytics = [
  {
    text: "pending",
    // textColor: 'text-[#febe0f]',
    textColor: "text-amber-600",
    border: "border-amber-600",
    bgColorLite: "bg-amber-50",
    bgColor: "bg-amber-500",
    Icon: PendingActionsOutlinedIcon,
  },
  {
    text: "active",
    // textColor: 'text-[#28afd0]',
    textColor: "text-cyan-600",
    border: "border-cyan-600",
    bgColorLite: "bg-cyan-50",
    bgColor: "bg-cyan-500",
    // Icon: HourglassBottomRoundedIcon,
    Icon: PendingActionsOutlinedIcon,
  },
  {
    text: "approved",
    // textColor: 'text-[#5ed84f]',
    textColor: "text-green-600",
    border: "border-green-600",
    bgColorLite: "bg-green-50",
    bgColor: "bg-green-500",
    Icon: CheckCircleOutlineRoundedIcon,
  },
  {
    text: "declined",
    // textColor: 'text-[#f8313c]',
    textColor: "text-red-600",
    border: "border-red-600",
    bgColorLite: "bg-red-50",
    bgColor: "bg-red-500",
    Icon: CancelOutlinedIcon,
  },
];

export const securitActions = [
  {
    Icon: "LockOutlined",
    title: "Create/Reset PIN",
    subtitle_alt: "Click to set or reset your PIN.",
    btnText: "Change Pin",
    id: "changePin",
  },
  {
    Icon: "LockOutlined",
    title: "Change Password",
    subtitle_alt: "Click to change your password.",
    btnText: "Change Password",
    id: "changePassword",
  },
  // {
  //   Icon: "LockOutlined",
  //   title: "Change Email",
  //   subtitle_alt: "Click to change your email.",
  //   btnText: "Change Email",
  //   id: "changeEmail",
  // },
  {
    Icon: "LockOutlined",
    title: "Change Phone Number",
    subtitle_alt: "Click to change your phone number.",
    btnText: "Change Phone Number",
    id: "changePhoneNumber",
  },
];

export const createPinFormList = [
  {
    name: "pin",
    type: "text",
    label: "New PIN",
    inputType: "textfield",
    otherProps: {
      autoComplete: "off",
    },
  },
  {
    name: "confirmPin",
    type: "text",
    label: "Confirm New PIN",
    inputType: "textfield",
    otherProps: {
      autoComplete: "off",
    },
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    inputType: "textfield",
    helperText_alt: "Confirm your PIN reset with your password",
    otherProps: {
      autoComplete: "off",
    },
  },
];

export const changePinFormList = (email) => [
  {
    name: "code",
    // type: "text",
    type: "numeric",
    label: "OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your PIN change with the verification code send to the email - ${email}`,
    otherProps: {
      autoComplete: "off",
    },
  },
];

export const verifyPassFormList = [
  {
    name: "currentPass",
    type: "password",
    label: "Current Password",
    inputType: "textfield",
  },
];

export const changePassFormList = (email) => [
  {
    name: "newPass",
    type: "password",
    label: "New Password",
    inputType: "textfield",
  },
  {
    name: "confirmNewPass",
    type: "password",
    label: "Confirm New Password",
    inputType: "textfield",
  },
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your password change with the verification code send to the email - ${email}`,
  },
];

export const verifyEmailFormList = [
  {
    name: "newEmail",
    type: "email",
    label: "New Email",
    inputType: "textfield",
    otherProps: {
      // disableAutoComplete: true,
      autoComplete: "off",
    },
  },
  {
    name: "currentPass",
    type: "password",
    label: "Password",
    inputType: "textfield",
  },
];

export const verifyPhoneFormList = [
  {
    name: "phone",
    type: "number",
    label: "Phone Number",
    inputType: "phonefield",
    otherProps: {
      // disableAutoComplete: true,
      autoComplete: "off",
    },
  },
  {
    name: "currentPass",
    type: "password",
    label: "Password",
    inputType: "textfield",
  },
];

export const changeEmailFormList = (email) => [
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your email change with the verification code send to the email address - ${email}`,
    otherProps: {
      // disableAutoComplete: true,
      autoComplete: "off",
    },
  },
];

export const changePhoneFormList = (phone) => [
  {
    name: "code",
    type: "text",
    label: "Input OTP Code",
    inputType: "textfield",
    helperText_alt: `Confirm your phone change with the verification code send to the phone number - ${phone}`,
    otherProps: {
      // disableAutoComplete: true,
      autoComplete: "off",
    },
  },
];

export const tradeActions = {
  msg: {
    approved: "Your transaction has been approved.",
    pending:
      "Your transaction will be processed shortly. We will get back to you on its status.",
    active:
      "Your transaction is already in process. We will get back to you shortly.",
    declined:
      "Your transaction was declined. Kindly view the decline upload box for more details.",
  },

  declineReason: {
    "wrong-channel":
      "Your card trade was uploaded through the wrong channel. Kindly submit your trade using the right card type mode.",
    "used-card":
      "The card/code has already been redeemed/used. Kindly reach out to the source of the card for more information.",
    "invalid-card":
      "The card/code is invalid. Kindly reach out to the source of the card for more information.",
  },

  tradeStatus: {
    approved: {
      bg: "bg-green-100/50",
      text: "text-green-500",
      color: "bg-green-500",
      Icon: CheckCircleOutlineIcon,
    },
    declined: {
      bg: "bg-red-100/50",
      text: "text-red-500",
      color: "bg-red-500",
      Icon: HighlightOffIcon,
    },
    pending: {
      bg: "bg-yellow-100/50",
      text: "text-yellow-500",
      color: "bg-amber-500",
      Icon: CheckCircleOutlineIcon,
    },
    active: {
      bg: "bg-sky-100/50",
      text: "text-sky-500",
      color: "bg-cyan-500",
      Icon: CheckCircleOutlineIcon,
    },
  },
};

//// fiat

export const walletFiatBonusProps = [
  {
    iconColor: "from-green-400 to-emerald-500",
    color: "text-emerald-400",
    name: "Naira",
    title: "Available Fund",
    id: "naira",
    sym: "NGN",
    symUnit: "₦",
    img: "NGN",
    wallet: "nairaWallet",
    address: "nairaWalletAddress",
  },
  {
    iconColor: "from-green-400 to-emerald-500",
    color: "text-emerald-400",
    name: "Bonus",
    title: "Bonus Balance",
    id: "nairaBonus",
    sym: "NGN",
    symUnit: "₦",
    img: "NGN",
    wallet: "nairaWallet",
    address: "nairaWalletAddress",
  },
  {
    iconColor: "from-yellow-600 to-yellow-500",
    color: "text-yellow-500",
    // color: "text-[#7f5621]",
    name: "Cedis",
    title: "Available Fund",
    id: "cedis",
    sym: "GHS",
    symUnit: "₵",
    img: "GHS",
    wallet: "cedisWallet",
  },
  {
    iconColor: "from-[#7f5621] to-yellow-500",
    color: "text-yellow-500",
    name: "Bonus",
    title: "Bonus Balance",
    id: "cedisBonus",
    sym: "GHS",
    symUnit: "₵",
    img: "GHS",
    wallet: "cedisWallet",
  },
  {
    iconColor: "from-yellow-600 to-yellow-500",
    color: "text-yellow-500",
    // color: "text-[#7f5621]",
    name: "Cefa",
    title: "Available Fund",
    id: "cefa",
    sym: "XOF",
    symUnit: "₵",
    img: "XOF",
    wallet: "cefaWallet",
  },
  {
    iconColor: "from-[#7f5621] to-yellow-500",
    color: "text-yellow-500",
    name: "Bonus",
    title: "Bonus Balance",
    id: "cefaBonus",
    sym: "XOF",
    symUnit: "₵",
    img: "XOF",
    wallet: "cefaWallet",
  },
];

export const walletFiatProps = [
  {
    iconColor: "from-green-400 to-emerald-500",
    color: "text-emerald-400",
    name: "Naira",
    otherName: "Nigerian Naira",
    title: "Available Fund",
    id: "naira",
    sym: "NGN",
    symUnit: "₦",
    img: "NGN",
    wallet: "nairaWallet",
    address: "nairaWalletAddress",
  },
  {
    iconColor: "from-yellow-600 to-yellow-500",
    color: "text-yellow-500",
    name: "Cedis",
    otherName: "Ghananian Cedis",
    title: "Available Fund",
    id: "cedis",
    sym: "GHS",
    symUnit: "₵",
    img: "GHS",
    wallet: "cedisWallet",
  },
  {
    iconColor: "from-orange-600 to-red-300",
    color: "text-orange-500",
    name: "Cefa",
    otherName: "Western African Cefa",
    title: "Available Fund",
    id: "cefa",
    sym: "XOF",
    symUnit: "₵",
    img: "XOF",
    wallet: "cefaWallet",
  },
];

const otherActionListFiat = [
  {
    id: "transfer",
    name: "Transfer",
    icon: "ArrowCircleUpOutlined",
    bg: "bg-cyan-50",
    text: "text-cyan-500",
  },
  {
    id: "bonus",
    name: "Bonus",
    icon: "ArrowCircleDownOutlined",
    bg: "bg-purple-50",
    text: "text-purple-500",
  },
  {
    id: "convert",
    name: "Convert",
    icon: "Autorenew",
    bg: "bg-yellow-50",
    text: "text-yellow-500",
  },
];

export const actionFiatList = {
  naira: [
    {
      id: "withdrawNaira",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-50",
      text: "text-red-500",
      subLinks: [
        { name: "Own Account", id: "own-account" },
        { name: "Beneficiary Account", id: "beneficiary-account" },
        { name: "Other Account", id: "other-account" },
      ],
    },
    {
      id: "fundNaira",
      name: "Fund",
      icon: "ArrowCircleRightOutlined",
      bg: "bg-emerald-50",
      text: "text-emerald-500",
    },
    ...otherActionListFiat,
  ],
  cedis: [
    {
      id: "withdrawCedis",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-50",
      text: "text-red-500",
    },
    {
      id: "fundCedis",
      name: "Fund",
      icon: "ArrowCircleRightOutlined",
      bg: "bg-emerald-50",
      text: "text-emerald-500",
    },
    ...otherActionListFiat,
  ],
  cefa: [
    {
      id: "withdrawCefa",
      name: "Withdraw",
      icon: "ArrowCircleLeftOutlined",
      bg: "bg-red-50",
      text: "text-red-500",
    },
    {
      id: "fundCefa",
      name: "Fund",
      icon: "ArrowCircleRightOutlined",
      bg: "bg-emerald-50",
      text: "text-emerald-500",
    },
    ...otherActionListFiat,
  ],
};

/// crypto

export const walletCryptoProps = [
  {
    iconColor: "from-[#f79439] to-[#cd9108]",
    color: "text-[#f58338]",
    fill: "#f58338",
    name: "Bitcoin",
    id: "bitcoin",
    sym: "BTC",
    sym_alt: "BTC",
    sym_verify: "BTC",
    assetType: "BTC",
    img: "BTC",
    wallet: "bitcoinWallet",
    address: "bitcoinWalletAddress",
    network: "bitcoin",
  },
  {
    iconColor: "from-[#4f6ee3] to-[#4f6ee3]",
    color: "text-[#4f6ee3]",
    fill: "#4f6ee3",
    name: "Ethereum",
    id: "ethereum",
    sym: "ETH",
    sym_alt: "ETH",
    sym_verify: "ETH",
    assetType: "ETH",
    img: "ETH",
    wallet: "ethereumWallet",
    address: "ethereumWalletAddress",
    network: "ethereum (erc20)",
  },
  {
    iconColor: "from-[#368f67] to-[#368f67]",
    color: "text-[#368f67]",
    fill: "#368f67",
    name: "Tether",
    id: "tether",
    sym: "USDT",
    sym_alt: "USDT",
    sym_verify: "USDT",
    assetType: "USDT",
    img: "USDT",
    wallet: "tetherWallet",
    address: "tetherWalletAddress",
    network: "ethereum (erc20)",
  },
  {
    iconColor: "from-[#368f67] to-[#368f67]",
    color: "text-[#368f67]",
    fill: "#368f67",
    name: "Tether (Tron)",
    id: "tether-tron",
    sym: "USDT_TRON",
    sym_alt: "USDT",
    sym_verify: "TRON",
    assetType: "USDT_TRON",
    img: "USDT",
    wallet: "tetherTronWallet",
    address: "tetherTronWalletAddress",
    network: "tron (trc20)",
    noDisplay: true,
  },
  {
    iconColor: "from-[#eda637] to-[#eda637]",
    color: "text-[#eda637]",
    fill: "#eda637",
    name: "Binance",
    id: "binance",
    sym: "BNB",
    sym_alt: "BNB",
    sym_verify: "BNB",
    assetType: "BSC",
    img: "BNB",
    wallet: "binanceWallet",
    address: "binanceWalletAddress",
    network: "bnb smart chain (bep20)",
  },
  {
    iconColor: "from-[#928c8c] to-[#928c8c]",
    color: "text-[#928c8c]",
    fill: "#928c8c",
    name: "Litecoin",
    id: "litecoin",
    sym: "LTC",
    sym_alt: "LTC",
    sym_verify: "LTC",
    assetType: "LTC",
    img: "LTC",
    wallet: "litecoinWallet",
    address: "litecoinWalletAddress",
    network: "litecoin",
  },
];

const otherActionListCrypto = [
  {
    id: "sell",
    name: "Sell",
    icon: "ArrowCircleDownOutlined",
    bg: "bg-cyan-50",
    text: "text-cyan-500",
    noDisplay: [],
  },
  {
    id: "buy",
    name: "Buy",
    icon: "ArrowCircleUpOutlined",
    bg: "bg-pink-50",
    text: "text-pink-500",
    noDisplay: [],
  },
  {
    id: "receive",
    name: "Receive",
    icon: "ArrowCircleRightOutlined",
    bg: "bg-emerald-50",
    text: "text-emerald-500",
    noDisplay: [],
  },
  {
    id: "send",
    name: "Send",
    icon: "ArrowCircleLeftOutlined",
    bg: "bg-red-50",
    text: "text-red-500",
    noDisplay: [],
  },
  {
    id: "transfer",
    name: "Transfer",
    icon: "MoveDown",
    bg: "bg-orange-50",
    text: "text-orange-500",
    noDisplay: ["tether-tron", "binance", "bitcoin", "litecoin", "ethereum"],
  },
  {
    id: "convert",
    name: "Convert",
    icon: "Autorenew",
    bg: "bg-purple-50",
    text: "text-purple-500",
    noDisplay: ["tether-tron", "binance", "litecoin"],
  },
];

const actionListCryptoConcat = (type) => {
  const subLinks = [
    {
      id: "tether-erc20",
      name: "Ethereum (ERC20)",
      linkTo: "ReceiveCrypto",
      network: "ethereum (erc20)",
      sym: "USDT",
      sym_alt: "USDT",
      assetType: "USDT",
      address: "tetherWalletAddress",
    },
    {
      id: "tether-trc20",
      name: "Tron (TRC20)",
      linkTo: "ReceiveCrypto",
      network: "tron (trc20)",
      sym: "USDT_TRON",
      sym_alt: "USDT",
      assetType: "USDT_TRON",
      address: "tetherTronWalletAddress",
    },
    {
      id: "tether-bep20",
      name: "BNB Smart Chain (BEP20)",
      linkTo: "ReceiveCrypto",
      network: "bnb smart chain (bep20)",
      sym: "USDT_BSC",
      sym_alt: "USDT",
      assetType: "USDT_BSC",
      address: "tetherBscWalletAddress",
    },
  ];

  return [
    ...otherActionListCrypto.slice(0, 2),
    type === "tether"
      ? { ...otherActionListCrypto[2], subLinks }
      : otherActionListCrypto[2],
    ...otherActionListCrypto.slice(3),
  ];
};

export const actionCryptoList = {
  bitcoin: actionListCryptoConcat(),
  ethereum: actionListCryptoConcat(),
  tether: actionListCryptoConcat("tether"),
  // "tether-tron": actionListCryptoConcat(),
  binance: actionListCryptoConcat(),
  litecoin: actionListCryptoConcat(),
};

export const currencyProps = [
  {
    name: "Dollar",
    id: "dollar",
    sym: "USD",
    img: "USD",
  },
  {
    name: "Naira",
    id: "naira",
    sym: "NGN",
    img: "NGN",
  },
  {
    name: "Bitcoin",
    id: "bitcoin",
    sym: "BTC",
    img: "BTC",
  },
  {
    name: "Ethereum",
    id: "ethereum",
    sym: "ETH",
    img: "ETH",
  },
  {
    name: "Tether",
    id: "tether",
    sym: "USDT",
    img: "USDT",
  },
  {
    name: "Tether (Tron)",
    id: "tether-tron",
    sym: "USDT_TRON",
    img: "USDT",
  },
  {
    name: "Binance",
    id: "binance",
    sym: "BNB",
    img: "BNB",
  },
  {
    name: "Litecoin",
    id: "litecoin",
    sym: "LTC",
    img: "LTC",
  },
];

export const cryptoArray = [
  "bitcoinWalletAddress",
  "ethereumWalletAddress",
  "tetherWalletAddress",
  "tetherTronWalletAddress",
  "binanceWalletAddress",
  "litecoinWalletAddress",
];

export const cryptoAssetSym = {
  BTC: "BTC",
  ETH: "ETH",
  USDT: "USDT",
  USDT_TRON: "USDT",
  USDT_BSC: "USDT",
  BSC: "BSC",
  BNB: "BSC",
  LTC: "LTC",
};

/// others

export const statProps = {
  pending: {
    bg: "bg-yellow-100",
    text: "text-yellow-500",
  },
  active: {
    bg: "bg-cyan-100",
    text: "text-cyan-500",
  },
  approved: {
    bg: "bg-green-100",
    text: "text-emerald-500",
  },
  declined: {
    bg: "bg-red-100",
    text: "text-red-500",
  },
};

export const utilityList = [
  { value: "MTN", id: "mtn", idNum: "1" },
  { value: "Airtel", id: "airtel", idNum: "2" },
  { value: "9mobile", id: "9Mobile", idNum: "3" },
  { value: "Glo", id: "glo", idNum: "4" },
];
