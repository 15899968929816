import React, { useCallback, useEffect, useState } from "react";
import {
  BackdropSection,
  EnterPIN,
  Select,
  TextField,
  WalletBalance,
} from "components";
import { SectionContent } from "components";
import {
  useGetFiatCharges,
  useGetSiteFiatRate,
  useGetUSDFiatRate,
  useWithdrawFundOther,
} from "api";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import NoDisplay from "../components/NoDisplay";
import TotalTrans from "./WithdrawNaira/shared/TotalTrans";

const momoType = [
  { name: "MTN Mobile Money", id: "MTN Mobile Money" },
  { name: "Airtel Tigo Casy", id: "Airtel Tigo Casy" },
  { name: "Vodafone Cash", id: "Vodafone Cash" },
];

export default function WithdrawFiat({
  displayPage,
  currentWalletName = "cedis",
}) {
  const [amount, setAmount] = useState("");
  const [momoDetails, setMomoDetails] = useState({});
  const [totalAmt, setTotalAmt] = useState(0);
  const [errorAmt, setErrorAmt] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [isSubmitting, isSubmitted, submitWithdraw] = useWithdrawFundOther();

  const {
    currentUser: { current_user: userId },
  } = useSelector((state) => state.auth);

  const { nairaWallet, cedisWallet, cefaWallet } = useSelector(
    (state) => state.account || {}
  );

  const { GHS = 0, XOF = 0 } = useGetUSDFiatRate() || {};
  const fiatRate = useGetSiteFiatRate() || {};
  const chargesObj = useGetFiatCharges(currentWalletName);
  const {
    minAmount: minAmt,
    maxAmount: maxAmt,
    charges,
    maintainMinimum: maintainAmt,
  } = chargesObj || {};

  const { cedisRate, cefaRate } = fiatRate;

  const currentWalletObj = useCallback(() => {
    let amount, sym, symUnit, type, rate, maxAmount, minAmount, maintainMax;
    switch (currentWalletName) {
      case "cedis":
        amount = cedisWallet?.amount || 0;
        symUnit = "₵";
        sym = "GHS";
        type = "cedis";
        rate = GHS;
        minAmount = Math.ceil(minAmt * cedisRate);
        maxAmount = Math.ceil(maxAmt * cedisRate);
        maintainMax = Math.ceil(maintainAmt * cedisRate);
        break;
      case "cefa":
        amount = cefaWallet?.amount || 0;
        symUnit = "₵";
        sym = "XOF";
        type = "cefa";
        rate = XOF;
        minAmount = Math.ceil(minAmt * cefaRate);
        maxAmount = Math.ceil(maxAmt * cefaRate);
        maintainMax = Math.ceil(maintainAmt * cefaRate);
        break;
      default:
        break;
    }
    return {
      amount,
      sym,
      symUnit,
      type,
      rate,
      maxAmount,
      minAmount,
      maintainMax,
    };
  }, [
    currentWalletName,
    nairaWallet,
    cedisWallet,
    cefaWallet,
    GHS,
    XOF,
    cedisRate,
    cefaRate,
    chargesObj,
  ])();

  useEffect(() => {
    const {
      amount: walletAmount,
      minAmount,
      maxAmount,
      maintainMax,
      symUnit,
    } = currentWalletObj;

    // const total = parseInt(Boolean(amount) ? amount : 0) + charges;
    const total = parseInt(
      Boolean(amount) ? parseInt(amount) + parseInt(charges) : undefined
    );
    const limitLow = parseInt(amount) < minAmount;
    const limitHigh = parseInt(amount) > maxAmount;
    const overlimit = total >= parseInt(walletAmount || 0);
    const maintainMaximum = parseInt(walletAmount || 0) - total < maintainMax;

    setTotalAmt(total);
    setErrorAmt(overlimit || maintainMaximum || limitLow || limitHigh);
    setErrorText(
      overlimit
        ? "Exceeded balance amount"
        : limitLow
        ? `Withdrawal must be ${symUnit}${minAmount} and above`
        : limitHigh
        ? `Withdrawal must be ${symUnit}${maxAmount} and below`
        : maintainMaximum
        ? `Maintain a minimum of ${symUnit}${maintainMax} in wallet`
        : ""
    );
  }, [amount]);

  const handleMomoDetails = (val, name) => {
    let e = val.target.value;
    if (name === "momoNumber") {
      e = e.replace(/[^0-9+]/g, "");
    }
    setMomoDetails((prevS) => ({
      ...prevS,
      [name]: e,
    }));
  };

  const handleAmtChange = (val) => {
    let e = val;
    e = e.replace(/[^0-9]/g, "");

    if (e.length > 12) {
      setAmount(e.slice(0, 12));
    } else {
      setAmount(e);
    }
  };

  const processWithdraw = () => {
    const { maintainMax, minAmount, maxAmount } = currentWalletObj;
    const values = {
      productType: `withdrawal-${currentWalletName}`,
      accountType: "own-account",
      amountToDebit: parseInt(totalAmt),
      amount: parseInt(amount),
      totalAmount: parseInt(totalAmt),
      type: currentWalletName,
      withdrawDetails: {
        amount: {
          amount: parseInt(amount),
          charges,
          totalAmount: parseInt(totalAmt),
          maintainMax: parseFloat(maintainMax),
          minAmount: parseFloat(minAmount),
          maxAmount: parseFloat(maxAmount),
        },
        accountDetails: momoDetails,
      },
      walletToDebit: `${currentWalletName}Wallet`,
    };
    submitWithdraw(values);
  };

  if (!displayPage) {
    return <NoDisplay type="withdrawal" />;
  }

  return (
    <>
      {fiatRate && chargesObj ? (
        <SectionContent>
          <div className="grid grid-cols-1 gap-6 p-2">
            <div className="col-span-1">
              <div className="rounded">
                <WalletBalance
                  type={currentWalletName}
                  amount={currentWalletObj?.amount || 0}
                  amountUSD={(currentWalletObj?.amount || 0) / GHS}
                  sym={currentWalletObj.sym}
                  symUnit={currentWalletObj.symUnit}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="grid grid-cols-1 gap-6">
                <div className="col-span-1">
                  <Select
                    handleChange={(e) =>
                      setMomoDetails((prevS) => ({
                        ...prevS,
                        momoType: e.name,
                      }))
                    }
                    titleValue="name"
                    dropDown={momoType ?? []}
                    inputLabel="Momo type"
                    disabled={isSubmitting}
                  />
                </div>
                {[
                  {
                    name: "momoNumber",
                    label: "MOMO Number",
                    placeholder: "+23334903490",
                  },
                  {
                    name: "momoName",
                    label: "MOMO Name",
                    placeholder: "John Doe",
                  },
                ].map((item) => (
                  <div className="col-span-1" key={item.name}>
                    <TextField
                      type="text"
                      value={momoDetails?.[item.name] || ""}
                      handleChange={(e) => handleMomoDetails(e, item.name)}
                      disableAutoComplete
                      disabled={isSubmitting}
                      {...item}
                    />
                  </div>
                ))}

                <div className="col-span-1">
                  <TextField
                    name="amount"
                    type="text"
                    label={`Amount (${currentWalletObj.sym})`}
                    value={amount}
                    handleChange={handleAmtChange}
                    // startAdornment={currentWalletObj.sym}
                    formatInput
                    disableAutoComplete
                    placeholder="0.00"
                    disabled={isSubmitting}
                  />
                  {errorAmt && (
                    <Slide down duration={300}>
                      <div className="mt-2 text-xs font-normal text-red-500">
                        {errorText}
                      </div>
                    </Slide>
                  )}
                </div>

                <TotalTrans
                  {...{ totalAmt, charges, sym: currentWalletObj.sym }}
                />

                {parseInt(totalAmt) &&
                !errorAmt &&
                momoDetails?.momoNumber &&
                momoDetails?.momoName &&
                momoDetails?.momoType ? (
                  <div className="col-span-1">
                    <Fade up duration={500}>
                      <EnterPIN
                        processSubmit={processWithdraw}
                        btnText="Withdraw Fund"
                        isSubmitting={isSubmitting}
                      />
                    </Fade>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </SectionContent>
      ) : (
        <div className="relative h-[350px]">
          <BackdropSection open={true} />
        </div>
      )}
    </>
  );
}
