import { useState } from "react";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import Avatar from "./Avatar";
import NavbarMobileDrawer from "./NavbarMobileDrawer";
import { SimpleIcon } from "components";

export default function NavbarTop({ logout, avatarUrl }) {
  const [open, setOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 z-10 h-16 w-full bg-gradient-to-r from-bg_header_gradient_from to-bg_header_gradient_to shadow-header-alt backdrop-opacity-5">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center justify-center">
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full border-0 border-slate-50/75 bg-transparent p-1 text-white focus:outline-none md:hidden"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              {open ? (
                <SimpleIcon icon="CloseRounded" frontColor="#fff" />
              ) : (
                <SimpleIcon icon="Menu" frontColor="#fff" />
              )}
            </button>
            <Link to="/" className="ml-3 flex items-center justify-center">
              <div className="flex h-full w-full flex-col items-center justify-center ">
                <img
                  src={require("../assets/images/e-logo-white.svg").default}
                  className="h-5 w-5"
                />
              </div>
              <h3 className="ml-1 text-[24px] font-bold text-white">esetech</h3>
            </Link>
          </div>
          <div className="flex items-center">
            <Notification />
            <div className="ml-2 md:ml-0 md:block">
              <Avatar avatarUrl={avatarUrl} logout={logout} />
            </div>
          </div>
        </div>
      </div>
      <NavbarMobileDrawer {...{ open, setOpen, logout }} />
    </header>
  );
}
