import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, SectionContent, Select, WalletIcon } from "components";
import { useUpdateCurrency } from "api";
import { startCase } from "lodash";
import { walletFiatProps } from "utils/constants";
import { Checkbox } from "@mui/material";

export default function DefaultCurrency() {
  const {
    profile: { user_details },
  } = useSelector((state) => state);

  const [defaultCurrency, setDefaultCurrency] = useState(null);

  const [isUpdatingCurr, isUpdatedCurr, updateCurrency] = useUpdateCurrency();

  const handleSetCurrency = () => {
    updateCurrency({ defaultCurrency: defaultCurrency.id });
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="col-span-1">
        <SectionContent>
          <div className="p-0">
            {/* <h5 className="pb-4">Set Default Currency</h5> */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <div className="col-span-1">
                <div className="rounded-lg bg-slate-50 px-4">
                  {walletFiatProps.map((item, index) => (
                    <div
                      className="flex flex-row items-start justify-between border-b border-slate-200 py-4"
                      key={index}
                    >
                      <div className="flex flex-row items-start justify-start">
                        <WalletIcon type={item.id} fontSize="extra_small" />
                        <div className="ml-2">
                          <div>{item.otherName}</div>
                          <div className="text-xs">{item.sym}</div>
                        </div>
                      </div>
                      <Checkbox
                        checked={user_details?.defaultCurrency === item.id}
                        size="small"
                        classes={{ root: "!p-0" }}
                        onChange={() => {
                          updateCurrency({ defaultCurrency: item.id });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </SectionContent>
      </div>
    </div>
  );
}
