import React from "react";

import { Form } from "components";
import { AuthContainer } from "shared";
import { loginValidationSchema } from "utils/formSchema";
import { useLogin } from "api";
import { useBreakpoint } from "utils";
import { useGetUrlParam } from "api/actions/getUrlParam";

export default function Login() {
  const [isLoading, isLoaded, error, login] = useLogin();

  const fieldList = [
    {
      name: "email",
      type: "email",
      label: "Email",
      inputType: "textfield",
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      inputType: "textfield",
    },
  ];
  const initialFieldValues = { email: "", password: "" };

  const handleSubmit = (values) => {
    const { email, password } = values;
    login(email, password);
  };

  const breakpoint = useBreakpoint();

  return (
    <AuthContainer
      title="Login"
      subTitle="Sign in with your Esetech Account"
      footer={{ link: "/register", text: "New to Esetech? Sign Up" }}
      showForgetPass
      showDownloadAppButton
    >
      <Form
        fieldList={fieldList}
        initialFieldValues={initialFieldValues}
        buttonText="Sign In"
        buttonLoading={isLoading}
        handleSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
        fullWidth
      />
    </AuthContainer>
  );
}
