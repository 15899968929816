import React from "react";
import { SectionContent, SimpleIcon } from "components";
import { classNames } from "utils";
import { Fade } from "react-reveal";
import { useSelector } from "react-redux";

const analysisList = [
  {
    name: "Pending Transactions",
    bgColor: "bg-[#7f5621]",
    num: 0,
    icon: "Pending",
    id: "pending",
  },
  {
    name: "Active Transactions",
    bgColor: "bg-cyan-800",
    num: 0,
    icon: "WatchLater",
    id: "active",
  },
  {
    name: "Approved Transactions",
    bgColor: "bg-[#358041]",
    num: 0,
    icon: "CheckCircle",
    id: "approved",
  },
  {
    name: "Declined Transactions",
    bgColor: "bg-[#801c17]",
    num: 0,
    icon: "Cancel",
    id: "declined",
  },
  // {
  //   name: "Completed Withdrawals",
  //   bgColor: "bg-[#638052]",
  //   num: 0,
  //   icon: "PublishedWithChanges",
  // },
];

export default function AnalysisCard() {
  const { transCount, isTransCountLoaded } = useSelector(
    (state) => state.transaction
  );

  return (
    <Fade>
      <SectionContent>
        <div className="grid grid-cols-1 gap-2">
          {analysisList.map((item) => (
            <div className="col-span-1" key={item.name}>
              <div
                className={classNames(
                  item.bgColor,
                  "flex items-center rounded-lg p-4 text-white"
                )}
              >
                <div>
                  <SimpleIcon
                    icon={item.icon}
                    frontColor="text-white"
                    fontSize="medium"
                  />
                </div>
                <div className="ml-4 font-bold">
                  <div className="">{item.name}</div>
                  {isTransCountLoaded ? transCount?.[item.id] || 0 : 0}
                </div>
              </div>
            </div>
          ))}
        </div>
      </SectionContent>
    </Fade>
  );
}
