import React from "react";
import { IconButton as MuiIconButton } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import * as Icons from "@mui/icons-material";
import { classNames } from "utils";
import { currencyProps } from "utils/constants";
import { toLower } from "lodash";

export const IconButton = ({
  icon,
  handleClick,
  iconColor = "bg-primary",
  otherClass,
}) => {
  const AppIcon = Icons[icon];
  return (
    <button
      className={classNames(
        iconColor,
        `flex h-12 w-12 items-center justify-center rounded-full p-2 text-center text-white shadow-lg`,
        otherClass
      )}
      onClick={handleClick}
    >
      <AppIcon className={classNames("h-6 w-6")} />
      {/* <Icon className="h-6 w-6" /> */}
    </button>
  );
};

export function SimpleIcon({
  icon = "Home",
  fontSize = "medium",
  frontColor = "text-primary",
  otherClass = "",
}) {
  const AppIcon = Icons[icon];

  return (
    <AppIcon
      // fontSize={fontSize}
      {...(typeof fontSize === "string"
        ? {
            fontSize,
          }
        : {
            sx: { fontSize },
          })}
      className={classNames(frontColor, otherClass)}
    />
  );
}

export function SimpleIconButton({
  icon = "Home",
  fontSize = "medium",
  frontColor = "text-primary",
  otherClass = "",
  ...otherProps
}) {
  const AppIcon = Icons[icon];

  return (
    <MuiIconButton {...otherProps}>
      <AppIcon
        {...(typeof fontSize === "string"
          ? {
              fontSize,
            }
          : {
              sx: { fontSize },
            })}
        className={classNames(frontColor, otherClass)}
      />
    </MuiIconButton>
  );
}

export function BackPage({ handleClick }) {
  return (
    <button className="mb-2" onClick={handleClick}>
      <IconBox
        Icon={ArrowCircleLeftOutlinedIcon}
        textColor="text-color-main"
        iconColor="bg-slate-50"
        shadowAlt
        fontSize={30}
        hover="hover:bg-slate-200"
      />
    </button>
  );
}

export function IconBox({
  icon = "Home",
  iconColor,
  textColor = "text-white",
  shadowAlt,
  gradient,
  fontSize = 24,
  hover,
  otherClass,
  CustomIcon,
}) {
  const AppIcon = Icons[icon];

  return (
    <div
      className={classNames(
        gradient
          ? `bg-gradient-to-l from-button_gradient_from to-button_gradient_to `
          : iconColor,
        hover ? hover : "",
        "inline-flex h-10 w-10 items-center justify-center rounded fill-white p-3 text-center shadow-lg md:h-10 md:w-10",
        otherClass ? otherClass : ""
      )}
    >
      {CustomIcon ? (
        <CustomIcon />
      ) : (
        <AppIcon
          sx={{ fontSize: 20 }}
          className={classNames(
            "iconShadow-alt",
            // shadowAlt ? "iconShadow-alt" : "iconShadow",
            textColor,
            "text-base"
          )}
        />
      )}
    </div>
  );
}

export function CustomIcon({ CustomIcon, otherProps }) {
  return (
    <div className={classNames("")}>
      <CustomIcon {...otherProps} />
    </div>
  );
}

export function WalletIcon({ type, fontSize = "small" }) {
  const fiatCurrs = ["naira", "cedis", "cefa"];
  const cWProp =
    !fiatCurrs.includes(type) &&
    currencyProps.find((i) => i.id === toLower(type));

  const props = {
    extra_small: {
      text: "text-xs",
      dim: "h-4 w-4",
    },
    small: {
      text: "text-sm",
      dim: "h-6 w-6",
    },
    medium: {
      text: "text-lg",
      dim: "h-8 w-8",
    },
    large: {
      text: "text-xl",
      dim: "h-10 w-10",
    },
  };

  const cur = {
    naira: "ngn",
    cedis: "ghs",
    cefa: "xof",
  };

  return (
    <>
      {fiatCurrs.includes(type) ? (
        <img
          src={require(`../assets/fiat/${cur?.[type]}.png`)}
          className={classNames("text-white", props[fontSize].dim)}
          alt=""
        />
      ) : (
        <img
          src={require(`../../node_modules/cryptocurrency-icons/128/icon/${cWProp?.img?.toLowerCase()}.png`)}
          className={classNames("text-white", props[fontSize].dim)}
          alt=""
        />
      )}
    </>
  );
}
