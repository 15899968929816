import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cryptoWallet: {},
  nairaWallet: {},
  cedisWallet: {},
  cefaWallet: {},

  depositAccount: { isLoaded: false, depositAcct: null },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getNairaWallet: (state, { payload }) => {
      state.nairaWallet = payload.nairaWallet;
    },
    getCedisWallet: (state, { payload }) => {
      state.cedisWallet = payload.cedisWallet;
    },
    getCefaWallet: (state, { payload }) => {
      state.cefaWallet = payload.cefaWallet;
    },
    getCryptoWallet: (state, { payload }) => {
      state.cryptoWallet = payload.cryptoWallet;
    },
    getWithdrawalAccount: (state, { payload }) => {
      state.withdrawalAccount = payload.withdrawalAccount;
    },
    getUtilitiesBeneficiary: (state, { payload }) => {
      state.utilityBeneficiary = payload.utilityBeneficiary;
    },
    getDepositAccount: (state, { payload }) => {
      state.depositAccount = { ...state.depositAccount, ...payload };
    },
  },
});

export const {
  getNairaWallet,
  getCedisWallet,
  getCefaWallet,
  getCryptoWallet,
  getWithdrawalAccount,
  getUtilitiesBeneficiary,
  getDepositAccount,
} = walletSlice.actions;

export default walletSlice.reducer;
