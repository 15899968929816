import React, { useEffect, useState } from "react";
import {
  TextField as MuiTextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Box,
  MenuItem,
  Select as MuiSelect,
  Chip,
  Autocomplete as MuiAutocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { formatMoney, formatInputNum, useBreakpoint, formatTime } from "utils";

export const TextFieldWithIcon = ({
  name,
  type,
  label,
  value,
  handleChange,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        }
        label={label}
        type={type}
        size="small"
      />
    </FormControl>
  );
};

export const TextField = ({
  name,
  type,
  label,
  value,
  handleChange,
  error,
  showError,
  touched,
  handleBlur,
  disabled,
  helperText,
  titleText,
  readOnly,
  startAdornment,
  formatInput,
  disableAutoComplete,
  placeholder,
  endAdornment,
  id,
  inputFontSize = "!text-base",
  ...otherProps
}) => {
  const breakpoint = useBreakpoint();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {titleText && (
        <div className="mb-3 text-sm text-gray-400">{titleText}</div>
      )}
      <MuiTextField
        fullWidth
        id={name}
        label={label}
        name={name}
        type={
          type
            ? type === "password"
              ? showPassword
                ? "text"
                : type
              : type
            : "text"
        }
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: "!rounded-lg",
        }}
        InputProps={{
          ...(readOnly && { readOnly: true }),
          classes: {
            root: "!rounded-lg",
            input: `${
              showError ? "!text-red-500" : "!text-gray-500"
            } !flex-1 ${inputFontSize}`,
          },
          sx: [
            {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(226 232 240)",
              },
            },
          ],
          ...(startAdornment && {
            startAdornment: (
              <InputAdornment
                position="start"
                component={() => (
                  <div className="mr-1 text-sm text-gray-400">
                    {startAdornment}
                  </div>
                )}
              />
            ),
          }),
          ...(endAdornment && {
            endAdornment: (
              <InputAdornment
                position="end"
                classes={{
                  root: "!justify-end",
                  // root: "!min-h-[3rem] !h-[3rem] -m-[14px]",
                }}
              >
                {endAdornment}
              </InputAdornment>
            ),
          }),
          ...(type === "password" && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        {...(disabled && { disabled })}
        value={formatInput ? formatInputNum(value) : value}
        {...(handleChange && {
          onChange: (e) => {
            // e.target.value.replace(/[^\w\s]/g, "")
            handleChange(
              formatInput ? e.target.value.replace(/[^\w\s]/g, "") : e
            );
          },
        })}
        {...(handleBlur && { onBlur: handleBlur })}
        {...(error &&
          touched && {
            error: touched && Boolean(error),
            helperText: touched && error,
          })}
        {...(error && showError && { error, helperText })}
        {...(disableAutoComplete && {
          autoComplete: type === "password" ? "new-password" : "off",
        })}
        {...(placeholder && { placeholder })}
        {...otherProps}
      />
    </>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

export const Select = ({
  handleChange,
  dropDown,
  dropDownEmptyText,
  inputLabel,
  titleValue,
  subTitleValue,
  defaultValue,
  disableInput,
  disabled,
  valueAlt,
  setValueAlt,
}) => {
  const [value, setValue] = useState(defaultValue || "");

  const handleInputChange = (e) => {
    const id = e.target.value;
    const findId = dropDown.find((i) => i.id === id);
    setValueAlt
      ? setValueAlt(findId?.[titleValue || "value"])
      : setValue(findId?.[titleValue || "value"]);
    handleChange(findId);
  };

  const id = `select-${inputLabel}`;

  return (
    <FormControl
      fullWidth
      size="small"
      classes={{
        root: "!h-14 w-full !rounded-lg",
      }}
    >
      <InputLabel id={id} shrink>
        Select {inputLabel}
      </InputLabel>
      <MuiSelect
        displayEmpty
        labelId={id}
        value={valueAlt || value}
        onChange={handleInputChange}
        input={
          <OutlinedInput
            notched
            label={`Select ${inputLabel}`}
            classes={{
              root: "h-full !rounded-lg",
              input: "h-full",
              // notchedOutline: "!border-slate-200",
            }}
            disabled={disabled}
          />
        }
        renderValue={(selected) => {
          return (
            <>
              {selected === "" || !selected || disableInput ? (
                <span className="text-sm text-gray-400">Click to select</span>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    label={valueAlt || value}
                    sx={{ height: 28 }}
                    classes={{
                      root: "!text-gray-500 !rounded !bg-primary/5 !text-base",
                    }}
                  />
                </Box>
              )}
            </>
          );
        }}
        MenuProps={MenuProps}
        classes={{
          root: "!h-16",
        }}
      >
        {!disabled && dropDown.length ? (
          dropDown.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              classes={{
                root: "flex flex-col !items-start",
              }}
            >
              <div className="text-base text-gray-600">
                {item?.[titleValue || "value"]}
              </div>
              {subTitleValue && (
                <div className="text-sm lowercase text-gray-400">
                  {item?.[subTitleValue]}
                </div>
              )}
            </MenuItem>
          ))
        ) : (
          <div className="p-2 text-sm text-gray-400">{dropDownEmptyText}</div>
        )}
      </MuiSelect>
    </FormControl>
  );
};

export const RadioInput = React.memo(
  ({
    handleChange,
    options,
    inputLabel,
    disableInput,
    disabled,
    className,
    handleSubmit,
    checked,
    sendPhoneCodeStatus,
    handleResendPhoneCode,
    phonecodeCounter,
    userDetails,
  }) => {
    const [selectedValue, setSelectedValue] = useState("");

    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
      handleChange(event.target.value);
    };

    return (
      <FormControl component="fieldset">
        {/* <Box>
          <div className="py-1">
            <p className="pb-1 text-center text-[1.2em] capitalize text-gray-500">
              {inputLabel}
            </p>
            <p className="text-center text-xs text-gray-500 first-letter:capitalize ">
              choose your preferred phone verification channel
            </p>
          </div>
        </Box> */}
        <RadioGroup
          value={selectedValue}
          onChange={handleRadioChange}
          style={{ display: "flex", flexDirection: "row", margin: "auto" }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.label}
              value={option.label}
              label={<p className="text-xs capitalize">{option.label}</p>}
              control={<Radio />}
              disabled={disabled || (disableInput && option.disabled)}
              style={{ marginRight: "20px" }}
              checked={checked}
            />
          ))}
          <button
            className="text-xs text-color-main"
            onClick={handleResendPhoneCode}
            disabled={sendPhoneCodeStatus}
          >
            {phonecodeCounter <= 1 ? (
              <p className="pt-1 pb-2 text-left text-xs ">
                Enter the code sent to
                <span className="px-1">{userDetails?.phoneNumber}</span>
                <span className="pl-1 text-sm text-primary">Send Now</span>
              </p>
            ) : (
              <>Retry send code - {formatTime(phonecodeCounter)}s</>
            )}
          </button>
        </RadioGroup>
      </FormControl>
    );
  }
);

export const Autocomplete = ({
  inputLabel,
  options,
  titleValue,
  handleChange,
  handleInputTextChange,
  valueAlt,
  setValueAlt,
  loadingText,
  disabled,
  isLoading,
}) => {
  const [value, setValue] = useState("");

  return (
    <MuiAutocomplete
      multiple
      freeSolo
      loading={Boolean(options?.length || valueAlt?.length)}
      loadingText={<span className="text-sm">{loadingText}</span>}
      options={options || []}
      getOptionLabel={(option) => option?.[titleValue || "title"]}
      value={[valueAlt] || [value]}
      onChange={(event, newValue) => {
        const val = newValue?.[newValue?.length - 1] || "";

        setValueAlt ? setValueAlt(val) : setValue(val);
        handleChange(val);
        if (handleInputTextChange) {
          handleInputTextChange();
        }
      }}
      renderTags={(value, getTagProps) => (
        <>
          {value?.[0]?.[titleValue || "title"] && (
            <Chip
              variant="contained"
              label={value?.[0]?.[titleValue || "title"]}
              {...getTagProps({ index: 0 })}
              classes={{
                root: "!text-gray-500 !bg-primary/5 !rounded !text-base",
              }}
            />
          )}
        </>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          placeholder={Boolean(valueAlt || value) ? "" : `Select ${inputLabel}`}
          autoComplete="off"
          label={`Select ${inputLabel}`}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            classes: {
              root: "!rounded-lg !text-[16px]",
            },
            sx: [
              {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(226 232 240)",
                },
              },
            ],
            ...params.InputProps,
          }}
          onChange={(e) => {
            if (handleInputTextChange) {
              handleInputTextChange();
            }
          }}
        />
      )}
      PaperComponent={({ children }) => (
        <div className="rounded-lg bg-white p-1 shadow-md">
          {!isLoading ? (
            <div className="w-full overflow-auto rounded-md border border-slate-200 bg-white shadow-md">
              {children}
            </div>
          ) : (
            <div className="p-4">Loading...</div>
          )}
        </div>
      )}
      classes={{
        input: "!text-gray-500 !text-[16px]",
        option: "!text-sm",
      }}
      disabled={disabled}
    />
  );
};
