/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';
import Slide from 'react-reveal/Slide';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useLogout } from 'api';
import { classNames } from 'utils';
import Notification from './Notification';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const navigation = [
  { name: 'Dashboard', linkTo: '/dashboard', Icon: GridViewIcon },
  { name: 'Wallet', linkTo: '/wallet', Icon: AccountBalanceWalletOutlinedIcon },
  { name: 'Transactions', linkTo: '/transactions', Icon: AddchartIcon },
  { name: 'Giftcard', linkTo: '/giftcard', Icon: CardGiftcardOutlinedIcon },
  { name: 'Utilities', linkTo: '/utilities', Icon: BubbleChartOutlinedIcon },
  { name: 'Settings', linkTo: '/settings', Icon: SettingsOutlinedIcon },
];

const NavbarTop = ({ logout }) => {
  const [open, setOpen] = useState(false);
  const userNavigation = [
    { name: 'Your Profile', handleClick: () => {} },
    { name: 'Sign out', handleClick: () => logout() },
  ];

  // bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to
  return (
    <>
      <div as="nav" className="bg-background h-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <Link to="/" className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  alt="Workflow"
                />
              </div>
              <h3 className="nav-link ml-3 text-2xl font-bold text-text_main">
                Kaapo
              </h3>
            </Link>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <Notification />
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user.imageUrl}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              {/* Mobile menu button */}
              <button
                className="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-gray-200 hover:bg-slate-700 hover:text-white focus:outline-none"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Drawer {...{ open, setOpen, userNavigation, logout }} />
    </>
  );
};

const Drawer = ({ open, setOpen, userNavigation, logout }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-gray-200 shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="mb-4 text-2xl font-bold text-gray-600">
                        {' '}
                        Kaapo
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="md:hidden">
                      <div className="space-y-1 divide-y divide-slate-300 px-2 pt-2  pb-3 sm:px-3">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.linkTo}
                            className={classNames(
                              item.current
                                ? 'text-primary-600'
                                : 'text-gray-700 hover:text-primary-600',
                              'block px-3 py-2 text-base font-normal'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                      <div className="border-t border-gray-300 pt-4 pb-3">
                        <div className="flex items-center px-5">
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </div>
                          <div className="ml-3">
                            <div className="text-base font-medium leading-none text-gray-600">
                              {user.name}
                            </div>
                            <div className="text-sm font-medium leading-none text-gray-500">
                              {user.email}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="mt-3 space-y-1 px-2">
                          {userNavigation.map((item) => (
                            <button
                              key={item.name}
                              onClick={item.handleClick}
                              className="block rounded-md px-3 py-2 text-sm font-normal text-gray-600 hover:bg-gray-700 hover:text-white"
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const NavbarBottom = ({ logout, pathname }) => {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset > 24);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const Block = ({ children }) => {
    return (
      <>{offset ? <Slide top>{children}</Slide> : <div>{children}</div>}</>
    );
  };

  return (
    <Block>
      <header
        className={classNames(
          offset ? 'fixed top-0 left-0 right-0' : 'relative animate-none',
          'hidden h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow-header md:block'
        )}
      >
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          {/* border-b border-gray-200 */}
          <div className="">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 md:hidden"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Flyout menus */}
              <Popover.Group className="hidden md:ml-8 md:block md:self-stretch">
                <div className="flex h-full space-x-6">
                  {navigation.map((page) => (
                    <Link
                      key={page.name}
                      to={page.linkTo}
                      className={classNames(
                        pathname.includes(page.linkTo)
                          ? // mt-2 rounded-t-lg
                            'bg-current-nav'
                          : '',
                        'nav-link flex items-center p-2 text-sm font-medium text-white hover:text-slate-50'
                      )}
                    >
                      <page.Icon className="h-4 w-4" sx={{ fontSize: 18 }} />

                      <span className="ml-1 text-sm font-bold leading-[5px]">
                        {page.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden md:flex md:flex-1 md:items-center md:justify-end md:space-x-6">
                  <button
                    className="text-sm font-medium text-white hover:text-gray-200"
                    onClick={logout}
                  >
                    Sign Out
                  </button>
                  <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                  <a
                    href="/"
                    className="text-sm font-medium text-white hover:text-gray-200"
                  >
                    Download App
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </Block>
  );
};

export default function Navbar() {
  const [, , , logout] = useLogout();
  const { pathname } = useLocation();

  return (
    <>
      <div className="navbar relative z-50">
        <NavbarTop logout={logout} />

        <NavbarBottom logout={logout} pathname={pathname} />
      </div>
    </>
  );
}
