import { useEffect, useState } from "react";
import { axiosCall, errorHandler } from "../helpers";
import { notification, createRefID, getDeviceType } from "utils";

import * as i from "../lib";
import {
  getTransactionIsCompleted,
  getTransactionStatus,
} from "redux/reducers/transactionReducers";
import { useNavigate } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";

const auth = i.auth;

const url = (endpoint) => `${i.baseURL}/fiat_trans/${endpoint}`;
const transRef = (uid, type) => createRefID(uid, type);

export const useBankList = (label) => {
  const [getBankList, setBankList] = useState(null);
  const [errList, setErrList] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (label === "Other account") {
      setLoading(true);
      const getList = async () => {
        try {
          const resolved = await axiosCall(url("get_banks"), {});
          setBankList(resolved.data.data);
          setLoading(false);
        } catch (error) {
          setErrList(true);
          setLoading(false);
        }
      };
      getList();
    }

    return () => {};
  }, [label]);
  return [getBankList, errList, isLoading];
};

export const useVerifyAccountNum = (params) => {
  const [verifyAcctName, setVerifyAcctName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorVerify, setErrorVerify] = useState(null);

  const verifyAccountNum = async () => {
    setVerifyAcctName(null);
    const { selectedBank, accountNum } = params || {};

    try {
      if (accountNum.length >= 10 && selectedBank) {
        setIsLoading(true);

        const val = {
          accountNumber: accountNum,
          bankCode:
            selectedBank?.[0]?.code ||
            selectedBank?.[0]?.bankCode ||
            selectedBank?.code ||
            selectedBank?.bankCode,
        };

        const resolved = await axiosCall(url("validate_bank"), val);
        const resolvedData = resolved.data;

        const {
          accountNumber: bankAccountNo,
          accountName,
          bank,
          bankCode: code,
        } = resolvedData.data;

        const bankName = bank?.name || selectedBank?.name;
        const bankCode = bank?.code || code;

        setVerifyAcctName({ bankAccountNo, accountName, bankName, bankCode });
        setIsLoading(false);
        setErrorVerify(false);
      } else {
        setVerifyAcctName(null);
        setIsLoading(false);
        setErrorVerify(null);
      }
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setErrorVerify(true);
    }
  };

  return [
    verifyAcctName,
    setVerifyAcctName,
    isLoading,
    errorVerify,
    setErrorVerify,
    verifyAccountNum,
  ];
};

export const useWithdrawFundNaira = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const {
    account: { withdrawalAccount },
  } = useStore().getState();

  const allDetails = Object.values(withdrawalAccount || {}).reduce(
    (acc, item) => {
      acc.push(...item);
      return acc;
    },
    []
  );

  const submitWithdraw = async (args) => {
    const { values, code, saveBene } = args;

    const acc = values.withdrawDetails.accountDetails;

    const saveBeneExist = allDetails.find(
      (item) =>
        item.accountName === acc.accountName &&
        item.bankAccountNo === acc.bankAccountNo &&
        item.bankName === acc.bankName
    );

    if (saveBeneExist && saveBene) {
      i.notification("warning", "Beneficiary already saved.");
      return;
    }

    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));
      setSubmitting(true);

      const valueFormat = {
        ...values,
        customerId: auth.currentUser.uid,
        amountToCredit: "none",
        walletToCredit: "none",
        walletToDebit: "nairaWallet",
        product: "payout",
        deviceType: getDeviceType(),
      };

      const withdrawTrans = await axiosCall(
        url("withdraw_naira"),
        {
          transData: valueFormat,
          code,
          saveBene,
        },
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, submitWithdraw];
};

export const useWithdrawFundOther = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const submitWithdraw = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      amountToCredit: "none",
      walletToCredit: "none",
      product: "payout",
      transRef: transRef(auth.currentUser?.uid, "FIAT_WITHD"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const withdrawTrans = await axiosCall(
        url("withdraw_fiat"),
        valueFormat,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, submitWithdraw];
};

export const useTransferFund = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const submitTransfer = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      amountToCredit: "none",
      walletToCredit: "none",
      product: "transfer-fiat",
      transRef: transRef(auth.currentUser?.uid, "ESET_TRNSTOUSER"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const trans = await axiosCall(
        url("transfer_fiat"),
        valueFormat,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, submitTransfer];
};

export const useBonusTransfer = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const bonusTransfer = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      product: "transfer-bonus",
      transRef: transRef(auth.currentUser?.uid, "TRNST_BONUS"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));
      setSubmitting(true);

      const trans = await axiosCall(
        url("transfer_bonus_fiat"),
        valueFormat,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, bonusTransfer];
};

export const useConvertFund = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const convertFund = async (values) => {
    const valueFormat = {
      ...values,
      customerId: auth.currentUser.uid,
      product: "convert-fiat",
      transRef: transRef(auth.currentUser?.uid, "CONVT_FIAT"),
      deviceType: getDeviceType(),
    };

    try {
      dispatch(getTransactionStatus(true));
      dispatch(getTransactionIsCompleted(false));

      setSubmitting(true);

      const trans = await axiosCall(
        url("convert_fiat"),
        valueFormat,
        true,
        "useLimitedToken"
      );

      setSubmitting(false);
      setSubmitted(true);
      dispatch(getTransactionStatus(false));
      dispatch(getTransactionIsCompleted(true));
    } catch (error) {
      // console.log(error);
      setSubmitting(false);
      dispatch(getTransactionStatus(false));
      errorHandler(error);
    }
  };

  return [isSubmitting, isSubmitted, convertFund];
};
