import * as React from "react";
import { useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import * as MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import { classNames, useBreakpoint } from "utils";
import { SimpleIconButton, SimpleIcon } from ".";

export function Drawer({
  anchor,
  state,
  setState,
  children,
  title,
  handleOnClose,
}) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const content = (
    <Box
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
      className={classNames(
        anchor === "top" || anchor === "bottom"
          ? "w-auto"
          : "w-auto md:w-[500px]",
        anchor === "bottom"
          ? "bg-background_main relative h-3/5 rounded-t-lg p-6"
          : "",
        ""
      )}
    >
      {anchor === "bottom" && (
        <>
          <div className="absolute -top-6 right-0">
            <button
              className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-50 shadow-lg"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="absolute top-3 left-1/2 h-2 w-12 -translate-x-1/2 rounded bg-slate-300" />
        </>
      )}
      {["left", "right"].includes(anchor) && (
        <AppBar
          sx={{ position: "relative" }}
          classes={{
            root: "h-16 bg-gradient-to-r from-bg_gradient_from to-bg_gradient_to shadow !sticky",
          }}
        >
          <Toolbar
            classes={{
              root: "justify-between items-center h-full",
            }}
          >
            <DialogTitle
              classes={{
                root: "!font-normal text-center !text-base",
              }}
            >
              {title}
            </DialogTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setState(false);
                handleOnClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {children}
    </Box>
  );

  return (
    <div>
      <MuiDrawer anchor={anchor} open={state}>
        {content}
      </MuiDrawer>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function PopupDrawer({
  setOpenDrawer,
  open,
  children,
  showCloseButton = true,
}) {
  const breakpoint = useBreakpoint();
  const { transactionStatus } = useSelector((state) => state.transaction);

  const handleClose = () => {
    if (showCloseButton) {
      if (!transactionStatus) {
        setOpenDrawer(false);
      }
    } else {
      return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      scroll="body"
      {...(breakpoint.md
        ? {
            "aria-labelledby": "scroll-dialog-title",
            "aria-describedby": "scroll-dialog-description",
            classes: {
              paper: "!w-[400px] !rounded-lg !overflow-visible",
            },
          }
        : {
            fullScreen: true,
          })}
    >
      {showCloseButton && (
        <>
          <div
            className={classNames(
              "absolute z-[502] flex h-8 w-8 items-center justify-center rounded-full shadow-dialog",
              breakpoint.md
                ? "-right-2 -top-2 bg-white"
                : "right-3 top-3 bg-primary"
            )}
          >
            <SimpleIconButton
              icon="Close"
              frontColor={classNames(
                breakpoint.md ? "text-primary" : "text-white"
              )}
              onClick={handleClose}
              fontSize="small"
              className="!p-1"
            />
          </div>
        </>
      )}

      <div
        className={classNames(
          "overflow-hidden rounded-2xl text-text-color shadow-md",
          breakpoint.md ? "bg-card p-2" : ""
        )}
      >
        {children}
      </div>
    </Dialog>
  );
}
