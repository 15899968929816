import React, { useEffect, useState } from "react";
import { BackdropSection, SimpleIcon } from "components";
import { classNames } from "utils";

export default function SettingsContent({ pageContent, selectedIndex }) {
  const { Comp, label, IconAlt } = pageContent;
  const [pageDelay, setPageDelay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageDelay(true);
    }, 500);
    setPageDelay(false);
  }, [selectedIndex]);

  return (
    <div className="relative flex-1">
      <div className="h-full">
        <div className="flex h-[60px] items-center border-b border-slate-100 p-4">
          <div className="flex-1">
            <div className="flex items-center">
              <div
                className={classNames(
                  "flex h-8 w-8 items-center justify-center rounded-full bg-slate-50"
                )}
              >
                <SimpleIcon
                  fontSize="small"
                  icon={IconAlt}
                  frontColor={"text-color-main"}
                />
              </div>
              <h5 className="ml-2 font-bold">{label}</h5>
            </div>
          </div>
        </div>

        <div className="h-full bg-dark/5">
          <div className="p-4">
            <Comp />
          </div>
        </div>
      </div>

      <BackdropSection open={!pageDelay} />
    </div>
  );
}
