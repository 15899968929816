import React, { useState } from "react";
import { PageContainer } from "shared";
import {
  withdrawFiat,
  cryptoTrades,
  fundFiat,
  transferFiat,
  utilities,
  convertFiat,
  transferBonus,
} from "./components/TransactionProps";
import { Fade } from "react-reveal";

import { classNames, useBreakpoint } from "utils";
import TransactionDrawer from "./TransactionDrawer";
import TransactionContentMobile from "./TransactionContentMobile";
import TransactionContent1 from "./TransactionContent";

const tabList = [
  {
    name: "Withdraw Fiat",
    pendingName: "Pending Withdraw Fiat",
    id: "withdrawal_fiat",
    collection: "transWithdrawals",
    pendingCollection: "pendingTransWithdrawals",
    other_collection: "withdrawalRequest",
    subtitle: "Details about your personal information",
    Page: withdrawFiat,
  },
  {
    name: "Crypto Trades",
    id: "crypto_trades",
    collection: "transCrypto",
    other_collection: "transactions",
    subtitle: "Details about your personal information",
    Page: cryptoTrades,
  },
  {
    name: "Utilities",
    pendingName: "Pending Utilities",
    id: "utilities",
    collection: "transUtilities",
    pendingCollection: "pendingTransUtilities",
    other_collection: "dataPurchase",
    subtitle: "Details about your personal information",
    Page: utilities,
  },
  {
    name: "Fund Fiat Wallet",
    id: "fund_fiat_wallet",
    collection: "transDeposits",
    Page: fundFiat,
  },
  {
    name: "Transfer Fiat",
    id: "transfer_fiat",
    collection: "transTransferFiat",
    Page: transferFiat,
  },
  {
    name: "Transfer Bonus",
    id: "transfer_bonus",
    collection: "transTransferBonus",
    Page: transferBonus,
  },
  {
    name: "Convert Fiat",
    id: "convert_fiat",
    collection: "transConvertFiat",
    Page: convertFiat,
  },
];

export default function Index() {
  const breakpoint = useBreakpoint();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mobilePopup, setMobilePopup] = useState(null);

  const pageContent = tabList?.[selectedIndex];

  return (
    <PageContainer header="Transactions">
      <div className="grid grid-cols-1 gap-6 ">
        <div className="col-span-1 h-full">
          <Fade>
            <div className="relative h-full rounded-xl">
              <div
                className={classNames(
                  "flex h-full",
                  breakpoint.md ? "flex-row" : "flex-col"
                )}
              >
                <TransactionDrawer
                  {...{
                    selectedIndex,
                    setSelectedIndex,
                    tabList,
                    setMobilePopup,
                  }}
                />

                {breakpoint.md ? (
                  <TransactionContent1
                    pageContent={pageContent}
                    selectedIndex={selectedIndex}
                  />
                ) : (
                  <TransactionContentMobile
                    {...{
                      pageContent,
                      selectedIndex,
                      mobilePopup,
                      setMobilePopup,
                    }}
                  />
                )}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </PageContainer>
  );
}
